import * as React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../../hooks/useTitle";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { ExpandMoreOutlined, LaunchOutlined } from "@mui/icons-material";
import { useGetResourcesQuery } from "../../services/resource.service";
import { Resource } from "../../types/resource";

const ResourcesPage = () => {
  useTitle("Resources/News");

  const { data, isLoading, isFetching, refetch } = useGetResourcesQuery();

  const dataGrouped: Array<{ category: string; items: Resource[] }> =
    React.useMemo(
      () =>
        Object.values(
          data?.reduce<{ [key: string]: Array<Resource> }>((acc, item) => {
            acc[item.category] = [...(acc[item.category] || []), item];
            return acc;
          }, {}) || {}
        ).map((items) => ({ category: items[0].category, items })),
      [data]
    );

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction={"row"} alignItems={"center"}>
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="h2"
                sx={{ fontSize: 22, letterSpacing: 1, fontWeight: "400" }}
              >
                Resources / News
              </Typography>
            </Box>
          </Stack>
          {isFetching && <LinearProgress />}
        </Grid>
        <Grid item xs={12}>
          {dataGrouped.map((it) => (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                <Typography variant="h5" sx={{ fontSize: 20 }}>
                  {it.category}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List dense={true}>
                  {it.items.map((item) => (
                    <ListItemButton
                      onClick={() => window.open(item.url, "_blank")}
                    >
                      <ListItemIcon>
                        <LaunchOutlined />
                      </ListItemIcon>
                      <ListItemText
                        primary={item.title}
                        secondary={item.url}
                        primaryTypographyProps={{
                          color: "primary.main",
                          fontSize: 16,
                        }}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};
export default ResourcesPage;
