import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import SelectCertification from "../../components/SelectCertification";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { UserCertification } from "../../types/certification";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import {
  useAddUserCertificationMutation,
  useUpdateUserCertificationMutation,
} from "../../services/user.service";

interface ModalUserCertificationFormProps {
  open: boolean;
  onClose: () => void;
  userId: number;
  data: UserCertification | null;
}

const ModalUserCertificationForm = ({
  open,
  onClose,
  userId,
  data,
}: ModalUserCertificationFormProps) => {
  const [add, { isLoading: adding }] = useAddUserCertificationMutation();
  const [edit, { isLoading: editing }] = useUpdateUserCertificationMutation();

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      payload.user_id = userId;

      if (!!payload.issue_date)
        payload.issue_date = dayjs(payload.issue_date).format("YYYY-MM-DD");
      if (!!payload.expiration_date)
        payload.expiration_date = dayjs(payload.expiration_date).format(
          "YYYY-MM-DD"
        );

      if (!data) {
        await add(payload).unwrap();
        toast.success("Certification added");
      } else {
        payload.id = data?.id!;
        await edit(payload).unwrap();
        toast.success("Certification updated");
      }
      onClose();
    } catch (err) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (open) {
      clearErrors();

      setValue("certification", data?.certification_id);
      setValue("certification_number", data?.certification_number);
      setValue("certification_url", data?.certification_url);
      setValue("issue_date", data?.issue_date);
      setValue("expiration_date", data?.expiration_date);
    }
  }, [data, open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">
          {!data ? "Add" : "Edit"} User Certification
        </DialogTitle>
        <DialogContent>
          <Box>
            <Controller
              name="certification"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <SelectCertification
                  onChange={onChange}
                  value={value}
                  error={!!errors.certification}
                  helperText={
                    !!errors.certification && "This field is required"
                  }
                />
              )}
            />
            <Controller
              name="certification_number"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Certification Number (optional)"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
            <Controller
              name="certification_url"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Certification URL (optional)"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
            <Controller
              name="issue_date"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <DesktopDatePicker
                  sx={{ mt: 1, width: "100%" }}
                  onChange={onChange}
                  value={!!value ? dayjs(value) : undefined}
                  label="Issue Date"
                  onError={() => {
                    setError("issue_date", {
                      type: "custom",
                      message: "Invalid",
                    });
                  }}
                  onAccept={() => clearErrors("issue_date")}
                  slotProps={{
                    textField: {
                      helperText:
                        !!errors.issue_date && "This field is required",
                      error: !!errors.issue_date,
                    },
                  }}
                />
              )}
            />
            <Controller
              name="expiration_date"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <DesktopDatePicker
                  sx={{ mt: 1, width: "100%" }}
                  onChange={onChange}
                  value={!!value ? dayjs(value) : undefined}
                  label="Expiration Date"
                  onError={() => {
                    setError("expiration_date", {
                      type: "custom",
                      message: "Invalid",
                    });
                  }}
                  onAccept={() => clearErrors("expiration_date")}
                  slotProps={{
                    textField: {
                      helperText:
                        !!errors.expiration_date && "This field is required",
                      error: !!errors.expiration_date,
                    },
                  }}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={adding || editing}
          >
            {!data ? "Add" : "Update"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalUserCertificationForm;
