import * as React from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../../hooks/useTitle";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import WorkOutlineOutlined from "@mui/icons-material/WorkOutlineOutlined";
import { currencyFormat } from "../../utils/helper";
import { useGetForumThreadsQuery } from "../../services/forum.service";
import CardFeed from "../connect/CardFeed";
import WorkStatisticView from "./WorkStatisticsView";
import TableActiveWorks from "./TableActiveWorks";
import WorkTablesView from "./WorkTablesView";

const HomePage = () => {
  const user = useCurrentUser();
  const navigate = useNavigate();

  useTitle("Home");

  const [page, setPage] = React.useState(1);

  const { data: pinned, refetch: refetchPinned } = useGetForumThreadsQuery({
    page: 1,
    pinned: true,
  });
  const { data, isLoading, isFetching, refetch } = useGetForumThreadsQuery({
    page,
    pageSize: 5,
    pinned: false,
  });

  const threads = React.useMemo(() => {
    if (page == 1) {
      return [...(pinned?.data || []), ...(data?.data || [])];
    } else {
      return data?.data || [];
    }
  }, [page, pinned, data]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", mb: 0 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h5" sx={{ fontSize: 18 }}>
                Welcome Back, {user?.full_name} 👋
              </Typography>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography sx={{ fontSize: 18, color: "#666" }}>
                  Your current balance:{" "}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    ml: 2,
                    color: "primary.main",
                    fontSize: 22,
                  }}
                >
                  {currencyFormat(user?.balance_decrypt || 0)}
                </Typography>
              </Stack>
            </Box>
            <Button
              variant="contained"
              onClick={() => navigate(`/works`)}
              startIcon={<WorkOutlineOutlined />}
              sx={{ letterSpacing: 1.5 }}
            >
              My Work
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <WorkStatisticView />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <WorkTablesView />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h2"
            sx={{ fontSize: 22, letterSpacing: 0.5, fontWeight: "400" }}
          >
            Latest Feeds
          </Typography>
          {isFetching && <LinearProgress />}
        </Grid>
        <Grid item xs={12}>
          {threads?.map((thread) => (
            <CardFeed thread={thread} />
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};
export default HomePage;
