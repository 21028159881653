import * as React from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../../hooks/useTitle";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import WorkOutlineOutlined from "@mui/icons-material/WorkOutlineOutlined";
import { currencyFormat } from "../../utils/helper";
import { useGetForumThreadsQuery } from "../../services/forum.service";
import CardFeed from "../connect/CardFeed";
import { PostAddOutlined } from "@mui/icons-material";

const FeedsPage = () => {
  const user = useCurrentUser();
  const navigate = useNavigate();

  useTitle("Latest Feeds");

  const [page, setPage] = React.useState(1);

  const { data: pinned, refetch: refetchPinned } = useGetForumThreadsQuery({
    page: 1,
    pinned: true,
  });
  const { data, isLoading, isFetching, refetch } = useGetForumThreadsQuery({
    page,
    pinned: false,
  });

  const threads = React.useMemo(() => {
    if (page == 1) {
      return [...(pinned?.data || []), ...(data?.data || [])];
    } else {
      return data?.data || [];
    }
  }, [page, pinned, data]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction={"row"} alignItems={"center"}>
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="h2"
                sx={{ fontSize: 22, letterSpacing: 1, fontWeight: "400" }}
              >
                Live Feed
              </Typography>
            </Box>
            <Button
              startIcon={<PostAddOutlined />}
              variant="outlined"
              onClick={() => navigate("/live-feed/new")}
            >
              Create Post
            </Button>
          </Stack>
          {isFetching && <LinearProgress />}
        </Grid>
        <Grid item xs={12}>
          {threads?.map((thread) => (
            <CardFeed thread={thread} />
          ))}
          <Pagination
            count={data?.meta?.last_page || 0}
            color="primary"
            page={page}
            onChange={(e, p) => {
              setPage(p);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default FeedsPage;
