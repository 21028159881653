import React from "react";
import { useCurrentUser } from "./useCurrentUser";

const TOTAL_PROGRESS_REQUIRED = 9;

export const useProfileCompleteness = () => {
  const user = useCurrentUser();

  const isPhotoDone = React.useMemo(
    () => user && user.photo?.length > 0,
    [user]
  );
  const isBioDone = React.useMemo(() => !!user?.bio, [user]);
  const isWorkSettingsDone = React.useMemo(
    () => !!user?.min_pay && !!user?.max_pay && !!user?.commute_radius,
    [user]
  );
  const isAddressDone = React.useMemo(
    () =>
      !!user?.physical_address_1 &&
      !!user?.city &&
      !!user?.state &&
      !!user?.city,
    [user]
  );
  const isSkillsDone = React.useMemo(
    () => user && user?.skills?.length > 0,
    [user]
  );
  const isIndustriesDone = React.useMemo(
    () => user && user?.business_verticals?.length > 0,
    [user]
  );
  const isWorkStatusDone = React.useMemo(
    () => user && !!user?.talent?.work_status,
    [user]
  );
  const isAvailabilityDone = React.useMemo(
    () =>
      !!user?.talent?.avail_monday ||
      !!user?.talent?.avail_tuesday ||
      !!user?.talent?.avail_wednesday ||
      !!user?.talent?.avail_thursday ||
      !!user?.talent?.avail_friday ||
      !!user?.talent?.avail_saturday ||
      !!user?.talent?.avail_sunday,
    [user]
  );
  const isLanguageDone = React.useMemo(
    () => user && user?.languages?.length > 0,
    [user]
  );
  // optional
  const isEducationDone = React.useMemo(
    () => user && user?.educations?.length > 0,
    [user]
  );
  const isCertificationDone = React.useMemo(
    () => user && user?.certifications?.length > 0,
    [user]
  );
  const isWorkExpDone = React.useMemo(
    () => user && user?.work_experiences?.length > 0,
    [user]
  );

  const progressCount = React.useMemo(() => {
    let count = 0;
    if (isPhotoDone) count++;
    if (isBioDone) count++;
    if (isWorkSettingsDone) count++;
    if (isAddressDone) count++;
    if (isSkillsDone) count++;
    if (isIndustriesDone) count++;
    if (isWorkStatusDone) count++;
    if (isAvailabilityDone) count++;
    if (isLanguageDone) count++;
    return count;
  }, [
    isPhotoDone,
    isBioDone,
    isWorkSettingsDone,
    isAddressDone,
    isSkillsDone,
    isIndustriesDone,
    isWorkStatusDone,
    isAvailabilityDone,
    isLanguageDone,
  ]);

  const hasCompleted = React.useMemo(
    () => progressCount == TOTAL_PROGRESS_REQUIRED,
    [progressCount]
  );

  return React.useMemo(
    () => ({
      hasCompleted,
      progress: {
        isPhotoDone,
        isBioDone,
        isWorkSettingsDone,
        isAddressDone,
        isSkillsDone,
        isIndustriesDone,
        isWorkStatusDone,
        isAvailabilityDone,
        isLanguageDone,
        // optional
        isEducationDone,
        isCertificationDone,
        isWorkExpDone,
      },
      progressCount,
      progressTotal: TOTAL_PROGRESS_REQUIRED,
    }),
    [
      hasCompleted,
      progressCount,
      isPhotoDone,
      isBioDone,
      isWorkSettingsDone,
      isAddressDone,
      isSkillsDone,
      isIndustriesDone,
      isWorkStatusDone,
      isAvailabilityDone,
      isLanguageDone,
      // optional
      isEducationDone,
      isCertificationDone,
      isWorkExpDone,
    ]
  );
};
