import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTitle } from "../../hooks/useTitle";
import { RefreshOutlined } from "@mui/icons-material";
import { useGetMyJobsQuery } from "../../services/my-job.service";
import { NegotiationStatus, ProjectStatus } from "../../types/project";
import WorkOutlineOutlined from "@mui/icons-material/WorkOutlineOutlined";
import TableRowInvite from "./TableRowInvite";
import TableRowOffer from "./TableRowOffer";
import TableRowCanceled from "./TableRowCanceled";

const WorkOffersPage = () => {
  useTitle("My Offers");

  const { data, refetch, isFetching } = useGetMyJobsQuery({
    statuses: "new,invited,interview,canceled",
  });

  const { bids, invites, canceleds } = React.useMemo(() => {
    const bids = data?.filter(
      (it) =>
        [NegotiationStatus.INTERVIEW, NegotiationStatus.NEW].includes(
          it.status
        ) && it.project?.status != ProjectStatus.CANCELED
    );
    const invitations = data?.filter(
      (it) =>
        [NegotiationStatus.INVITED].includes(it.status) &&
        it.project?.status == ProjectStatus.PUBLISHED
    );
    const canceleds = data?.filter((it) =>
      [NegotiationStatus.CANCELED].includes(it.status)
    );
    return {
      bids,
      invites: invitations,
      canceleds,
    };
  }, [data]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack alignItems={"flex-end"}>
            <Button startIcon={<RefreshOutlined />} onClick={refetch}>
              Refresh
            </Button>
          </Stack>
          {isFetching && <LinearProgress />}
          <Card>
            <CardHeader
              title={`Invited (${invites?.length || 0})`}
              avatar={<WorkOutlineOutlined />}
            />
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Work ID/Title</TableCell>
                    <TableCell>Date Invited</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invites?.length == 0 && (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Typography color={"GrayText"}>
                          No current invitations in queue, let's explore new
                          opportunities.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {invites?.map((job) => (
                    <TableRowInvite job={job} key={job.id} />
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          {isFetching && <LinearProgress />}
          <Card>
            <CardHeader
              title={`My Offers (${bids?.length || 0})`}
              avatar={<WorkOutlineOutlined />}
            />
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Work ID/Title</TableCell>
                    <TableCell>Requested Start Date</TableCell>
                    <TableCell>Submitted At</TableCell>
                    <TableCell align="right">Submitted Rate</TableCell>
                    <TableCell align="right">Submitted Estimated Hours</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bids?.length == 0 && (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Typography color={"GrayText"}>
                          No active offers here, start a new offer.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {bids?.map((job) => (
                    <TableRowOffer job={job} key={job.id} />
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          {isFetching && <LinearProgress />}
          <Card>
            <CardHeader
              title={`Cancelled (${canceleds?.length || 0})`}
              avatar={<WorkOutlineOutlined />}
            />
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Work ID/Title</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>Client Name</TableCell>
                    <TableCell>Reason for Cancellation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {canceleds?.length == 0 && (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Typography color={"GrayText"}>
                          No cancelled job
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {canceleds?.map((job) => (
                    <TableRowCanceled job={job} key={job.id} />
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
export default WorkOffersPage;
