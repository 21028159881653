import {
  collection,
  addDoc,
  DocumentData,
  Timestamp,
  where,
  query,
  getDocs,
  orderBy,
  limit,
  setDoc,
  getDoc,
  doc,
} from "firebase/firestore";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { InboxFile, InboxMessage, InboxRoom } from "../types/inbox";
import { TAG_TYPE_INBOX_MESSAGE, TAG_TYPE_INBOX_ROOM } from "./tags";
import { User } from "../types/users";
import { v4 as uuidv4 } from "uuid";
import { firestore, storage } from "../firebase";
import { isProductionMode } from "../utils/helper";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

const roomsRef = isProductionMode()
  ? collection(firestore, "rooms")
  : collection(firestore, "staging.rooms");

const userNotificationsRef = collection(firestore, "user-notifications");

export function getRoomId(uid1: number, uid2: number): string {
  let id = "room_" + (uid1 < uid2 ? uid1 + "_" + uid2 : uid2 + "_" + uid1);
  return id;
}

export const inboxApi = createApi({
  reducerPath: "inboxApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: [TAG_TYPE_INBOX_ROOM, TAG_TYPE_INBOX_MESSAGE],
  refetchOnMountOrArgChange: 60, // refetch after 60s
  refetchOnReconnect: false,
  refetchOnFocus: false,
  endpoints: (builder) => ({
    getInboxRooms: builder.query<Array<InboxRoom>, { userId: number }>({
      queryFn: async (params) => {
        try {
          const rooms: Array<InboxRoom> = [];

          const q = query(
            roomsRef,
            where("users", "array-contains", params.userId)
          );
          const response = await getDocs(q);
          response?.forEach((doc: DocumentData) => {
            const docData = doc.data() as InboxRoom;
            rooms.push({ ...docData, id: doc.id });
          });

          const sorted = rooms.sort(
            (a, b) =>
              (b.last_message?.timestamp?.seconds || 0) -
              (a.last_message?.timestamp?.seconds || 0)
          );
          return { data: sorted };
        } catch (error) {
          return { error };
        }
      },
      providesTags: (result, error, param) => {
        const data = result;
        if (!data) return [{ type: TAG_TYPE_INBOX_ROOM, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE_INBOX_ROOM, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE_INBOX_ROOM, id })),
        ];
      },
    }),
    getInboxRoomMessages: builder.query<
      Array<InboxMessage>,
      { roomId: string }
    >({
      queryFn: async ({ roomId }) => {
        try {
          const messages: Array<InboxMessage> = [];

          const roomRef = doc(roomsRef, roomId);
          const messagesRef = collection(roomRef, "messages");
          const q = query(
            messagesRef,
            orderBy("timestamp", "desc"),
            limit(500)
          );

          const response = await getDocs(q);
          response?.forEach((doc: DocumentData) => {
            const docData = doc.data() as InboxMessage;
            messages.push({
              ...docData,
              id: doc.id,
            });
          });
          return { data: messages };
        } catch (error) {
          return { error };
        }
      },
      providesTags: (result, error, params) => {
        return [{ type: TAG_TYPE_INBOX_MESSAGE, id: params.roomId }];
      },
    }),
    sendInboxMessage: builder.mutation<
      any,
      { sender: User; target: User; message: string; file?: InboxFile }
    >({
      queryFn: async ({ sender, target, message, file }) => {
        const roomId = getRoomId(sender.id, target.id);

        try {
          const roomRef = doc(roomsRef, roomId);
          const room = await getDoc(roomRef);

          if (!room.exists()) {
            // Create a new room
            await setDoc(roomRef, {
              users: [sender.id, target.id],
              last_message: {},
              last_read: {
                [sender.id]: Timestamp.fromMillis(0),
                [target.id]: Timestamp.fromMillis(0),
              },
              user_info: {
                [sender.id]: {
                  first_name: sender.first_name,
                  last_name: sender.last_name,
                  photo_url: sender.photo?.[0]?.original_url,
                },
                [target.id]: {
                  first_name: target.first_name,
                  last_name: target.last_name,
                  photo_url: target.photo?.[0]?.original_url,
                },
              },
            });
          }

          const msgPayload = {
            sender_id: sender.id,
            message,
            timestamp: Timestamp.now(),
            file: !!file ? file : null,
          };

          const messagesRef = collection(roomRef, "messages");
          await addDoc(messagesRef, msgPayload);

          await setDoc(
            roomRef,
            {
              last_message: msgPayload,
              last_read: {
                [sender.id]: Timestamp.now(),
              },
            },
            { merge: true }
          );

          await addDoc(userNotificationsRef, {
            title: sender.full_name,
            body: !!file ? "Sent a file" : message,
            type: "inbox",
            extra: JSON.stringify({ roomId }),
            timestamp: Timestamp.now(),
            userId: target.id.toString(),
            fcmToken: target.device_token,
          });

          return { data: msgPayload };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: (result, error, params) => {
        const roomId = getRoomId(params.sender.id, params.target.id);
        return [
          { type: TAG_TYPE_INBOX_MESSAGE, id: roomId },
          { type: TAG_TYPE_INBOX_ROOM, id: "PARTIAL-LIST" },
        ];
      },
    }),
    uploadInboxFile: builder.mutation<
      InboxFile,
      { roomId: string; file: File }
    >({
      queryFn: async ({ file, roomId }) => {
        try {
          let filename = "";
          let ext = file.name?.split(".").pop();
          filename = `${uuidv4()}.${ext}`;

          const storageRef = isProductionMode()
            ? ref(storage, `rooms/${roomId}`)
            : ref(storage, `staging.rooms/${roomId}`);

          const fileRef = ref(storageRef, filename);
          await uploadBytes(fileRef, file);

          const url = await getDownloadURL(fileRef);
          return {
            data: {
              url,
              mime: file.type,
              filename,
            },
          };
        } catch (error) {
          return { error };
        }
      },
    }),
    markRoomAsRead: builder.mutation<any, { roomId: string; reader: User }>({
      queryFn: async ({ roomId, reader }) => {
        try {
          const roomRef = doc(roomsRef, roomId);
          const room = await getDoc(roomRef);

          if (room.exists()) {
            await setDoc(
              roomRef,
              {
                last_read: {
                  [reader.id]: Timestamp.now(),
                },
              },
              { merge: true }
            );
          }
          return { data: "ok" };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: (result, error, params) => {
        return [{ type: TAG_TYPE_INBOX_ROOM, id: "PARTIAL-LIST" }];
      },
    }),
  }),
});

export const {
  useGetInboxRoomsQuery,
  useGetInboxRoomMessagesQuery,
  useSendInboxMessageMutation,
  useUploadInboxFileMutation,
  useMarkRoomAsReadMutation,
} = inboxApi;
