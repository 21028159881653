import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { useTitle } from "../../hooks/useTitle";
import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import {
  useDeleteForumThreadMutation,
  useDeleteThreadCommentMutation,
  useGetForumThreadByIdQuery,
  useGetForumThreadCommentsQuery,
  useLikeForumThreadMutation,
} from "../../services/forum.service";
import dayjs from "dayjs";
import { decode } from "html-entities";
import {
  CommentOutlined,
  DeleteOutlineOutlined,
  DownloadOutlined,
  EditOutlined,
  FlagOutlined,
  PersonOutlineOutlined,
  ThumbUp,
  ThumbUpOutlined,
} from "@mui/icons-material";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import { toast } from "react-toastify";
import { useState } from "react";
import InputComment from "./InputComment";
import CardComment from "./CardComment";
import ClickableAvatar from "../../components/ClickableAvatar";

const ThreadDetailPage = () => {
  const { threadId } = useParams();
  const navigate = useNavigate();
  const user = useCurrentUser();

  const [deleteModal, setDeleteModal] = useState<{
    open: boolean;
  }>({
    open: false,
  });

  const {
    data: thread,
    isLoading,
    isFetching,
    isError,
  } = useGetForumThreadByIdQuery(
    { id: parseInt(threadId!) },
    {
      skip: !threadId,
    }
  );

  const {
    data: comments,
    isLoading: loadingComments,
    isFetching: fetchingComments,
  } = useGetForumThreadCommentsQuery(
    { thread_id: thread?.id! },
    {
      skip: !thread,
    }
  );

  const [doLike, { isLoading: liking }] = useLikeForumThreadMutation();
  const [doDelete, { isLoading: deleting }] = useDeleteForumThreadMutation();
  const [doDeleteComment, { isLoading: deletingComment }] =
    useDeleteThreadCommentMutation();

  const handleLike = async () => {
    try {
      if (!thread) return;

      await doLike({ id: thread.id, is_like: !thread.is_like });
    } catch (err) {
      toast.error(err);
    }
  };

  const closeDeleteModal = () =>
    setDeleteModal({ ...deleteModal, open: false });

  const handleDelete = async () => {
    if (!!threadId) {
      try {
        await doDelete({ thread_id: parseInt(threadId) }).unwrap();
        closeDeleteModal();

        navigate("/live-feed");
        toast.success("Successfully deleted");
      } catch (err) {
        closeDeleteModal();
      }
    }
  };

  useTitle(thread?.title || "");

  if (!isFetching && isError) {
    return (
      <Box>
        <Alert severity="error">
          <AlertTitle>Error Loading Feed</AlertTitle>
          The feed probably no longer exists. If you think this is a mistake,
          please reload several times. If the issue persists, please contact our
          Support.
        </Alert>
      </Box>
    );
  }

  return (
    <Box>
      {(isLoading || isFetching || liking || deleting) && <LinearProgress />}
      <Box sx={{ bgcolor: "background.paper", width: "auto" }} boxShadow={1}>
        <Card>
          <CardHeader
            avatar={<ClickableAvatar talent={thread?.user} />}
            title={thread?.user?.full_name || "?"}
            subheader={
              <Stack direction={"row"}>
                <Typography>
                  {dayjs(thread?.created_at).format("MMM DD YYYY, hh:mm A")} ‣
                  {thread?.total_view || 0} views
                </Typography>
              </Stack>
            }
            subheaderTypographyProps={{ fontSize: 14 }}
            action={
              <IconButton
                onClick={() =>
                  navigate({
                    pathname: "/live-feed/send-feedback",
                    search: createSearchParams({
                      type: "Report a Feed",
                      info: `Thread:${thread?.title} (ID:${thread?.id})`,
                    }).toString(),
                  })
                }
              >
                <FlagOutlined color="error" />
              </IconButton>
            }
          />
          <CardContent>
            <Box
              sx={{
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
                mb: 1,
              }}
            >
              <Typography
                variant="h3"
                sx={{ fontSize: 20, fontWeight: "400", mb: 2 }}
              >
                {thread?.title}
              </Typography>
              <Typography variant="body1">
                {decode(thread?.body) || ""}
              </Typography>
            </Box>
            <Typography variant="caption">
              Tags: {thread?.tags || ""}
            </Typography>
          </CardContent>
          {(thread?.documents?.length || 0) > 0 && (
            <CardContent>
              <ImageList cols={6} rowHeight={400} gap={20}>
                {thread?.documents?.map((item) => (
                  <ImageListItem key={item.id}>
                    <img
                      src={`${item.original_url}`}
                      alt={item.file_name}
                      loading="lazy"
                    />
                    <ImageListItemBar
                      position="top"
                      actionIcon={
                        <IconButton
                          onClick={() =>
                            window.open(item.original_url, "_blank")
                          }
                          color="warning"
                        >
                          <DownloadOutlined />
                        </IconButton>
                      }
                    />
                  </ImageListItem>
                ))}
                || <></>
              </ImageList>
            </CardContent>
          )}

          {/* buttons etc */}
          <CardActions>
            <Button
              startIcon={thread?.is_like ? <ThumbUp /> : <ThumbUpOutlined />}
              // size="small"
              sx={{ ml: 1 }}
              onClick={handleLike}
            >
              {(thread?.total_likes || 0) > 0 ? `${thread?.total_likes} ` : ``}
              {thread?.is_like ? "Liked" : "Like"}
            </Button>
            <Button startIcon={<CommentOutlined />}>
              {thread?.total_comments || 0 > 0
                ? `${thread?.total_comments} `
                : ``}
              Comments
            </Button>
            <Box style={{ flex: 1 }} />
            {user?.id == thread?.user?.id && (
              <>
                <Button
                  startIcon={<EditOutlined />}
                  onClick={() => navigate("/live-feed/" + threadId + "/edit")}
                >
                  Edit
                </Button>
                <Button
                  startIcon={<DeleteOutlineOutlined />}
                  color="error"
                  onClick={() => setDeleteModal({ open: true })}
                />
              </>
            )}
          </CardActions>

          {/* comment input */}
          {!!thread && !thread.is_locked ? (
            <CardContent>
              <InputComment thread={thread} />
            </CardContent>
          ) : (
            <Alert severity="info">
              <AlertTitle>Comment Disabled</AlertTitle>Feed is locked by Admin
            </Alert>
          )}

          {/* comments section */}
          <CardContent>
            {(loadingComments || fetchingComments) && <LinearProgress />}
            <Typography variant="body1">Comment(s):</Typography>
            {comments?.length == 0 && (
              <Typography variant="body2" color={"GrayText"} sx={{ mt: 2 }}>
                No comment, yet
              </Typography>
            )}
            {!!thread &&
              comments?.map((comment) => (
                <CardComment
                  thread={thread}
                  comment={comment}
                  onDelete={(comm) =>
                    doDeleteComment({
                      thread_id: thread.id,
                      comment_id: comm.id,
                    })
                  }
                />
              ))}
          </CardContent>
        </Card>
        <Divider />
      </Box>
      <ConfirmDeleteModal
        open={deleteModal.open}
        onClose={closeDeleteModal}
        onDelete={handleDelete}
        message={`Delete Feed ?`}
      />
    </Box>
  );
};
export default ThreadDetailPage;
