import * as React from "react";
import {
  Link,
  Rating,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { ProjectResource } from "../../types/project";
import { formatWithTimezone } from "../../utils/dateHelper";

const TableRowInvite = ({ job }: { job: ProjectResource }) => {
  const navigate = useNavigate();
  const startDate = React.useMemo(() => {
    const st = dayjs(job?.start_date || job?.project?.start_date).toDate();
    return st;
  }, [job]);

  return (
    <TableRow>
      <TableCell>
        <Link
          component="button"
          variant="body1"
          onClick={() => navigate(`/works/${job.project_id}`)}
        >
          #{job.project_id} - {job.project?.title}
        </Link>
        <Stack direction={"row"} alignItems={"center"}>
          <Typography variant="body2" sx={{ mr: 1 }}>
            {job.project?.company?.name}
          </Typography>
          <Rating
            value={job.project?.company?.total_rating || 0}
            readOnly
            precision={0.5}
            size="small"
          />
        </Stack>
      </TableCell>
      <TableCell>
        {formatWithTimezone(
          job?.created_at,
          job?.timezone || "",
          "ddd, MMM DD YYYY"
        )}
      </TableCell>
      <TableCell>
        {formatWithTimezone(startDate, job?.timezone || "", "ddd, MMM DD YYYY")}
        <br />
        <Typography variant="caption">
          {formatWithTimezone(startDate, job?.timezone || "", "hh:mm A z")}
        </Typography>
      </TableCell>
      <TableCell>Awaiting Your Response</TableCell>
    </TableRow>
  );
};
export default TableRowInvite;
