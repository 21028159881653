import {OAUTH_CLIENT_ID, OAUTH_CLIENT_SECRET} from '../configs/api';
import {Talent, User} from '../types/users';
import {baseApi} from './base';
import {TAG_TYPE_USER} from './tags';

export interface LoginResponse {
  token_type: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

export interface LoginRequest {
  username: string;
  password: string;
}

const apiWithTag = baseApi.enhanceEndpoints({addTagTypes: [TAG_TYPE_USER]});

export const authApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: req => {
        return {
          url: 'oauth/token',
          method: 'POST',
          body: {
            username: req.username,
            password: req.password,
            grant_type: 'password',
            client_id: OAUTH_CLIENT_ID,
            client_secret: OAUTH_CLIENT_SECRET,
            scope: '*',
          },
        };
      },
    }),
    loadUser: builder.query<Talent, void>({
      query: () => ({
        url: 'api/me',
        method: 'GET',
        params: {
          load: 'photo,country,businessVerticals,languages,skills,certifications.certification,educations,workExperiences,talent',
        },
      }),
      providesTags: result => [{type: TAG_TYPE_USER, id: 'me'}],
    }),
    getUser: builder.query<Talent, void>({
      query: () => ({
        url: 'api/me',
        method: 'GET',
        params: {
          load: 'photo,country,businessVerticals,languages,skills,certifications.certification,educations,workExperiences,talent',
        },
      }),
      providesTags: result => [{type: TAG_TYPE_USER, id: 'me'}],
    }),
    logout: builder.mutation<{message: string}, void>({
      query: () => ({
        url: 'api/logout',
        method: 'POST',
      }),
    }),
    register: builder.mutation<Talent, Partial<Talent>>({
      query: body => ({
        url: 'api/registers',
        method: 'POST',
        body,
      }),
    }),
    forgotPassword: builder.mutation<{message: string}, {email: string}>({
      query: body => ({
        url: 'api/forgot-password',
        method: 'POST',
        body,
      }),
    }),
    resetPassword: builder.mutation<
      Talent,
      {email: string; reset_code: string; password: string}
    >({
      query: body => ({
        url: 'api/reset-password',
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useLoadUserQuery,
  useGetUserQuery,
  useLogoutMutation,
  useRegisterMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApi;
