import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { currencyFormat } from "../../utils/helper";
import {
  NegotiationStatus,
  Project,
  ProjectResource,
} from "../../types/project";
import { formatWithTimezone } from "../../utils/dateHelper";
import {
  AttachMoneyOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@mui/icons-material";
import ConfirmModal from "../../components/ConfirmModal";
import { useCallback, useMemo, useState } from "react";
import {
  useAcceptProjectInvitationMutation,
  useWithdrawBidMutation,
} from "../../services/project.service";
import { toast } from "react-toastify";
import ModalBid from "./ModalBid";

const BidView = ({
  project,
  job,
}: {
  project: Project;
  job: ProjectResource;
}) => {
  const [doWithdraw, { isLoading: isWithdrawing }] = useWithdrawBidMutation();
  const [doAcceptInvitation, { isLoading: isAccepting }] =
    useAcceptProjectInvitationMutation();

  const [confirmAcceptModalOpen, setConfirmAcceptModalOpen] = useState(false);
  const [confirmRejectModalOpen, setConfirmRejectModalOpen] = useState(false);
  const [modalBidOpen, setModalBidOpen] = useState(false);

  const canAgreeClientRate = useMemo(() => {
    if (!project) return false;
    const wd = dayjs.tz(project.start_date, project.timezone).tz();
    return dayjs().isBefore(wd);
  }, [project]);

  const handleAccept = useCallback(async () => {
    try {
      await doAcceptInvitation({
        projectId: project?.id!,
        rate: project?.type == "hourly" ? project?.hourly_rate : undefined,
        max_hour:
          project?.type == "hourly"
            ? project?.approximate_hours_to_complete
            : undefined,
        price: project?.type == "fixed" ? project?.total_pay : undefined,
        start_date: formatWithTimezone(
          project?.start_date,
          project?.timezone,
          "YYYY-MM-DD HH:mm"
        ),
        timezone: project?.timezone,
      }).unwrap();
    } catch (err: any) {
      toast.error(err);
    }
  }, [project, doAcceptInvitation]);

  const handleReject = useCallback(async () => {
    try {
      await doWithdraw(project.id).unwrap();
    } catch (err: any) {
      toast.error(err);
    }
  }, [project, doWithdraw]);

  return (
    <>
      <Card sx={{ mt: 0, mb: 1, borderColor: "secondary.main" }}>
        <CardHeader
          title={
            job.status == NegotiationStatus.INVITED
              ? "Customer Offer"
              : "Current Offer"
          }
          subheader={
            job.status == NegotiationStatus.INVITED
              ? "You have been invited to this work opportunity"
              : "Pending Customer Response"
          }
          sx={{ backgroundColor: "secondary.main" }}
          action={
            <Button
              variant="contained"
              color="error"
              sx={{ mt: 1, mr: 1 }}
              startIcon={<CloseOutlined />}
              onClick={() => setConfirmRejectModalOpen(true)}
            >
              Cancel Offer
            </Button>
          }
        />
        {(isWithdrawing || isAccepting) && <LinearProgress />}
        <CardContent>
          <Table sx={{ fontSize: 14 }} size="small">
            <TableBody>
              <TableRow>
                <TableCell width={"20%"}>Contract Type</TableCell>
                <TableCell>
                  {project.type == "hourly" ? "Hourly" : "Fixed Price"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={"20%"}>Rate</TableCell>
                <TableCell>
                  {project.type == "hourly"
                    ? `${currencyFormat(project.hourly_rate || 0)} /hr`
                    : currencyFormat(project.total_pay || 0)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={"20%"}>Start Date</TableCell>
                <TableCell>
                  {formatWithTimezone(
                    dayjs(project.start_date).toDate(),
                    project.timezone
                  )}
                </TableCell>
              </TableRow>
              {job.status != NegotiationStatus.INVITED && (
                <>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography color={"primary.main"} fontWeight={"bold"}>
                        My Offer →
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Rate</TableCell>
                    <TableCell>
                      {project.type == "hourly"
                        ? `${currencyFormat(job.rate || 0)}/hr`
                        : currencyFormat(job.price || 0)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Requested Start Date</TableCell>
                    <TableCell>
                      {formatWithTimezone(
                        dayjs(job.start_date).toDate(),
                        job.timezone
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Submitted At</TableCell>
                    <TableCell>
                      {formatWithTimezone(
                        dayjs(job.updated_at).toDate(),
                        job.timezone
                      )}
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end", mb: 1 }}>
          <Button
            variant="contained"
            color="success"
            disabled={
              !canAgreeClientRate || job?.status != NegotiationStatus.INVITED
            }
            startIcon={<CheckOutlined />}
            onClick={() => setConfirmAcceptModalOpen(true)}
          >
            Accept Customer's Offer
          </Button>
          <Button
            variant="outlined"
            color="info"
            startIcon={<AttachMoneyOutlined />}
            onClick={() => setModalBidOpen(true)}
          >
            {job?.status == NegotiationStatus.INVITED
              ? "Counter Offer"
              : "Edit Counter Offer"}
          </Button>
        </CardActions>
      </Card>
      <ConfirmModal
        open={confirmRejectModalOpen}
        title={"Cancell Offer"}
        message="Are you sure want to Cancell this work offer?"
        onClose={() => setConfirmRejectModalOpen(false)}
        onConfirm={handleReject}
      />
      <ConfirmModal
        open={confirmAcceptModalOpen}
        title={"Agree Customer's Rate"}
        message="Are you sure want to match the Customer's rate and submit the offer?"
        onClose={() => setConfirmAcceptModalOpen(false)}
        onConfirm={handleAccept}
      />
      <ModalBid
        open={modalBidOpen}
        project={project}
        bid={job}
        onClose={() => setModalBidOpen(false)}
      />
    </>
  );
};

export default BidView;
