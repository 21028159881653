import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Rating,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../../hooks/useTitle";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import {
  BusinessCenterOutlined,
  EditOutlined,
  SchoolOutlined,
  SubtitlesOutlined,
} from "@mui/icons-material";
import { currencyFormat, nl2br } from "../../utils/helper";
import { useGetUserQuery } from "../../services/auth.service";

const AccountPage = () => {
  const user = useCurrentUser();
  const navigate = useNavigate();

  useTitle("My Account");

  const { data, isFetching } = useGetUserQuery();

  return (
    <Box>
      <Grid container spacing={2}>
        {/* header */}
        <Grid item xs={12}>
          <Stack direction={"row"} alignItems="center">
            <Avatar
              // variant="square"
              sx={{ bgColor: "primary.main", width: 100, height: 100, mr: 2 }}
              src={data?.photo?.[0]?.original_url}
            />
            <Stack direction="column" flex={1}>
              <Stack direction="row" alignItems="center">
                <Typography variant="h5">{data?.full_name}</Typography>
                <Rating
                  value={data?.total_rating || 0}
                  readOnly
                  sx={{ ml: 2 }}
                />
              </Stack>
              <Typography>
                {data?.city} {data?.state}
              </Typography>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography sx={{ color: "#666" }}>
                  Your current balance:{" "}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    ml: 1,
                    color: "primary.main",
                  }}
                >
                  {currencyFormat(user?.balance_decrypt || 0)}
                </Typography>
                {/* made button contained, etc */}
                <Link
                  component="button"
                  onClick={() => navigate(`/account/balance-history`)}
                  sx={{ ml: 1, padding: '6px 16px', 
                    backgroundColor:'primary.main', 
                    color:'primary.contrastText', 
                    borderRadius:'4px', 
                    textTransform:'none', '&:hover': {
                    backgroundColor:'primary.dark',
                    textDecoration: 'none'},
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                Balance History
                </Link>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        {/* main content */}
        <Grid item sm={12} lg={8}>
          {isFetching && <LinearProgress />}
          <Card>
            <CardHeader title="About" />
            <CardContent>{nl2br(data?.bio || "n/a")}</CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Skills" />
            <CardContent>
              {data?.skills?.length == 0 ? (
                <Typography variant="body2" color="gray">
                  No skills added
                </Typography>
              ) : (
                data?.skills?.map((it) => (
                  <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                ))
              )}
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Industries" />
            <CardContent>
              {data?.business_verticals?.length == 0 ? (
                <Typography variant="body2" color="gray">
                  No industries added
                </Typography>
              ) : (
                data?.business_verticals?.map((it) => (
                  <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                ))
              )}
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Languages" />
            <CardContent>
              {data?.languages?.length == 0 ? (
                <Typography variant="body2" color="gray">
                  No languages added
                </Typography>
              ) : (
                data?.languages?.map((it) => (
                  <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                ))
              )}
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Certificates & Licenses" />
            <CardContent>
              <List>
                {data?.certifications?.length == 0 ? (
                  <Typography variant="body2" color="gray">
                    No certificates added
                  </Typography>
                ) : (
                  data?.certifications?.map((it) => (
                    <ListItem key={it.id}>
                      <ListItemIcon>
                        <SubtitlesOutlined />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${it.certification?.name} (${it.certification?.provider})`}
                        secondary={`Issued: ${it.issue_date}`}
                      />
                    </ListItem>
                  ))
                )}
              </List>
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Work Experiences" />
            <CardContent>
              <List>
                {data?.work_experiences?.length == 0 ? (
                  <Typography variant="body2" color="gray">
                    No work experiences added
                  </Typography>
                ) : (
                  data?.work_experiences?.map((it) => (
                    <ListItem key={it.id}>
                      <ListItemIcon>
                        <BusinessCenterOutlined />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${it.company}`}
                        secondary={`${it.start_period_year || "?"} - ${
                          it.end_period_year || ""
                        }`}
                      />
                    </ListItem>
                  ))
                )}
              </List>
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Educations" />
            <CardContent>
              <List>
                {data?.educations?.length == 0 ? (
                  <Typography variant="body2" color="gray">
                    No educations added
                  </Typography>
                ) : (
                  data?.educations?.map((it) => (
                    <ListItem key={it.id}>
                      <ListItemIcon>
                        <SchoolOutlined />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${it.school}`}
                        secondary={`${it.field_of_study || ""}`}
                      />
                    </ListItem>
                  ))
                )}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} lg={4}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            startIcon={<EditOutlined />}
            disabled={!data}
            onClick={() => navigate(`/account/edit`)}
          >
            Edit Profile
          </Button>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Contact" />
            <CardContent>
              <Table sx={{ fontSize: 14 }}>
                <TableBody>
                  <TableRow>
                    <TableCell width="50%">Email</TableCell>
                    <TableCell>{data?.email || "n/a"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="50%">Phonenumber</TableCell>
                    <TableCell>{data?.phone_number || "n/a"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="50%">Address 1</TableCell>
                    <TableCell>{data?.physical_address_1 || "n/a"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Address 2</TableCell>
                    <TableCell>{data?.physical_address_2 || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>City / Zip</TableCell>
                    <TableCell>
                      {data?.city} / {data?.zip}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>State</TableCell>
                    <TableCell>{data?.state || "?"}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Work Settings" />
            <CardContent>
              <Table sx={{ fontSize: 14 }} size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>Work Status</TableCell>
                    <TableCell align="right">
                      {data?.talent?.work_status?.toUpperCase() || "?"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Rate</TableCell>
                    <TableCell align="right">
                      {data?.min_pay || "?"} - {data?.max_pay || "?"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Commute Radius</TableCell>
                    <TableCell align="right">
                      {data?.commute_radius || "?"} miles
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Background Check</TableCell>
                    <TableCell align="right">
                      {!!data?.allow_background_check ? "Yes" : "No"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Drug Test</TableCell>
                    <TableCell align="right">
                      {!!data?.allow_drug_test ? "Yes" : "No"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Valid U.S Citizen or Have a Work Visa</TableCell>
                    <TableCell align="right">
                      {!!data?.talent?.is_valid_us ? "Yes" : "No"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Availabilty" />
            <CardContent>
              <Table sx={{ fontSize: 14 }} size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>Monday</TableCell>
                    <TableCell align="right">
                      {!!data?.talent?.avail_monday ? "✅" : "❌"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Tuesday</TableCell>
                    <TableCell align="right">
                      {!!data?.talent?.avail_tuesday ? "✅" : "❌"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Wednesday</TableCell>
                    <TableCell align="right">
                      {!!data?.talent?.avail_wednesday ? "✅" : "❌"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Thursday</TableCell>
                    <TableCell align="right">
                      {!!data?.talent?.avail_thursday ? "✅" : "❌"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Friday</TableCell>
                    <TableCell align="right">
                      {!!data?.talent?.avail_friday ? "✅" : "❌"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Saturday</TableCell>
                    <TableCell align="right">
                      {!!data?.talent?.avail_saturday ? "✅" : "❌"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Sunday</TableCell>
                    <TableCell align="right">
                      {!!data?.talent?.avail_sunday ? "✅" : "❌"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Social Medias" />
            <CardContent>
              <Table sx={{ fontSize: 14 }}>
                <TableBody>
                  <TableRow>
                    <TableCell width="50%">LinkedIn</TableCell>
                    <TableCell>{data?.linkedin || "n/a"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Facebook</TableCell>
                    <TableCell>{data?.facebook || "n/a"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Instagram</TableCell>
                    <TableCell>{data?.instagram || "n/a"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Website</TableCell>
                    <TableCell>{data?.website || "n/a"}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AccountPage;
