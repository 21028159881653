import { Talent } from "../types/users";
import { QueryResponse, baseApi } from "./base";
import { TAG_TYPE_TALENT } from "./tags";

const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [TAG_TYPE_TALENT] });

export const talentApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getTalents: builder.query<
      QueryResponse<Talent>,
      {
        page?: number;
        pageSize?: number;
        search?: string;
      }
    >({
      query: ({ page = 0, pageSize = 100, search }) => ({
        url: "api/talents",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          // sort: 'first_name|asc',
          load: "photo",
          search,
        },
      }),
      providesTags: (result) => {
        const data = result;
        if (!data) return [{ type: TAG_TYPE_TALENT, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE_TALENT, id: "PARTIAL-LIST" },
          ...data?.data?.map(({ id }) => ({ type: TAG_TYPE_TALENT, id })),
        ];
      },
    }),
    getTalentById: builder.query<Talent, { id: number; load?: string }>({
      query: ({ id, load }) => ({
        url: `api/talents/${id}`,
        method: "GET",
        params: {
          load: load || "photo,talent,skills,business_verticals",
        },
      }),
      providesTags: (result, error, params) => [
        { type: TAG_TYPE_TALENT, id: params.id },
      ],
    }),
    getUserDetail: builder.query<Talent, number>({
      query: (id) => ({
        url: `api/talents/${id}`,
        method: "GET",
        params: {
          load: "photo,country,businessVerticals,languages,skills,certifications.certification,educations,workExperiences,talent",
        },
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE_TALENT, id }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetTalentsQuery, useGetTalentByIdQuery } = talentApi;
