import * as React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useGetCertificationsQuery } from "../services/certification.service";
import { Certification } from "../types/certification";

const SelectCertification = ({
  onChange,
  value,
  error,
  helperText,
}: {
  onChange: (arg?: number) => void;
  value: number;
  error?: boolean;
  helperText?: React.ReactNode;
}) => {
  const { data: certs, isLoading, isFetching } = useGetCertificationsQuery({});

  const selected = React.useMemo(
    () => certs?.find((it) => it.id == value),
    [certs, value]
  );

  return (
    <Autocomplete
      fullWidth
      options={certs || []}
      value={selected || null}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Certification"
          placeholder="Search..."
          error={error}
          helperText={helperText}
        />
      )}
      onChange={(event: any, newValue: Certification | null) => {
        onChange(newValue?.id);
      }}
      loading={isLoading}
    />
  );
};
export default SelectCertification;
