import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Link,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { NegotiationStatus, Project, ProjectStatus } from "../../types/project";
import { getDistanceBetweenPoints } from "../../utils/mapHelper";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { formatWithTimezone } from "../../utils/dateHelper";
import { decode } from "html-entities";
import { RoomOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router";

export const DEF_LAT = 38.8976763;
export const DEF_LNG = -77.0387238;

const CardProject = ({
  project,
  userCoord,
}: {
  project: Project;
  userCoord: GeolocationCoordinates | undefined;
}) => {
  const user = useCurrentUser();
  const navigate = useNavigate();

  const { city, lat, lng } = React.useMemo(() => {
    if (!!project.customer) {
      const city = project.customer.city;
      // const state = project.customer.state;
      const [lng, lat] = project.customer.coordinate.coordinates;
      return { city, lat, lng };
    } else if (!!project.location) {
      const city = project.location.city;
      // const state = project.location.state;
      const [lng, lat] = project.location.coordinate.coordinates;
      return { city, lat, lng };
    } else return { city: "?", lat: 0, lng: 0 };
  }, [project]);

  // const myBid = React.useMemo(() => {
  //   return item?.resources?.find(it => it.resource_id == user?.id);
  // }, [user, item]);

  const distanceInMiles = React.useMemo(
    () =>
      getDistanceBetweenPoints(
        {
          lat: userCoord?.latitude || DEF_LAT,
          lng: userCoord?.longitude || DEF_LNG,
        },
        { lat: lat, lng: lng }
      ),
    [userCoord, lat, lng]
  );

  const {
    text: statusText,
    color: statusBgColor,
    show: showStatus,
    disabled,
  } = React.useMemo(() => {
    const jobTaken =
      project.num_of_talent == 1 &&
      [ProjectStatus.SCHEDULED, ProjectStatus.IN_PROGRESS].includes(
        project.status
      );
    const myBid = project?.resources?.find((it) => it.resource_id == user?.id);

    if (jobTaken) {
      if (!!myBid) {
        // we have bid on this job
        if (myBid.status == NegotiationStatus.COMPLETED) {
          return {
            text: "Work Completed",
            color: "success",
            show: true,
            disabled: false,
          };
        } else if (myBid.status == NegotiationStatus.HIRED) {
          return {
            text: "Active",
            color: "info",
            show: true,
            disabled: false,
          };
        } else if (myBid.status == NegotiationStatus.CANCELED) {
          return {
            text: "Cancelled Work",
            color: "error",
            show: true,
            disabled: true,
          };
        } else {
          // job taken by other worker, our bid lost
          return {
            text: "Work Already Taken",
            color: "GrayText",
            show: true,
            disabled: true,
          };
        }
      } else {
        // job taken by other worker, our bid lost
        return {
          text: "Work Already Taken",
          color: "GrayText",
          show: true,
          disabled: true,
        };
      }
    } else {
      // job is still available, jobTaken = false
      if (!!myBid) {
        // we have bid on this job
        if (
          myBid.status == NegotiationStatus.NEW ||
          myBid.status == NegotiationStatus.INTERVIEW ||
          myBid.status == NegotiationStatus.REJECTED
        ) {
          return {
            text: "Request Submitted",
            color: "warning",
            show: true,
            disabled: false,
          };
        } else if (myBid.status == NegotiationStatus.INVITED) {
          return {
            text: "Invited: Awaiting Your Response",
            color: "info",
            show: true,
            disabled: false,
          };
        } else {
          return {
            text: myBid.status || "?",
            color: "warning",
            show: true,
            disabled: true,
          };
        }
      } else {
        // we have no bid
        return {
          text: "",
          color: "GrayText",
          show: false,
          disabled: false,
        };
      }
    }
  }, [project]);

  const openProjectPage = React.useCallback(() => {
    navigate("/works/search/" + project.id);
  }, [project, navigate]);

  return (
    <Card sx={{ mb: 1 }}>
      <CardHeader
        title={
          <Link component="button" onClick={openProjectPage}>
            #{project.id} {project.title}
          </Link>
        }
        titleTypographyProps={{ fontSize: 18, color: "primary.main" }}
        subheader={project.type == "hourly" ? "Hourly" : "Fixed Price"}
        subheaderTypographyProps={{ fontSize: 14 }}
        action={
          <Box sx={{ mt: 1 }}>
            <Typography variant="body1" textAlign={"right"}>
              {formatWithTimezone(
                project.start_date,
                project.timezone,
                "ddd, MMM D"
              )}
            </Typography>
            <Typography variant="body2" textAlign={"right"}>
              {formatWithTimezone(
                project.start_date,
                project.timezone,
                "h:mmA z"
              )}
            </Typography>
          </Box>
        }
      />
      <CardContent>
        <Typography variant="body2">{decode(project.description)}</Typography>
        <Box sx={{ mt: 1 }}>
          {!!project.skills &&
            project.skills?.map((it) => (
              <Chip key={it.id} label={it.name} size="small" sx={{ mr: 1 }} />
            ))}
        </Box>
        <Divider sx={{ mt: 2, mb: 1 }} />
        <Stack direction={"row"}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="body2">{project?.company?.name}</Typography>
            <Rating
              value={project?.company?.total_rating}
              precision={0.5}
              readOnly
              size="small"
            />
          </Box>
          <Box textAlign={"right"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <RoomOutlined fontSize="small" />
              <Typography variant="body2">{city}</Typography>
            </Stack>
            <Typography variant="body2">
              {distanceInMiles.toFixed(1)} miles
            </Typography>
          </Box>
        </Stack>
        {showStatus && (
          <Stack alignItems={"center"}>
            <Chip
              label={statusText}
              color={statusBgColor as any}
              size="small"
            />
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};
export default CardProject;
