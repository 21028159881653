import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface ConfirmDeleteModalProps {
  open: boolean;
  title?: string;
  message: string;
  onDelete: () => Promise<void>;
  onClose: () => void;
}

const ConfirmDeleteModal = ({
  open,
  title,
  message,
  onClose,
  onDelete,
}: ConfirmDeleteModalProps) => {
  const [loading, setLoading] = React.useState(false);

  const handleDelete = React.useCallback(() => {
    setLoading(true);
    onDelete().finally(() => {
      setLoading(false);
    });
  }, [onDelete]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">
        {title || "Confirm Deletion"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message || ""}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ mb: 1 }}>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          autoFocus
          color="error"
          disabled={loading}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDeleteModal;
