import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface UiState {
  title: string | null;
  filterSkills: Array<number>;
  filterVerticals: Array<number>;
  filterRadius: number;
}

const initialState: UiState = {
  title: null,
  filterSkills: [],
  filterVerticals: [],
  filterRadius: 10,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setTitle: (state, action: PayloadAction<string | null>) => {
      state.title = action.payload;
    },
    setFilterSkills: (state, action: PayloadAction<Array<number>>) => {
      state.filterSkills = action.payload;
    },
    setFilterVerticals: (state, action: PayloadAction<Array<number>>) => {
      state.filterVerticals = action.payload;
    },
    setFilterRadius: (state, action: PayloadAction<number>) => {
      state.filterRadius = action.payload;
    },
  },
});

export const {
  setTitle,
  setFilterSkills,
  setFilterVerticals,
  setFilterRadius,
} = uiSlice.actions;

export const currentTitle = (state: RootState) => state.ui.title;

export const currentSearchFilter = (state: RootState) => ({
  skills: state.ui.filterSkills,
  verticals: state.ui.filterVerticals,
  radius: state.ui.filterRadius,
});

export default uiSlice.reducer;
