import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useCreateFeedbackMutation } from "../../services/feedback.service";
import { Box, Grid, Paper, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useChangePasswordMutation } from "../../services/user.service";

const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm({
    defaultValues: {
      old_password: "",
      password: "",
      retype_password: "",
    },
  });

  const onSubmit = async (payload: any) => {
    try {
      const { old_password, password, retype_password } = payload;
      if (password !== retype_password) {
        toast.error("New password and retype password doesnt match!");
        return;
      }
      await changePassword({ old_password, password }).unwrap();
      toast.success("Password succesfully changed");

      setValue("old_password", "");
      setValue("password", "");
      setValue("retype_password", "");
    } catch (err: any) {
      toast.error(err);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Paper sx={{ p: 2 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography>
                The password must have at least 8 characters, at least 1
                digit(s), at least 1 upper case letter(s)
              </Typography>
              <Controller
                name="old_password"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    label="Old Password"
                    value={value}
                    fullWidth
                    type="password"
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.old_password}
                    helperText={!!errors.old_password && "Invalid value"}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    label="New Password"
                    value={value}
                    fullWidth
                    type="password"
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.password}
                    helperText={!!errors.password && "Invalid value"}
                  />
                )}
              />
              <Controller
                name="retype_password"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    label="Retype New Password"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    fullWidth
                    type="password"
                    error={!!errors.retype_password}
                    helperText={!!errors.retype_password && "Invalid value"}
                  />
                )}
              />
              <LoadingButton
                loading={isLoading}
                type="submit"
                variant="contained"
                sx={{ mt: 1 }}
              >
                Change Password
              </LoadingButton>
            </Box>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default ChangePasswordPage;
