import { ForumThread, ThreadComment } from "../types/forum";
import { QueryResponse, baseApi } from "./base";
import { TAG_TYPE_FORUM_COMMENT, TAG_TYPE_FORUM_THREAD } from "./tags";

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_FORUM_THREAD, TAG_TYPE_FORUM_COMMENT],
});

export const forumApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getForumThreads: builder.query<
      QueryResponse<ForumThread>,
      {
        sortField?: string;
        sortMode?: string | null;
        page?: number;
        pageSize?: number;
        pinned?: boolean;
      }
    >({
      query: ({ sortField, sortMode, pinned, page = 1, pageSize = 10 }) => ({
        url: "api/threads",
        method: "GET",
        params: {
          page,
          per_page: pageSize,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `created_at|desc`,
          load: "user,documents",
          pinned,
        },
      }),
      providesTags: (result) => {
        const data = result;
        if (!data) return [{ type: TAG_TYPE_FORUM_THREAD, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE_FORUM_THREAD, id: "PARTIAL-LIST" },
          ...data?.data.map(({ id }) => ({ type: TAG_TYPE_FORUM_THREAD, id })),
        ];
      },
    }),
    getForumThreadById: builder.query<
      ForumThread,
      { id: number; load?: string }
    >({
      query: ({ id, load }) => ({
        url: `api/threads/${id}`,
        method: "GET",
        params: {
          load: load || "user,documents",
        },
      }),
      providesTags: (result, error, { id }) => [
        { type: TAG_TYPE_FORUM_THREAD, id },
      ],
    }),
    createForumThread: builder.mutation<
      ForumThread,
      {
        title: string;
        body: string;
        tags: string;
        files?: Array<File>;
      }
    >({
      query: ({ title, body, tags, files }) => {
        let formData = new FormData();
        formData.append("title", title);
        formData.append("body", body);
        formData.append("tags", tags);
        files?.forEach((file) => {
          formData.append("documents[]", file as any);
        });

        return {
          url: `api/user/threads`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, params) => [
        { type: TAG_TYPE_FORUM_THREAD, id: "PARTIAL-LIST" },
      ],
    }),
    updateForumThread: builder.mutation<
      ForumThread,
      {
        thread_id: number;
        title: string;
        body: string;
        tags: string;
        files?: Array<File>;
      }
    >({
      query: ({ thread_id, title, body, tags, files }) => {
        let formData = new FormData();
        formData.append("title", title);
        formData.append("body", body);
        formData.append("tags", tags);
        formData.append("_method", "put");
        files?.forEach((file) => {
          formData.append("documents[]", file as any);
        });

        return {
          url: `api/user/threads/${thread_id}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, params) => [
        { type: TAG_TYPE_FORUM_THREAD, id: "PARTIAL-LIST" },
        { type: TAG_TYPE_FORUM_THREAD, id: params.thread_id },
      ],
    }),
    deleteForumThread: builder.mutation<
      ForumThread,
      {
        thread_id: number;
      }
    >({
      query: ({ thread_id }) => {
        return {
          url: `api/user/threads/${thread_id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, params) => [
        { type: TAG_TYPE_FORUM_THREAD, id: "PARTIAL-LIST" },
      ],
    }),
    likeForumThread: builder.mutation<
      ForumThread,
      {
        id: number;
        is_like: boolean;
      }
    >({
      query: ({ id, is_like }) => {
        return {
          url: is_like
            ? `api/user/threads/${id}/like`
            : `api/user/threads/${id}/unlike`,
          method: "PUT",
        };
      },
      invalidatesTags: (result, error, params) => [
        { type: TAG_TYPE_FORUM_THREAD, id: "PARTIAL-LIST" },
        { type: TAG_TYPE_FORUM_THREAD, id: params.id },
      ],
    }),
    getForumThreadComments: builder.query<
      Array<ThreadComment>,
      { thread_id: number; sortField?: string; sortMode?: string | null }
    >({
      query: ({ thread_id, sortField, sortMode }) => ({
        url: `api/threads/${thread_id}/comments`,
        method: "GET",
        params: {
          load: "childs,childs.owner,childs.documents,documents",
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `created_at|desc`,
        },
      }),
      providesTags: (result, error, params) => {
        return [{ type: TAG_TYPE_FORUM_COMMENT, id: params.thread_id }];
      },
    }),
    sendThreadComment: builder.mutation<
      ForumThread,
      {
        thread_id: number;
        comment: string;
        files?: Array<File>;
      }
    >({
      query: ({ thread_id, comment, files }) => {
        let formData = new FormData();
        formData.append("comment", comment);
        files?.forEach((file) => {
          formData.append("documents[]", file as any);
        });

        return {
          url: `api/user/threads/${thread_id}/comments`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, params) => [
        { type: TAG_TYPE_FORUM_THREAD, id: params.thread_id },
        { type: TAG_TYPE_FORUM_COMMENT, id: params.thread_id },
      ],
    }),
    replyThreadComment: builder.mutation<
      ThreadComment,
      {
        thread_id: number;
        parent_id: number;
        comment: string;
        files?: Array<File>;
      }
    >({
      query: ({ thread_id, parent_id, comment, files }) => {
        let formData = new FormData();
        formData.append("comment", comment);
        formData.append("parent_id", parent_id.toString());
        files?.forEach((file) => {
          formData.append("documents[]", file as any);
        });

        return {
          url: `api/user/threads/${thread_id}/comments`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, params) => [
        { type: TAG_TYPE_FORUM_THREAD, id: params.thread_id },
        { type: TAG_TYPE_FORUM_COMMENT, id: params.thread_id },
      ],
    }),
    editThreadComment: builder.mutation<
      ThreadComment,
      {
        thread_id: number;
        comment_id: number;
        comment: string;
        files?: Array<File>;
      }
    >({
      query: ({ thread_id, comment_id, comment, files }) => {
        let formData = new FormData();
        formData.append("comment", comment);
        formData.append("_method", "put");
        files?.forEach((file) => {
          formData.append("documents[]", file as any);
        });

        return {
          url: `api/user/threads-comments/${comment_id}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, params) => [
        { type: TAG_TYPE_FORUM_THREAD, id: params.thread_id },
        { type: TAG_TYPE_FORUM_COMMENT, id: params.thread_id },
      ],
    }),
    deleteThreadComment: builder.mutation<
      ThreadComment,
      {
        thread_id: number;
        comment_id: number;
      }
    >({
      query: ({ thread_id, comment_id }) => {
        return {
          url: `api/user/threads-comments/${comment_id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, params) => [
        { type: TAG_TYPE_FORUM_THREAD, id: params.thread_id },
        { type: TAG_TYPE_FORUM_COMMENT, id: params.thread_id },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetForumThreadsQuery,
  useGetForumThreadByIdQuery,
  useCreateForumThreadMutation,
  useUpdateForumThreadMutation,
  useDeleteForumThreadMutation,
  useLikeForumThreadMutation,
  useGetForumThreadCommentsQuery,
  useSendThreadCommentMutation,
  useReplyThreadCommentMutation,
  useEditThreadCommentMutation,
  useDeleteThreadCommentMutation,
} = forumApi;
