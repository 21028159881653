import {Country} from '../types/country';
import {baseApi} from './base';
import {TAG_TYPE_COUNTRY} from './tags';

const apiWithTag = baseApi.enhanceEndpoints({addTagTypes: [TAG_TYPE_COUNTRY]});

export const countryApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getCountries: builder.query<
      Array<Country>,
      {sortField?: string; sortMode?: string | null; search?: string}
    >({
      query: ({sortField, sortMode, search}) => ({
        url: 'api/countries',
        method: 'GET',
        params: {
          sort:
            !!sortField && !!sortMode ? `${sortField}|${sortMode}` : `name|asc`,
          search,
        },
      }),
      providesTags: result => {
        const data = result;
        if (!data) return [{type: TAG_TYPE_COUNTRY, id: 'PARTIAL-LIST'}];
        return [
          {type: TAG_TYPE_COUNTRY, id: 'PARTIAL-LIST'},
          ...data?.map(({id}) => ({type: TAG_TYPE_COUNTRY, id})),
        ];
      },
    }),
  }),
  overrideExisting: false,
});

export const {useGetCountriesQuery} = countryApi;
