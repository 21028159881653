import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import { nl2br } from "../../utils/helper";
import { decode } from "html-entities";
import {
  AssignmentOutlined,
  ContactPhoneOutlined,
  DirectionsOutlined,
  EmailOutlined,
  ExpandMoreOutlined,
  FilePresentOutlined,
} from "@mui/icons-material";
import { Project, ProjectResource, ProjectStatus } from "../../types/project";
import MapPreview from "../../components/MapPreview";
import { useMemo } from "react";
import { formatWithTimezone } from "../../utils/dateHelper";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const StatusChip = ({ status }: { status?: string }) => {
  const style = { fontWeight: 500 };

  if (status == ProjectStatus.DRAFT)
    return <Chip label={"DRAFT"} sx={style} color="default" />;
  if (status == ProjectStatus.PUBLISHED)
    return <Chip label={"PUBLISHED"} sx={style} color="secondary" />;
  if (status == ProjectStatus.SCHEDULED)
    return <Chip label={"SCHEDULED"} sx={style} color="primary" />;
  if (status == ProjectStatus.IN_PROGRESS)
    return <Chip label={"IN PROGRESS"} sx={style} color="primary" />;
  if (status == ProjectStatus.COMPLETED)
    return <Chip label={"COMPLETED"} sx={style} color="info" />;
  if (status == ProjectStatus.CANCELED)
    return <Chip label={"CANCELLED"} sx={style} color="error" />;
  else return <Chip label={status} sx={style} color="default" />;
};

const WorkDetailView = ({
  project,
  job,
}: {
  project: Project;
  job?: ProjectResource;
}) => {
  const navigate = useNavigate();

  const [lng, lat] = useMemo(() => {
    if (!!project?.customer) {
      return project?.customer?.coordinate?.coordinates || [];
    } else if (!!project?.location) {
      return project?.location?.coordinate?.coordinates || [];
    } else return [];
  }, [project]);

  const startDate = useMemo(() => {
    const st = dayjs(job?.start_date || job?.project?.start_date).toDate();
    return st;
  }, [job]);

  return (
    <Grid container spacing={2}>
      {project?.status == ProjectStatus.CANCELED && (
        <Grid item xs={12}>
          <Alert severity="error" variant="standard">
            Project Cancelled. Reason: {project?.canceled_reason || "n/a"}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack direction="column" flex={1}>
          <Stack direction="row">
            <StatusChip status={project.status} />
            <Typography variant="h5" sx={{ ml: 1 }}>{`#${project?.id || ""} ${
              project?.title || ""
            }`}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Stack direction={"row"} alignItems={"center"} sx={{ mt: 1 }}>
              <Typography variant="body1" sx={{ mr: 1 }}>
                {project?.company?.name}
              </Typography>
              <Rating
                value={project?.company?.total_rating || 0}
                readOnly
                precision={0.5}
                size="small"
              />
              <Typography variant="body2">
                ({Number(project?.company?.total_rating || 0).toFixed(1)})
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
      {/* main content */}
      <Grid item lg={8} xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={!!job?.max_hour ? 4 : 6}>
            <Card>
              <CardHeader title="Schedule" />
              <CardContent>
                <Typography variant="body1">
                  {formatWithTimezone(startDate, job?.timezone, "dddd, MMMM D")}
                </Typography>
                <Typography variant="body2">
                  {formatWithTimezone(startDate, job?.timezone, "h:mmA z")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={!!job?.max_hour ? 4 : 6}>
            <Card>
              <CardHeader title="Contract Type" />
              <CardContent>
                <Typography variant="body2">
                  {project?.type == "hourly" ? "Hourly" : "Fixed Price"}
                </Typography>
                <Typography variant="body1" /*fontWeight={500}*/>
                  {project?.type == "hourly"
                    ? `$${job?.rate}/hr`
                    : `$${job?.price}`}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {!!job?.max_hour && (
            <Grid item xs={12} lg={4}>
              <Card>
                <CardHeader title="Max Hour" />
                <CardContent>
                <Typography variant={"body2"}>Hour</Typography>
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    {job?.max_hour + ' hrs' || 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>

        <Card sx={{ mt: 1 }}>
          <CardHeader title="Scope of Work / Instructions" />
          <CardContent>
            <Typography variant="body2">
              {nl2br(decode(project?.description))}
            </Typography>
          </CardContent>
          <Divider sx={{ mt: 1 }} />
          <CardContent>
            <Typography variant="body1" sx={{ fontSize: 18, mb: 1 }}>
              Confidential Information
            </Typography>
            {!!project?.confidential_information ? (
              <Typography variant="body2">
                {nl2br(project?.confidential_information)}
              </Typography>
            ) : (
              <Typography variant="body2" color="GrayText">
                n/a
              </Typography>
            )}
          </CardContent>
          <Divider sx={{ mt: 1 }} />
          <CardContent>
            <Typography variant="body1" sx={{ fontSize: 18, mb: 1 }}>
              Tasks
            </Typography>
            {project?.tasks?.length == 0 && (
              <Typography variant="body2" color={"GrayText"}>
                No tasks specified
              </Typography>
            )}
            {project?.tasks?.map((it, idx) => (
              <Accordion key={idx}>
                <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                  <AssignmentOutlined sx={{ mr: 1 }} />
                  <Typography>{it.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    {decode(it.description) || "n/a"}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Work Documents" />
          <CardContent>
            {project?.documents?.length == 0 && (
              <Typography variant="body2" color={"GrayText"}>
                No documents specified
              </Typography>
            )}
            <List disablePadding={true}>
              {project?.documents?.map((doc) => (
                <ListItem key={doc.id} disablePadding={true}>
                  <ListItemIcon>
                    <FilePresentOutlined />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${doc.name || "n/a"}`}
                    primaryTypographyProps={{
                      fontWeight: "500",
                      fontSize: 14,
                    }}
                    secondary={
                      <Link href={doc.original_url} target="_blank">
                        View
                      </Link>
                    }
                    sx={{ flex: 1 }}
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
        {/* <Card sx={{ mt: 1 }}>
          <CardHeader title="Tasks" />
          <CardContent>
            {project?.tasks?.length == 0 && (
              <Typography variant="caption">No tasks specified</Typography>
            )}
            {project?.tasks?.map((it, idx) => (
              <Accordion key={idx}>
                <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                  <AssignmentOutlined sx={{ mr: 2 }} />
                  <Typography>{it.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="caption">
                    {decode(it.description) || "n/a"}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </CardContent>
        </Card> */}
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Requirements" />
          <CardContent>
            <Typography variant="body2" fontWeight={500}>
              Skills
            </Typography>
            <Box sx={{ mt: 1 }}>
              {project?.skills?.length == 0 ? (
                <Typography sx={{ fontSize: 12, color: "text.disabled" }}>
                  Not Required
                </Typography>
              ) : (
                project?.skills?.map((it) => (
                  <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                ))
              )}
            </Box>
            <Typography sx={{ mt: 2 }} variant="body2" fontWeight={500}>
              Certifications
            </Typography>
            <Box sx={{ mt: 1 }}>
              {project?.certifications?.length == 0 ? (
                <Typography sx={{ fontSize: 12, color: "text.disabled" }}>
                  Not Required
                </Typography>
              ) : (
                project?.certifications?.map((it) => (
                  <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                ))
              )}
            </Box>
          </CardContent>
          <Divider />
          <CardContent>
            <Typography variant="body1">
              Photos of Completed Work →{" "}
              {!!project?.is_photo_required ? "Required" : "Not Required"}
              <br />
              Final Notes of Completed Work →{" "}
              {!!project?.is_final_notes_required ? "Required" : "Not Required"}
              <br />
              Background Check →{" "}
              {!!project?.background_check_required
                ? "Required"
                : "Not Required"}
              <br />
              Drug Test →{" "}
              {!!project?.drug_test_required ? "Required" : "Not Required"}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={4} xs={12}>
        <Card>
          <CardHeader title={"Directions to Work Site"} />
          <CardContent sx={{ p: 0 }}>
            <MapPreview lat={lat} lng={lng} />
          </CardContent>
          <CardContent>
            {!!project?.location && (
              <Box fontSize={14}>
                <Typography variant="body1" fontWeight={500} fontSize={18}>
                  {project?.location?.name}
                </Typography>
                <Typography variant="body2">
                  {project?.location.address_1}, {project?.location.city},{" "}
                  {project?.location.state} {project?.location.zip}
                </Typography>
                <Button
                  fullWidth
                  variant="outlined"
                  startIcon={<DirectionsOutlined />}
                  sx={{ mt: 2 }}
                  onClick={() =>
                    window.open(
                      "https://www.google.com/maps/dir//" + lat + "%2C" + lng,
                      "_blank"
                    )
                  }
                >
                  Get Map Directions
                </Button>
                <Typography
                  variant="body1"
                  sx={{ mt: 2, mb: 1 }}
                  fontWeight={500}
                >
                  Contact
                </Typography>
                <Stack direction={"row"} alignItems="center">
                  <ContactPhoneOutlined
                    fontSize={"small"}
                    color={"disabled"}
                    sx={{ mr: 2 }}
                  />
                  {project?.location?.primary_contact_name} -{" "}
                  {project?.location?.primary_contact_phone_number}
                </Stack>
              </Box>
            )}
            {!!project?.customer && (
              <Box fontSize={14}>
                <Typography variant="body1" fontWeight={500}>
                  {project?.customer?.first_name}
                </Typography>
                {project?.customer.physical_address_1}, {project?.customer.city}
                , {project?.customer.state} {project?.customer.zip}
                <br />
                <Stack direction={"row"} alignItems="center" sx={{ mt: 1 }}>
                  <ContactPhoneOutlined
                    fontSize={"small"}
                    color={"disabled"}
                    sx={{ mr: 2 }}
                  />
                  {project?.customer?.phone_number}
                </Stack>
                <Stack direction={"row"} alignItems="center">
                  <EmailOutlined
                    fontSize={"small"}
                    color={"disabled"}
                    sx={{ mr: 2 }}
                  />
                  {project?.customer?.email}
                </Stack>
              </Box>
            )}
          </CardContent>
        </Card>
        <>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Work Manager" />
            <CardContent>
              <Typography variant="body2">
                <Box fontSize={14}>
                  <Typography variant="body1" fontWeight={500}>
                    {project?.manager?.first_name} {project?.manager?.last_name}
                  </Typography>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: 1 }}>
                    <ContactPhoneOutlined
                      fontSize={"small"}
                      color={"disabled"}
                      sx={{ mr: 2 }}
                    />
                    {project?.manager?.phone_number}
                  </Stack>
                  <Stack direction={"row"} alignItems="center">
                    <EmailOutlined
                      fontSize={"small"}
                      color={"disabled"}
                      sx={{ mr: 2 }}
                    />
                    {project?.manager?.email}
                  </Stack>
                </Box>
              </Typography>
            </CardContent>
          </Card>
        </>
      </Grid>
    </Grid>
  );
};
export default WorkDetailView;
