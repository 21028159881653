import {
  NegotiationStatus,
  ProjectResource,
  ReportProgress,
} from "../types/project";
import { baseApi } from "./base";
import { TAG_TYPE_JOB } from "./tags";

const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [TAG_TYPE_JOB] });

export const myJobApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getMyJobs: builder.query<
      Array<ProjectResource>,
      {
        sortField?: string;
        sortMode?: string | null;
        search?: string;
        statuses?: string;
        load?: string;
        projects?: string;
      }
    >({
      query: ({ sortField, sortMode, search, statuses, load, projects }) => ({
        url: "api/user/my-jobs",
        method: "GET",
        params: {
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `created_at|asc`,
          search,
          load: load || "project,project.company,reportProgresses",
          statuses,
          projects,
        },
      }),
      providesTags: (result) => {
        const data = result;
        if (!data) return [{ type: TAG_TYPE_JOB, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE_JOB, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE_JOB, id })),
        ];
      },
    }),
    getMyJobById: builder.query<ProjectResource, number>({
      query: (id) => ({
        url: `api/user/my-jobs/${id}`,
        method: "GET",
        params: {
          load: "project,reportProgresses,reportProgresses.documents",
        },
      }),
      providesTags: (result, error, id) => {
        return [{ type: TAG_TYPE_JOB, id: id }];
      },
    }),
    checkIn: builder.mutation<
      ReportProgress,
      { project_resource: number; lat: number; lng: number }
    >({
      query: ({ project_resource, lat, lng }) => {
        return {
          url: `api/user/report-progresses-check-in`,
          method: "POST",
          params: {
            project_resource,
            lat,
            lng,
          },
        };
      },
      invalidatesTags: (result, error, payload) => [
        { type: TAG_TYPE_JOB, id: payload.project_resource },
        // {type: TAG_TYPE_JOB},
      ],
    }),
    checkOut: builder.mutation<
      ReportProgress,
      {
        project_resource: number;
        report_progress: number;
        lat: number;
        lng: number;
      }
    >({
      query: ({ report_progress, lat, lng }) => {
        return {
          url: `api/user/report-progresses-check-out`,
          method: "POST",
          params: {
            report_progress,
            lat,
            lng,
          },
        };
      },
      invalidatesTags: (result, error, payload) => [
        { type: TAG_TYPE_JOB, id: payload.project_resource },
        // {type: TAG_TYPE_JOB},
      ],
    }),
    submitResult: builder.mutation<
      ReportProgress,
      {
        project_resource: number;
        report_progress: number;
        files?: Array<File>;
        final_note?: string;
      }
    >({
      query: ({ report_progress, files, final_note }) => {
        let formData = new FormData();
        return {
          url: `api/user/report-progresses-submitted`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, payload) => [
        { type: TAG_TYPE_JOB, id: payload.project_resource },
        // {type: TAG_TYPE_JOB},
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMyJobsQuery,
  useGetMyJobByIdQuery,
  useCheckInMutation,
  useCheckOutMutation,
  useSubmitResultMutation,
} = myJobApi;
