import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_API_URL } from "../configs/api";
import { RootState } from "../app/store";

export interface QueryResponse<T> {
  data: Array<T>;
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    per_page: number;
    to: number;
    total: number;
  };
}

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = (getState() as RootState).auth.token?.accessToken;
      if (accessToken) {
        headers.set("authorization", `Bearer ${accessToken}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  endpoints: () => ({}),
  refetchOnMountOrArgChange: 30, // refetch after 30s
  refetchOnReconnect: false,
  refetchOnFocus: false,
});
