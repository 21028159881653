import {Skill, SkillCategory} from '../types/skill';
import {baseApi} from './base';
import {TAG_TYPE_SKILL} from './tags';

const apiWithTag = baseApi.enhanceEndpoints({addTagTypes: [TAG_TYPE_SKILL]});

export const skillApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getSkills: builder.query<Array<Skill & {category: SkillCategory}>, void>({
      query: () => ({
        url: 'api/skills',
        method: 'GET',
        params: {
          sort: `name|asc`,
          load: 'category',
        },
      }),
      providesTags: result => {
        const data = result;
        if (!data) return [{type: TAG_TYPE_SKILL, id: 'PARTIAL-LIST'}];
        return [
          {type: TAG_TYPE_SKILL, id: 'PARTIAL-LIST'},
          ...data?.map(({id}) => ({type: TAG_TYPE_SKILL, id})),
        ];
      },
    }),
  }),
  overrideExisting: false,
});

export const {useGetSkillsQuery} = skillApi;
