import {Certification} from '../types/certification';
import {baseApi} from './base';
import {TAG_TYPE_CERTIFICATION} from './tags';

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_CERTIFICATION],
});

export const certApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getCertifications: builder.query<
      Array<Certification>,
      {sortField?: string; sortMode?: string | null; search?: string}
    >({
      query: ({sortField, sortMode, search}) => ({
        url: 'api/certifications',
        method: 'GET',
        params: {
          sort:
            !!sortField && !!sortMode ? `${sortField}|${sortMode}` : `name|asc`,
          search,
        },
      }),
      providesTags: result => {
        const data = result;
        if (!data) return [{type: TAG_TYPE_CERTIFICATION, id: 'PARTIAL-LIST'}];
        return [
          {type: TAG_TYPE_CERTIFICATION, id: 'PARTIAL-LIST'},
          ...data?.map(({id}) => ({type: TAG_TYPE_CERTIFICATION, id})),
        ];
      },
    }),
  }),
  overrideExisting: false,
});

export const {useGetCertificationsQuery} = certApi;
