import { store, persistor } from "./app/store";
import { Provider } from "react-redux";
import AppRoutes from "./Routes";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";

import "react-toastify/dist/ReactToastify.css";
import { CssBaseline } from "@mui/material";
// import shadows, { Shadows } from "@mui/material/styles/shadows";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./App.css";

let menuColor = "#17903d";

const theme = createTheme({
  // shadows: shadows.map(() => "none") as Shadows,
  palette: {
    primary: {
      main: "#17903d",
    },
    secondary: {
      main: "#F4A259",
    },
  },
  // spacing: 4,
  components: {
    MuiButton: {
      defaultProps: {
        // size: "small",
      },
    },
    MuiFilledInput: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: "none",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: "dense",
        sx: {
          backgroundColor: "white", //fix outline label overlap
        },
      },
    },
    MuiListItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiListItemButton: {
      defaultProps: {},
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: menuColor + "66",
          },
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: "normal",
        size: "small",
        variant: "outlined",
      },
    },
    MuiToolbar: {
      defaultProps: {
        variant: "dense",
      },
    },
    MuiMenuItem: {
      defaultProps: {
        // divider: true,
      },
    },
    MuiCard: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { fontSize: 18 },
        sx: {
          py: 1,
          borderBottom: 1,
          borderBottomColor: "#eee",
        },
      },
    },
    MuiCardContent: {
      defaultProps: {},
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CssBaseline />
            <AppRoutes />
          </LocalizationProvider>
        </ThemeProvider>
      </PersistGate>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
        theme="colored"
      />
    </Provider>
  );
}
export default App;
