import {Certification, UserCertification} from './certification';
import {Coordinate} from './common';
import {Company} from './company';
import {Country} from './country';
import {UserEducation} from './education';
import {Language} from './language';
import {Photo} from './photo';
import {Skill} from './skill';
import {BusinessVertical} from './vertical';
import {UserWorkExperience} from './workExperience';

export enum UserStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  IN_ACTIVE = 'in-active',
}

export enum UserWorkStatus {
  PART_TIME = 'part-time',
  SOLE_PROPRIETOR = 'sole-proprietor',
  ENTREPRENEUR = 'entrepreneur',
}

export interface AdditionalUserInfo {
  avail_monday: boolean;
  avail_tuesday: boolean;
  avail_wednesday: boolean;
  avail_thursday: boolean;
  avail_friday: boolean;
  avail_saturday: boolean;
  avail_sunday: boolean;
  is_valid_us: boolean;
  work_status: UserWorkStatus;
}

export interface User {
  id: number;
  role_id: number;
  first_name: string | null;
  last_name: string | null;
  full_name: string | null;
  phone_number: string | null;
  email: string | null;
  email_verified_at: Date | null;
  status: UserStatus;
  // security_questions: string | null;
  // security_answer: string | null;
  instagram: string | null;
  facebook: string | null;
  linkedin: string | null;
  website: string | null;
  //
  country_id: number;
  country?: Country;
  zip: string | null;
  state: string | null;
  city: string | null;
  physical_address_1: string | null;
  physical_address_2: string | null;

  device_token: string | null;

  photo: Array<Photo>;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

export interface Talent extends User {
  bio: string | null;
  min_pay: number;
  max_pay: number;
  commute_radius: number;
  total_rating: number;
  balance_decrypt: number;
  // covid_vaccinated: boolean | null;
  allow_background_check: boolean | null;
  allow_drug_test: boolean | null;
  is_valid_us: boolean | null;

  skills: Array<Skill>;
  languages: Array<Language>;
  certifications: Array<UserCertification>;
  business_verticals: Array<BusinessVertical>;
  //business_verticals
  educations: Array<UserEducation>;
  work_experiences: Array<UserWorkExperience>;
  coordinate: Coordinate;

  talent: AdditionalUserInfo;
}

export interface Manager extends User {
  company: Company;
}
