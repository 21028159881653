import {baseApi} from './base';
import {UserFeedback} from '../types/feedback';

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [],
});

export const feedbackApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    createFeedback: builder.mutation<UserFeedback, UserFeedback>({
      query: payload => {
        return {
          url: `api/user/incidents`,
          method: 'POST',
          body: payload,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {useCreateFeedbackMutation} = feedbackApi;
