import React, { Fragment } from "react";
import { Talent } from "../types/users";

var jsxRuntime = require("react/jsx-runtime");
// import jsxRuntime from "react/jsx-runtime";
const _jsx = jsxRuntime._jsx;
const newlineRegex = /(\r\n|\r|\n)/g;

export function isProductionMode() {
  return process.env.REACT_APP_MODE == "production";
}

export function isManage360() {
  return process.env.REACT_APP_IS_MANAGE360 == "true";
}

export const isFile = (input: any) => "File" in window && input instanceof File;

export function nl2br(str?: string | null) {
  if (!str) return "";
  // if is no newline char in the string
  if (!str.match("\n")) {
    return str;
  }

  const fragments = str.split("\n");
  const fragmentsCount = fragments.length;

  return fragments.reduce((res, line, i) => {
    if (line !== "") {
      //@ts-ignore
      res.push(React.createElement(React.Fragment, { key: i, children: line }));
    }

    // in the last fragment not display <br>
    if (i + 1 !== fragmentsCount) {
      //@ts-ignore
      res.push(React.createElement("br", { key: `${i}br` }));
    }

    return res;
  }, []);
}

export const formatCash = (n: number) => {
  if (n < 1e3) return +n.toFixed(1);
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export const formatMoney = (n: number): string => {
  if (!n) return "";
  const fixedNum = +Number(n).toFixed(2);
  return `${fixedNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

export function ucFirstAllWords(str: string): string {
  var pieces = str.split(" ");
  for (var i = 0; i < pieces.length; i++) {
    var j = pieces[i].charAt(0).toUpperCase();
    pieces[i] = j + pieces[i].substr(1);
  }
  return pieces.join(" ");
}

export function getPrivateCompanyName(lastName: string | null | undefined) {
  if (lastName?.endsWith("]")) {
    const privateCompanyName = lastName.split("[")[1].replace("]", "");
    return privateCompanyName;
  }
  return "";
}
export function getOriginalLastName(lastName: string | null | undefined) {
  if (lastName?.endsWith("]")) {
    const originalLastName = lastName.split("[")[0];
    return originalLastName;
  }
  return lastName;
}

export function currencyFormat(value: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(value);
}
