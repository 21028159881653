import {Notification} from '../types/notification';
import {baseApi, QueryResponse} from './base';
import {TAG_TYPE_NOTIFICATION} from './tags';

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_NOTIFICATION],
});

export const notificationApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getNotifications: builder.query<QueryResponse<Notification>, void>({
      query: () => {
        return {
          url: `api/user/notifications`,
          method: 'GET',
          params: {
            page: 1,
            per_page: 100,
            sort: 'created_at|desc',
          },
        };
      },
      providesTags: result => {
        if (!result?.data)
          return [{type: TAG_TYPE_NOTIFICATION, id: 'PARTIAL-LIST'}];
        return [
          {type: TAG_TYPE_NOTIFICATION, id: 'PARTIAL-LIST'},
          ...result.data?.map(({id}) => ({type: TAG_TYPE_NOTIFICATION, id})),
        ];
      },
    }),
    readNotification: builder.mutation<void, number>({
      query: id => {
        return {
          url: `api/user/notifications/${id}`,
          method: 'PUT',
        };
      },
      invalidatesTags: (result, error, id) => [
        {type: TAG_TYPE_NOTIFICATION, id: id},
      ],
    }),
    readAllNotifications: builder.mutation<void, void>({
      query: () => {
        return {
          url: `api/user/notifications`,
          method: 'PUT',
        };
      },
      invalidatesTags: () => [{type: TAG_TYPE_NOTIFICATION}],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetNotificationsQuery,
  useReadNotificationMutation,
  useReadAllNotificationsMutation,
} = notificationApi;
