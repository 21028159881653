import * as React from "react";
import {
  Chip,
  Link,
  Rating,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { currencyFormat } from "../../utils/helper";
import {
  ProjectResource,
  ProjectStatus,
  ReportProgressStatus,
} from "../../types/project";
import { formatWithTimezone, toDuration } from "../../utils/dateHelper";
import { useJobMeta } from "../../hooks/useJobMeta";

const TableRowActive = ({ job }: { job: ProjectResource }) => {
  const navigate = useNavigate();
  const date = React.useMemo(() => {
    const st = dayjs(job?.start_date || job?.project?.start_date).toDate();
    return st;
  }, [job]);

  const [timer, setTimer] = React.useState(toDuration(0));
  const meta = useJobMeta(job);
  const isHourly = React.useMemo(() => job.project?.type == "hourly", [job]);

  React.useEffect(() => {
    if (
      job.project?.status != ProjectStatus.SCHEDULED &&
      job.project?.status != ProjectStatus.IN_PROGRESS
    )
      return;

    if (meta.isCheckedIn) {
      const currentMs =
        new Date().getTime() -
        new Date(meta.currentSession?.check_in || 0).getTime();
      setTimer(toDuration(meta.totalSec + currentMs / 1000));
    } else if (meta.isCheckedOut) {
      setTimer(toDuration(meta.totalSec));
    }
  }, [job, meta]);

  return (
    <TableRow>
      <TableCell>
        <Link
          component="button"
          variant="body1"
          onClick={() => navigate(`/works/${job.project_id}`)}
        >
          #{job.project_id} - {job.project?.title}
        </Link>
        <br />
        <Stack direction={"row"} alignItems={"center"}>
          <Typography variant="body2" sx={{ mr: 1 }}>
            {job.project?.company?.name}
          </Typography>
          <Rating
            value={job.project?.company?.total_rating || 0}
            readOnly
            precision={0.5}
            size="small"
          />
        </Stack>
      </TableCell>
      <TableCell>
        {formatWithTimezone(date, job?.timezone || "", "ddd, MMM DD YYYY")}
        <br />
        <Typography variant="caption">
          {formatWithTimezone(date, job?.timezone || "", "hh:mm A z")}
        </Typography>
      </TableCell>
      <TableCell align="right">
        {isHourly
          ? `${currencyFormat(job?.rate)} / hr`
          : currencyFormat(job?.price)}
      </TableCell>
      <TableCell align="right">{timer}</TableCell>
      <TableCell>
        {!!meta.currentSession?.status ? (
          <>
            {meta.currentSession?.status == ReportProgressStatus.SUBMITTED && (
              <Chip label="Waiting Approval" size="small" color="info" />
            )}
            {meta.currentSession?.status == ReportProgressStatus.APPROVED && (
              <Chip
                label="Work Approved by Customer"
                size="small"
                color="success"
              />
            )}
            {meta.currentSession?.status == ReportProgressStatus.REJECTED && (
              <Chip label="Work Incompleted" size="small" color="error" />
            )}
          </>
        ) : job?.project?.status == ProjectStatus.SCHEDULED ? (
          <Typography variant="body2" color={"InactiveCaptionText"}>
            Not-Started
          </Typography>
        ) : meta.isCheckedIn ? (
          <Chip label="Checked In" size="small" color="warning" />
        ) : meta.isCheckedOut ? (
          <Chip label="Checked Out" size="small" />
        ) : (
          "?"
        )}
      </TableCell>
    </TableRow>
  );
};

export default TableRowActive;
