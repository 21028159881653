import * as React from "react";
import { useGetInboxRoomsQuery } from "../../services/inbox.service";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { AddCommentOutlined, RefreshOutlined } from "@mui/icons-material";
import { InboxRoom } from "../../types/inbox";
import dayjs from "dayjs";
import { timeSince } from "../../utils/dateHelper";
import { useNavigate } from "react-router-dom";
import ModalSearchTalent from "./ModalSearchTalent";
import ModalTalentProfile from "./ModalTalentProfile";

function stringAvatar(name: string) {
  return {
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const InboxItem = ({ item }: { item: InboxRoom }) => {
  const navigate = useNavigate();
  const user = useCurrentUser();
  const [modalOpen, setModalOpen] = React.useState(false);
  const otherId = item.users?.find((it) => it != user?.id) || 0;
  const other = item.user_info[otherId];
  const isUnread = dayjs(item.last_read[user?.id!]?.toDate()).isBefore(
    dayjs(item.last_message?.timestamp?.toDate())
  );

  return (
    <>
      <ListItem
        secondaryAction={
          <Typography variant="caption" sx={{ mt: 1 }}>
            {timeSince(item.last_message?.timestamp?.toDate())}
          </Typography>
        }
      >
        <ListItemIcon>
          <IconButton onClick={() => setModalOpen(true)}>
            {!!other?.photo_url ? (
              <Avatar src={other?.photo_url} />
            ) : (
              <Avatar {...stringAvatar(user?.full_name || "?")} />
            )}
          </IconButton>
        </ListItemIcon>
        <ListItemText
          primary={
            <Link
              component="button"
              onClick={() =>
                navigate(
                  `/private-messages/${otherId}?isUnread=${isUnread ? 1 : 0}`
                )
              }
            >
              {other.first_name} {other.last_name}
            </Link>
          }
          primaryTypographyProps={{
            fontSize: 16,
            fontWeight: isUnread ? "600" : undefined,
          }}
          secondary={
            <Box>
              <Typography variant="body2">{`${
                item.last_message?.sender_id == user?.id ? "You: " : ""
              }${item.last_message?.message}`}</Typography>
            </Box>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ModalTalentProfile
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        id={otherId || 0}
      />
    </>
  );
};

const InboxPage = () => {
  const [modalSearchOpen, setModalSearchOpen] = React.useState(false);

  const user = useCurrentUser();
  const {
    data: inboxes,
    isFetching,
    refetch,
  } = useGetInboxRoomsQuery(
    {
      userId: user?.id!,
    },
    {
      skip: !user,
    }
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        <Stack direction={"row"} sx={{ mb: 1 }} justifyContent={"flex-end"}>
          <Button
            variant="outlined"
            startIcon={<AddCommentOutlined />}
            onClick={() => setModalSearchOpen(true)}
            sx={{ mr: 1 }}
          >
            New Chat
          </Button>
          <Button startIcon={<RefreshOutlined />} onClick={refetch}>
            Refresh
          </Button>
        </Stack>
        {isFetching && <LinearProgress />}
        <Paper sx={{ p: 2 }}>
          <List dense={true} disablePadding>
            {inboxes?.map((inbox) => (
              <InboxItem item={inbox} />
            ))}
          </List>
        </Paper>
        <ModalSearchTalent
          open={modalSearchOpen}
          onClose={() => setModalSearchOpen(false)}
        />
      </Grid>
    </Grid>
  );
};
export default InboxPage;
