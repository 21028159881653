import * as React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import {
  getRoomId,
  useGetInboxRoomMessagesQuery,
  useMarkRoomAsReadMutation,
  useSendInboxMessageMutation,
  useUploadInboxFileMutation,
} from "../../services/inbox.service";
import { useGetTalentByIdQuery } from "../../services/talent.service";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { RefreshOutlined } from "@mui/icons-material";
import {
  Avatar,
  ChatContainer,
  ConversationHeader,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
  MessageSeparator,
} from "@chatscope/chat-ui-kit-react";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useFilePicker } from "use-file-picker";
import { useTitle } from "../../hooks/useTitle";
import { InboxFile } from "../../types/inbox";
import ClickableAvatar from "../../components/ClickableAvatar";

const InboxChatPage = () => {
  const { targetId } = useParams();
  const [params] = useSearchParams();
  const isUnread = params.get("isUnread");

  const navigate = useNavigate();
  const user = useCurrentUser();
  const roomId = getRoomId(user?.id!, parseInt(targetId || "0"));

  const { data: target, isFetching: fetchingTarget } = useGetTalentByIdQuery({
    id: parseInt(targetId || "0"),
  });
  const {
    data: roomMessages,
    refetch,
    isFetching,
    isLoading,
  } = useGetInboxRoomMessagesQuery({
    roomId,
  });
  const [doSend, { isLoading: sending }] = useSendInboxMessageMutation();
  const [doUpload, { isLoading: uploading }] = useUploadInboxFileMutation();
  const [doReadRoom] = useMarkRoomAsReadMutation();

  useTitle(target?.full_name || "");

  const [openFileSelector, { plainFiles, loading, errors, clear }] =
    useFilePicker({
      multiple: false,
      accept: [
        ".doc",
        ".docx",
        ".pdf",
        ".txt",
        ".jpeg",
        ".jpg",
        ".png",
        ".gif",
        ".pptx",
        ".zip",
        ".xls",
        "xlsx",
        ".csv",
      ],
      maxFileSize: 10,
      readFilesContent: false,
    });

  const _send = async (text: string, file?: File) => {
    try {
      if (text?.length == 0 || !target || !user) return;

      let inboxFile: InboxFile | undefined = undefined;
      if (!!file) {
        const uploaded = await doUpload({ roomId, file });
        //@ts-ignore
        inboxFile = uploaded.data;
      }

      await doSend({
        message: text,
        sender: user,
        target,
        file: inboxFile,
      }).unwrap();
      clear();
    } catch (err: any) {
      toast.error(err.message);
      clear();
    }
  };
  // @ts-ignore
  const handleOnSend = (innerHtml, text, innerText: any) => {
    if (innerText?.length == 0) return;
    _send(innerText);
  };

  // on file selected
  React.useEffect(() => {
    if (plainFiles?.length > 0) {
      const file = plainFiles[0];
      _send(file.name, file);
    }
  }, [plainFiles]);

  React.useEffect(() => {
    errors?.[0]?.fileSizeTooSmall && toast.error("File size is too small!");
    errors?.[0]?.fileSizeToolarge && toast.error("File size is too large!");
    errors?.[0]?.readerError &&
      toast.error("Problem occured while reading file!");
    errors?.[0]?.maxLimitExceeded && toast.error("Too many files");
    errors?.[0]?.minLimitNotReached && toast.error("Not enought files");
  }, [errors]);

  React.useEffect(() => {
    if (!roomId || !user) return;

    if (!!isUnread) doReadRoom({ roomId, reader: user });
  }, [roomId, user, isUnread]);

  const messages = React.useMemo(() => {
    let lastDate = "";
    return roomMessages
      ?.slice(0)
      .reverse()
      .map((it) => {
        let curDate = dayjs(it.timestamp?.toDate()).format("MMM DD, YYYY");
        let isNewDay = curDate != lastDate;
        if (isNewDay) lastDate = curDate;

        const isImage = it.file?.mime?.startsWith("image") || false;
        const isFile = !!it.file?.mime && !isImage; //anything but image

        return (
          <>
            {isNewDay && <MessageSeparator>{curDate}</MessageSeparator>}
            <Message
              key={it.id}
              model={{
                direction: user?.id == it.sender_id ? "outgoing" : "incoming",
                position: "single",
              }}
            >
              {isImage ? (
                <Message.HtmlContent
                  html={`<a href='${it.file.url}' target='_blank'><img src='${it.file.url}' width='250'/></a>`}
                />
              ) : isFile ? (
                <Message.HtmlContent
                  html={`${it.message}<br/><a href='${it.file.url}' target='_blank'>⬇️ Open File</a>`}
                />
              ) : (
                <Message.HtmlContent html={it.message} />
              )}
              <Message.Footer
                // sender={target?.full_name || "?"}
                sentTime={dayjs(it.timestamp.toDate()).format("hh:mm A")}
              />
            </Message>
          </>
        );
      });
  }, [roomMessages, user, target]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        {(isFetching || sending || uploading) && <LinearProgress />}

        {!fetchingTarget && !target && (
          <Alert severity="error" sx={{ mb: 2 }}>
            <AlertTitle>Chat Disabled</AlertTitle>User Id #{targetId} not found
          </Alert>
        )}
        <div
          style={{ position: "relative", height: "80vh", overflow: "hidden" }}
        >
          <MainContainer style={{ borderWidth: 1, borderRadius: 5 }}>
            <ChatContainer>
              <ConversationHeader>
                <ConversationHeader.Back
                  onClick={() => navigate("/private-messages")}
                />
                <ConversationHeader.Content>
                  <Stack direction={"row"} alignItems={"center"}>
                    <ClickableAvatar talent={target} />
                    <Stack sx={{ ml: 1 }}>
                      <Typography variant="body1">
                        {target?.full_name || "?"}
                      </Typography>
                      <Typography variant="caption">{`${target?.city || "?"}, ${
                        target?.state || "?"
                      }`}</Typography>
                    </Stack>
                  </Stack>
                </ConversationHeader.Content>
                {/* <ConversationHeader.Content
                  info={`${target?.city || "?"}, ${target?.state || "?"}`}
                  userName={target?.full_name || "?"}
                /> */}
                <ConversationHeader.Actions>
                  <Button startIcon={<RefreshOutlined />} onClick={refetch}>
                    Refresh
                  </Button>
                </ConversationHeader.Actions>
              </ConversationHeader>
              <MessageList loading={isLoading}>{messages}</MessageList>
              <MessageInput
                placeholder="Message..."
                attachButton={true}
                onAttachClick={() => openFileSelector()}
                onSend={handleOnSend}
                disabled={sending || uploading || !target}
              />
            </ChatContainer>
          </MainContainer>
        </div>
      </Grid>
    </Grid>
  );
};
export default InboxChatPage;
