// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, initializeFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDQjsB3Jl0HIvXuTCFStclnE9prVQsf0WE",
  authDomain: "the-method-329619.firebaseapp.com",
  projectId: "the-method-329619",
  storageBucket: "the-method-329619.appspot.com",
  messagingSenderId: "593192977060",
  appId: "1:593192977060:web:ec3e816dd02da720541c31",
  measurementId: "G-7YVPG7G3PJ",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const firestore = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});
export const storage = getStorage(app);
