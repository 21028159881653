import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTitle } from "../../hooks/useTitle";
import dayjs from "dayjs";
import {
  HistoryOutlined,
  NextWeekOutlined,
  RefreshOutlined,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import { useGetMyJobsQuery } from "../../services/my-job.service";
import { NegotiationStatus, ProjectStatus } from "../../types/project";
import TableRowActive from "./TableRowActive";
import { NavLink } from "react-router-dom";

const ActiveWorksPage = () => {
  useTitle("My Work");

  const { data, refetch, isFetching } = useGetMyJobsQuery({
    statuses: "hired",
  });

  const { scheduledJobs, inprogressJobs } = React.useMemo(() => {
    const active = data
      ?.filter(
        (it) =>
          it.status == NegotiationStatus.HIRED &&
          [ProjectStatus.SCHEDULED, ProjectStatus.IN_PROGRESS].includes(
            it.project?.status!
          )
      )
      .slice()
      .sort((a, b) =>
        dayjs(a?.start_date || a?.project?.start_date || 0).isAfter(
          dayjs(b?.start_date || b?.project?.start_date || 0)
        )
          ? -1
          : 1
      );

    const scheduledJobs = active?.filter(
      (it) => it.project?.status == ProjectStatus.SCHEDULED
    );
    const inprogressJobs = active?.filter(
      (it) => it.project?.status == ProjectStatus.IN_PROGRESS
    );

    return {
      scheduledJobs,
      inprogressJobs,
    };
  }, [data]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack alignItems={"flex-end"}>
            <Button startIcon={<RefreshOutlined />} onClick={refetch}>
              Refresh
            </Button>
          </Stack>
          {isFetching && <LinearProgress />}
          <Card>
            <CardHeader
              title={`Schedule Work (${scheduledJobs?.length || 0})`}
              avatar={<NextWeekOutlined />}
            />
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Work ID/Title</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell align="right">Rate</TableCell>
                    <TableCell align="right">Total Hour</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {scheduledJobs?.map((job) => (
                    <TableRowActive job={job} key={job.id} />
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Card sx={{ mt: 2 }}>
            <CardHeader
              title={`In Progress (${inprogressJobs?.length || 0})`}
              avatar={<NextWeekOutlined />}
            />
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Work ID/Title</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell align="right">Rate</TableCell>
                    <TableCell align="right">Total Hour</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inprogressJobs?.map((job) => (
                    <TableRowActive job={job} key={job.id} />
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Link to="/works/completed" underline="hover" component={NavLink}>
            <Button
              variant="outlined"
              startIcon={<WorkOutlineOutlined />}
              sx={{ mt: 2 }}
            >
              Completed Work
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ActiveWorksPage;
