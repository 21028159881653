import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import { useLoginMutation } from "../../services/auth.service";
import { Navigate, useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import workz360 from "../../images/workz360.png";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { Alert } from "@mui/material";
import { resetError } from "./authSlice";
import { useAppDispatch } from "../../hooks/store";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://workz360.com/">
        Workz360
      </Link>{" "}
      2022 - {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function LoginPage() {
  const [login, { isLoading }] = useLoginMutation();
  const user = useCurrentUser();
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const error = useSelector((state: RootState) => state.auth.error);
  const bgImage = React.useMemo(() => {
    return "https://images.unsplash.com/photo-1620519185537-4e18c939713f?q=80&w=800&auto=format&fit=crop";
  }, []);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm();

  // @ts-ignore
  const onSubmit = async (data: any) => {
    const { email, password } = data;
    dispatch(resetError());
    await login({ username: email, password });
  };

  if (!!user) return <Navigate to="/home" replace={true} />;

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(" + bgImage + ")",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={workz360} height={56} />
          <Typography component="h1" variant="h6">
            Independent Professional Portal
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 2 }}
          >
            <Controller
              name="email"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  id="email"
                  size="medium"
                  label="Email Address"
                  autoComplete="email"
                  autoFocus
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.email}
                  helperText={!!errors.email && "This field is required"}
                />
              )}
            />
            <Controller
              name="password"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="Password"
                  type="password"
                  id="password"
                  size="medium"
                  autoComplete="current-password"
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.password}
                  helperText={!!errors.password && "This field is required"}
                />
              )}
            />
            {!!error && <Alert severity="error">{error}</Alert>}
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              loading={isLoading}
            >
              Sign In
            </LoadingButton>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
