import * as React from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Autocomplete, Checkbox, TextField } from "@mui/material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const availableTags = [
  {
    id: "general",
    name: "General",
  },
  {
    id: "question",
    name: "Question",
  },
  {
    id: "sharing",
    name: "Sharing",
  },
];

const SelectFeedTags = ({
  onChange,
  values,
}: {
  onChange: (ids?: string[]) => void;
  values: string[];
}) => {
  const initialValues = React.useMemo(
    () => availableTags?.filter((it) => values?.includes(it.id)),
    [availableTags, values]
  );

  return (
    <Autocomplete
      multiple
      fullWidth
      options={availableTags}
      disableCloseOnSelect
      ChipProps={{ color: "secondary" }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Select Tags'" placeholder="Search..." />
      )}
      onChange={(
        event: any,
        newValue: Array<{ id: string; name: string }> | null
      ) => {
        onChange(newValue?.map((it) => it.id));
      }}
      value={initialValues || []}
    />
  );
};
export default SelectFeedTags;
