import { useEffect, useState } from "react";
import {
  useEditThreadCommentMutation,
  useReplyThreadCommentMutation,
  useSendThreadCommentMutation,
} from "../../services/forum.service";
import { toast } from "react-toastify";
import { ForumThread, ThreadComment } from "../../types/forum";
import {
  Avatar,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  AttachFileOutlined,
  EditOutlined,
  SendOutlined,
} from "@mui/icons-material";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useFilePicker } from "use-file-picker";

const EditComment = ({
  thread,
  comment,
  onFinish,
}: {
  thread: ForumThread;
  comment?: ThreadComment;
  onFinish?: () => void;
}) => {
  // const user = useCurrentUser();
  const [doEdit, { isLoading: editing }] = useEditThreadCommentMutation();
  const [commentText, setCommentText] = useState(comment?.comment || "");

  // const [
  //   openFileSelector,
  //   { plainFiles, loading, errors: uploadErrors, clear },
  // ] = useFilePicker({
  //   multiple: true,
  //   accept: [".jpeg", ".jpg", ".png", ".gif"],
  //   maxFileSize: 5,
  //   readFilesContent: false,
  // });
  // const [files, setFiles] = useState<Array<File>>([]);

  // useEffect(() => {
  //   if (plainFiles?.length > 0) {
  //     setFiles(files.concat(plainFiles));
  //   }
  // }, [plainFiles]);

  const handleEditComment = async () => {
    try {
      if (!!comment) {
        await doEdit({
          thread_id: thread?.id!,
          comment_id: comment?.id!,
          comment: commentText,
          // files,
        }).unwrap();
      }

      if (onFinish) onFinish();
      // setFiles([]);
    } catch (err) {
      toast.error(err);
    }
  };

  return (
    <>
      <Stack direction={"row"} alignItems={"center"}>
        <TextField
          fullWidth
          value={commentText}
          color="secondary"
          variant="standard"
          multiline={true}
          onChange={(e) => setCommentText(e.target.value)}
          focused
        />
        <LoadingButton
          disabled={commentText.trim().length == 0}
          onClick={handleEditComment}
          loading={editing}
          size="small"
        >
          Save
        </LoadingButton>
        {/* <LoadingButton onClick={() => openFileSelector()} size="small">
          <AttachFileOutlined />
        </LoadingButton> */}
        {!!comment && (
          <Button onClick={() => !!onFinish && onFinish()} size="small">
            Close
          </Button>
        )}
      </Stack>
      {/* {files.length > 0 && (
        <List>
          {files.map((f) => (
            <ListItem
              secondaryAction={
                <Button
                  variant="text"
                  onClick={() =>
                    setFiles(files.filter((it) => it.name != f.name))
                  }
                >
                  ❌
                </Button>
              }
            >
              <ListItemText>{f.name}</ListItemText>
            </ListItem>
          ))}
        </List>
      )} */}
    </>
  );
};
export default EditComment;
