import {
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { useTitle } from "../hooks/useTitle";
import {
  AttachMoneyOutlined,
  BookOutlined,
  InfoOutlined,
  SupportOutlined,
} from "@mui/icons-material";

const pdf =
  "https://firebasestorage.googleapis.com/v0/b/the-method-329619.appspot.com/o/Workz360%20User%20Manual%20%26%20Stripe.pdf?alt=media&token=b1b01e7f-3b10-4773-bebc-8ee469ae9193";

const HelpSupportPage = () => {
  useTitle("Help & Support");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Paper sx={{ p: 2 }}>
          <List>
            <ListItem>
              <ListItemIcon>
                <InfoOutlined fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Link
                    component="button"
                    onClick={() =>
                      window.open(
                        "mailto:info@workz360.com?subject=General%20Inquiry",
                        "_blank"
                      )
                    }
                  >
                    General Inquiry
                  </Link>
                }
                primaryTypographyProps={{ fontSize: 18 }}
                secondary={
                  <Typography variant="body2">
                    Any questions, comments, or concerns about the Workz360 app
                  </Typography>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemIcon>
                <SupportOutlined fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Link
                    component="button"
                    onClick={() =>
                      window.open(
                        "mailto:support@workz360.com?subject=Need%20Assistance",
                        "_blank"
                      )
                    }
                  >
                    Support
                  </Link>
                }
                primaryTypographyProps={{ fontSize: 18 }}
                secondary={
                  <Typography variant="body2">
                    Any technical issues or issues related to work
                    opportunities.
                  </Typography>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemIcon>
                <AttachMoneyOutlined fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Link
                    component="button"
                    onClick={() =>
                      window.open(
                        "mailto:payments@workz360.com?subject=Need%20Assistance",
                        "_blank"
                      )
                    }
                  >
                    Payment Issues
                  </Link>
                }
                primaryTypographyProps={{ fontSize: 18 }}
                secondary={
                  <Typography variant="body2">
                    Questions about payment, tax, etc
                  </Typography>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemIcon>
                <BookOutlined fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Link
                    component="button"
                    onClick={() => window.open(pdf, "_blank")}
                  >
                    Workz360 User Manual
                  </Link>
                }
                primaryTypographyProps={{ fontSize: 18 }}
                secondary={
                  <Typography variant="body2">
                    Learn how to use the Workz360 mobile app
                  </Typography>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default HelpSupportPage;
