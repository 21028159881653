import { SearchOutlined, TuneOutlined } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Badge,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputBase,
  LinearProgress,
  Paper,
} from "@mui/material";
import { useGeolocated } from "react-geolocated";
import useDebounce from "../../hooks/useDebounce";
import React from "react";
import { ProjectStatus } from "../../types/project";
import { useGetProjectsQuery } from "../../services/project.service";
import { milesToMeter } from "../../utils/mapHelper";
import CardProject from "./CardProject";
import { useProfileCompleteness } from "../../hooks/useProfileCompleteness";
import { useSelector } from "react-redux";
import { currentSearchFilter } from "../../app/uiSlice";
import ModalSearchFilter from "./ModalSearchFilter";

const SearchWorksPage = () => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const debounceQuery = useDebounce(searchQuery, 500);
  const { hasCompleted: profileCompleted } = useProfileCompleteness();
  const [modalFilterOpen, setModalFilterOpen] = React.useState(false);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 30000,
      watchLocationPermissionChange: true,
    });

  const { skills, verticals, radius } = useSelector(currentSearchFilter);

  const { data, isLoading, isFetching, refetch } = useGetProjectsQuery(
    {
      search: debounceQuery,
      distance: !!coords
        ? `${coords.latitude}|${coords.longitude}|${milesToMeter(radius)}`
        : undefined,
      skills: skills.length > 0 ? skills : undefined,
      verticals: verticals.length > 0 ? verticals : undefined,
    },
    {
      skip: !coords,
    }
  );

  const projects = React.useMemo(
    () =>
      data?.data.filter((it) => {
        if (
          it.num_of_talent == 1 &&
          (it.status == ProjectStatus.SCHEDULED ||
            it.status == ProjectStatus.IN_PROGRESS)
        ) {
          // hide projects that already taken / scheduled
          return false;
        } else if (it.is_private) {
          // check if we're invited/bid
          // const bid = it?.resources?.find((bid) => bid.resource_id == user?.id);
          // return !!bid;

          // hide it
          return false;
        }
        return true;
      }),
    [data]
  );

  const hasFilter = React.useMemo(
    () => skills?.length > 0 || verticals?.length > 0,
    [skills, verticals]
  );

  const onChangeSearch = (e: any) => setSearchQuery(e.target.value);

  if (!isGeolocationAvailable)
    return (
      <Alert severity="error">Your browser does not support Geolocation</Alert>
    );

  if (!isGeolocationEnabled)
    return (
      <Alert severity="error">
        <AlertTitle>Search Disabled</AlertTitle>
        Search feature need your location for accurate results. Please allow the
        app to access your location.
      </Alert>
    );

  if (!profileCompleted)
    return (
      <Alert severity="error">
        <AlertTitle>Search Disabled</AlertTitle>
        ⚠️ You must complete your profile before you can search for work and
        submit your Offer. Please open the Workz360 mobile app and complete your
        profile.
      </Alert>
    );

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton sx={{ p: 1 }} onClick={() => setModalFilterOpen(true)}>
              {hasFilter ? (
                <Badge color="secondary" variant="dot">
                  <TuneOutlined />
                </Badge>
              ) : (
                <TuneOutlined />
              )}
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Work Oportunities"
              onChange={onChangeSearch}
              disabled={!coords}
            />
            <IconButton type="button" sx={{ px: 2, py: 1 }} aria-label="search">
              <SearchOutlined />
            </IconButton>
          </Paper>
          {(isFetching || !coords) && <LinearProgress />}
        </Grid>
        <Grid item xs={12}>
          {projects?.length == 0 && (
            <Alert severity="info">
              <AlertTitle>Not Found</AlertTitle>Nearby work not found. Please
              adjust your commute radius and/or search filter.
            </Alert>
          )}
          {projects?.map((project) => (
            <CardProject project={project} userCoord={coords} />
          ))}
        </Grid>
      </Grid>
      <ModalSearchFilter
        open={modalFilterOpen}
        onClose={() => setModalFilterOpen(false)}
      />
    </Box>
  );
};
export default SearchWorksPage;
