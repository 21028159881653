import * as React from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { useGetBusinessVerticalsQuery } from "../services/vertical.service";
import { BusinessVertical } from "../types/vertical";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SelectVerticals = ({
  onChange,
  values,
}: {
  onChange: (ids?: number[]) => void;
  values: number[];
}) => {
  const {
    data: verticals,
    isLoading,
    isFetching,
  } = useGetBusinessVerticalsQuery();

  const initialValues = React.useMemo(
    () => verticals?.filter((it) => values?.includes(it.id)),
    [verticals, values]
  );

  return (
    <Autocomplete
      multiple
      fullWidth
      options={verticals || []}
      disableCloseOnSelect
      ChipProps={{ color: "secondary" }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Industries" placeholder="Search..." />
      )}
      loading={isLoading}
      onChange={(event: any, newValue: BusinessVertical[] | null) => {
        onChange(newValue?.map((it) => it.id));
      }}
      value={initialValues || []}
    />
  );
};
export default SelectVerticals;
