import * as React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import ErrorNotFound from "./components/ErrorNotFound";
import ProtectedPageLayout from "./components/ProtectedPageLayout";
import LoginPage from "./features/auth/LoginPage";
import { useAppSelector } from "./hooks/store";
import { useLoadUserQuery } from "./services/auth.service";
import CircularProgress from "@mui/material/CircularProgress";
import { useCurrentUser } from "./hooks/useCurrentUser";
import { isProductionMode } from "./utils/helper";
import CornerRibbon from "./components/CornerRibbon";
import { colors } from "@mui/material";
import HomePage from "./features/home/HomePage";
import ActiveWorksPage from "./features/works/ActiveWorksPage";
import WorkOffersPage from "./features/works/WorkOffersPage";
import WorkHistoryPage from "./features/works/WorkHistoryPage";
import WorkDetailPage from "./features/works/WorkDetailPage";
import ThreadDetailPage from "./features/connect/ThreadDetailPage";
import FeedsPage from "./features/connect/FeedsPage";
import ResourcesPage from "./features/connect/ResourcesPage";
import SearchWorksPage from "./features/works/SearchWorksPage";
import EditFeedPage from "./features/connect/EditFeedPage";
import SendFeedbackPage from "./features/connect/SendFeedbackPage";
import HelpSupportPage from "./features/HelpSupportPage";
import ChangePasswordPage from "./features/account/ChangePasswordPage";
import NotificationsPage from "./features/NotificationsPage";
import InboxPage from "./features/inbox/InboxPage";
import InboxChatPage from "./features/inbox/InboxChatPage";
import AccountPage from "./features/account/AccountPage";
import EditAccountPage from "./features/account/EditAccountPage";
import BalanceHistoryPage from "./features/account/BalanceHistoryPage";

const Loader = () => {
  const token = useAppSelector((state) => state.auth.token);
  const { isLoading, refetch } = useLoadUserQuery(undefined, {});

  React.useEffect(() => {
    refetch();
  }, [token]);

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  return <Outlet />;
};

const PublicLayout = () => {
  const user = useCurrentUser();
  const isProduction = React.useMemo(() => isProductionMode(), []);
  if (!!user) return <Navigate to="/home" replace />;
  return (
    <div>
      {!isProduction && (
        <CornerRibbon backgroundColor={colors.yellow[200]} fontColor="#333">
          STAGING
        </CornerRibbon>
      )}
      <Outlet />
    </div>
  );
};

const IndexPage = () => {
  return <Navigate to="/login" />;
};

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Loader />}>
          <Route path="/" element={<IndexPage />} />
          <Route element={<PublicLayout />}>
            <Route path="/login" element={<LoginPage />} />
          </Route>
          <Route element={<ProtectedPageLayout />}>
            <Route path="/home" element={<HomePage />} />
            <Route path="/notifications">
              <Route index element={<NotificationsPage />} />
            </Route>
            <Route path="/profile">
              <Route index element={<p>Profile Page</p>} />
              {/* <Route path="create" element={<CompanyEditPage />} />
              <Route path=":companyId" element={<CompanyDetailPage />} />
              <Route path=":companyId/edit" element={<CompanyEditPage />} /> */}
            </Route>
            <Route path="/live-feed">
              <Route index element={<FeedsPage />} />
              <Route path="new" element={<EditFeedPage />} />
              <Route path="send-feedback" element={<SendFeedbackPage />} />
              <Route path=":threadId">
                <Route index element={<ThreadDetailPage />} />
                <Route path="edit" element={<EditFeedPage />} />
              </Route>
            </Route>
            <Route path="/private-messages">
              <Route index element={<InboxPage />} />
              <Route path=":targetId">
                <Route index element={<InboxChatPage />} />
              </Route>
            </Route>
            <Route path="/works">
              <Route index element={<ActiveWorksPage />} />
              <Route path="search">
                <Route index element={<SearchWorksPage />} />
                <Route path=":projectId" element={<WorkDetailPage />} />
              </Route>
              {/* <Route path="my-work" element={<ActiveWorksPage />} /> */}
              <Route path="my-offers" element={<WorkOffersPage />} />
              <Route path="completed" element={<WorkHistoryPage />} />
              <Route path=":projectId" element={<WorkDetailPage />} />
            </Route>
            <Route path="/resources">
              <Route index element={<ResourcesPage />} />
            </Route>
            <Route path="/help">
              <Route index element={<HelpSupportPage />} />
            </Route>
            <Route path="/change-password">
              <Route index element={<ChangePasswordPage />} />
            </Route>
            <Route path="/account">
              <Route index element={<AccountPage />} />
              <Route path="edit" element={<EditAccountPage />} />
              <Route path="balance-history" element={<BalanceHistoryPage />} />
            </Route>
          </Route>
          <Route path="*" element={<ErrorNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default AppRoutes;
