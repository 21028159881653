import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { AttachFileOutlined, UploadFileOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import {
  useCreateForumThreadMutation,
  useGetForumThreadByIdQuery,
  useUpdateForumThreadMutation,
} from "../../services/forum.service";
import { useNavigate, useParams } from "react-router";
import { useFilePicker } from "use-file-picker";
import SelectFeedTags from "../../components/SelectFeedTags";
import { useCurrentUser } from "../../hooks/useCurrentUser";

const EditFeedPage = () => {
  const navigate = useNavigate();
  const user = useCurrentUser();
  const { threadId } = useParams();

  const {
    handleSubmit,
    clearErrors,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      body: "",
      tags: [] as string[],
    },
  });

  const { data } = useGetForumThreadByIdQuery(
    { id: parseInt(threadId!) },
    { skip: !threadId }
  );
  const [createThread, { isLoading: creating }] =
    useCreateForumThreadMutation();
  const [updateThread, { isLoading: updating }] =
    useUpdateForumThreadMutation();

  useEffect(() => {
    if (!data) return;
    setValue("title", data?.title || "");
    setValue("body", data?.body || "");
    setValue("tags", data?.tags.split(",") || []);
  }, [data]);

  const onSubmit = async (payload: any) => {
    try {
      const { tags, title, body } = payload;
      if (tags.length == 0) {
        toast.error("Please select at least one tag(s)");
        return;
      }

      payload.tags = tags.join(",");

      if (files.length > 0) payload.files = files;

      if (!!threadId) {
        payload.thread_id = threadId;
        await updateThread(payload).unwrap();
        toast.success("Feed updated");
        navigate("/live-feed/" + threadId);
        //
      } else {
        await createThread(payload).unwrap();
        toast.success("Feed created");
        navigate("/live-feed");
      }
    } catch (err: any) {
      toast.error(err);
    }
  };

  const [
    openFileSelector,
    { plainFiles, loading, errors: uploadErrors, clear },
  ] = useFilePicker({
    multiple: true,
    accept: [".jpeg", ".jpg", ".png", ".gif"],
    maxFileSize: 5,
    readFilesContent: false,
  });
  const [files, setFiles] = useState<Array<File>>([]);

  useEffect(() => {
    if (plainFiles?.length > 0) {
      setFiles(files.concat(plainFiles));
    }
  }, [plainFiles]);

  if (!!threadId && data?.user?.id != user?.id)
    return (
      <Alert severity="error">
        <AlertTitle>Forbidden</AlertTitle>
      </Alert>
    );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Paper sx={{ p: 2 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="title"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Title"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.title}
                  helperText={!!errors.title && "This field is required"}
                />
              )}
            />
            <Controller
              name="body"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Content"
                  value={value}
                  fullWidth
                  minRows={10}
                  multiline={true}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.body}
                  helperText={!!errors.body && "This field is required"}
                />
              )}
            />
            <Controller
              name="tags"
              control={control}
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <SelectFeedTags values={value} onChange={onChange} />
              )}
            />
            <Button
              startIcon={<AttachFileOutlined />}
              onClick={() => openFileSelector()}
              variant="text"
            >
             +ADD Photo
            </Button>
            {files.length > 0 && (
              <List>
                {files.map((f) => (
                  <ListItem
                    secondaryAction={
                      <Button
                        variant="text"
                        onClick={() =>
                          setFiles(files.filter((it) => it.name != f.name))
                        }
                      >
                        ❌
                      </Button>
                    }
                  >
                    <ListItemText>{f.name}</ListItemText>
                  </ListItem>
                ))}
              </List>
            )}
            <Box>
              <Divider sx={{ my: 1 }} />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={creating || updating}
                sx={{ mt: 1 }}
              >
                {!threadId ? "Post" : "Update Feed"}
              </LoadingButton>
            </Box>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default EditFeedPage;
