import { Avatar, IconButton, Link } from "@mui/material";
import * as React from "react";
import { User } from "../types/users";
import ModalTalentProfile from "../features/inbox/ModalTalentProfile";

const ClickableAvatar = ({ talent }: { talent?: User }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  return (
    <>
      <IconButton onClick={() => setModalOpen(true)}>
        <Avatar src={talent?.photo?.[0]?.original_url} />
      </IconButton>
      <ModalTalentProfile
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        id={talent?.id || 0}
      />
    </>
  );
};
export default ClickableAvatar;
