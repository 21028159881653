import {Resource} from '../types/resource';
import {baseApi} from './base';
import {TAG_TYPE_RESOURCE} from './tags';

const apiWithTag = baseApi.enhanceEndpoints({addTagTypes: [TAG_TYPE_RESOURCE]});

export const resourceApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getResources: builder.query<Array<Resource>, void>({
      query: () => ({
        url: 'api/resources',
        method: 'GET',
        params: {
          sort: `id|asc`,
        },
      }),
      providesTags: result => {
        const data = result;
        if (!data) return [{type: TAG_TYPE_RESOURCE, id: 'PARTIAL-LIST'}];
        return [{type: TAG_TYPE_RESOURCE, id: 'PARTIAL-LIST'}];
      },
    }),
  }),
  overrideExisting: false,
});

export const {useGetResourcesQuery} = resourceApi;
