export const TAG_TYPE_USER: string = 'user';
export const TAG_TYPE_COUNTRY: string = 'country';
export const TAG_TYPE_VERTICAL: string = 'vertical';
export const TAG_TYPE_SKILL: string = 'skill';
export const TAG_TYPE_LANGUAGE: string = 'language';
export const TAG_TYPE_CERTIFICATION: string = 'certification';
export const TAG_TYPE_EDUCATION: string = 'education';
export const TAG_TYPE_WORK_EXPERIENCE: string = 'work-experience';
export const TAG_TYPE_JOB: string = 'job';
export const TAG_TYPE_PROJECT: string = 'project';
export const TAG_TYPE_REVIEW: string = 'project-review';
export const TAG_TYPE_REPORT: string = 'report';
export const TAG_TYPE_WITHDRAWAL: string = 'withdrawal';
export const TAG_TYPE_NOTIFICATION: string = 'notification';
export const TAG_TYPE_CHAT: string = 'chat';
export const TAG_TYPE_SETTING: string = 'setting';
export const TAG_TYPE_RESOURCE: string = 'resource';
export const TAG_TYPE_TALENT: string = 'talent';
export const TAG_TYPE_INBOX_ROOM: string = 'inbox-room';
export const TAG_TYPE_INBOX_MESSAGE: string = 'inbox-message';
export const TAG_TYPE_FORUM_THREAD: string = 'forum-thread';
export const TAG_TYPE_FORUM_COMMENT: string = 'forum-comment';
export const TAG_TYPE_TALENT_PROFILE: string = 'talent-profile';
