import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAppDispatch } from "../hooks/store";
import { clearSession } from "../features/auth/authSlice";
import { currentTitle } from "../app/uiSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Avatar, colors } from "@mui/material";
import { isManage360 } from "../utils/helper";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { useGetNotificationsQuery } from "../services/notification.service";
import { CommentOutlined, MailOutlineOutlined } from "@mui/icons-material";

const drawerWidth: number = 250 - 1;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function stringAvatar(name: string) {
  return {
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const Navbar = ({
  open,
  onToggle,
}: {
  open: boolean;
  onToggle: () => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const pageTitle = useSelector(currentTitle);
  const user = useCurrentUser();
  const { data: notifications, isLoading } = useGetNotificationsQuery();

  const unreadNotifCount = React.useMemo(() => {
    return (
      notifications?.data?.filter((it) => !!it.read_at == false)?.length || 0
    );
  }, [notifications]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleLogout = () => {
    dispatch(clearSession());
    setAnchorElUser(null);
  };
  const handleAccountMenu = () => {
    navigate("account");
    setAnchorElUser(null);
  };

  return (
    <AppBar position="absolute" open={open} color="primary">
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={onToggle}
          sx={{
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          {pageTitle}
        </Typography>
        <IconButton color="inherit" onClick={() => navigate("/notifications")}>
          <Badge badgeContent={unreadNotifCount} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        {/* <IconButton
          color="inherit"
          onClick={() => navigate("/private-messages")}
          sx={{ ml: 1 }}
        >
          <Badge badgeContent={0} color="secondary">
            <MailOutlineOutlined />
          </Badge>
        </IconButton> */}
        <IconButton color="inherit" onClick={handleOpenUserMenu} sx={{ ml: 2 }}>
          {!!user?.photo?.[0]?.original_url ? (
            <Avatar src={user?.photo?.[0]?.original_url} />
          ) : (
            <Avatar {...stringAvatar(user?.full_name || "?")} />
          )}
        </IconButton>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem key={"account"} onClick={handleAccountMenu}>
            <Typography textAlign="center">My Account</Typography>
          </MenuItem>
          <MenuItem key={"logout"} onClick={handleLogout}>
            <Typography textAlign="center">Logout</Typography>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};
export default Navbar;
