import * as React from "react";
import { Box, Autocomplete, TextField } from "@mui/material";
import { useGetCountriesQuery } from "../services/country.service";
import { Country } from "../types/country";

const SelectCountry = ({
  onChange,
  sx,
  value,
  error,
  helperText,
  size,
}: {
  onChange: (arg?: number) => void;
  sx?: any;
  value: number;
  error?: boolean;
  helperText?: React.ReactNode;
  size?: "small" | "medium";
}) => {
  const { data: countries, isLoading } = useGetCountriesQuery({
    sortField: "name",
    sortMode: "asc",
  });

  const selected = React.useMemo(
    () => countries?.find((it) => it.id == value),
    [countries, value]
  );

  return (
    <Autocomplete
      fullWidth
      options={countries || []}
      value={selected || null}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Country"
          placeholder="Search..."
          size={size}
          sx={sx}
          error={error}
          helperText={helperText}
        />
      )}
      onChange={(event: any, newValue: Country | null) => {
        onChange(newValue?.id);
      }}
      loading={isLoading}
    />
  );
};
export default SelectCountry;
