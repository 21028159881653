import {BusinessVertical} from '../types/vertical';
import {baseApi} from './base';
import {TAG_TYPE_VERTICAL} from './tags';

const apiWithTag = baseApi.enhanceEndpoints({addTagTypes: [TAG_TYPE_VERTICAL]});

export const verticalApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getBusinessVerticals: builder.query<Array<BusinessVertical>, void>({
      query: () => ({
        url: 'api/business-verticals',
        method: 'GET',
        params: {
          sort: `name|asc`,
        },
      }),
      providesTags: result => {
        const data = result;
        if (!data) return [{type: TAG_TYPE_VERTICAL, id: 'PARTIAL-LIST'}];
        return [
          {type: TAG_TYPE_VERTICAL, id: 'PARTIAL-LIST'},
          ...data?.map(({id}) => ({type: TAG_TYPE_VERTICAL, id})),
        ];
      },
    }),
  }),
  overrideExisting: false,
});

export const {useGetBusinessVerticalsQuery} = verticalApi;
