import {baseApi} from './base';
import {TAG_TYPE_REPORT} from './tags';

const apiWithTag = baseApi.enhanceEndpoints({addTagTypes: [TAG_TYPE_REPORT]});

export const reportApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getTotalEarningByDate: builder.query<
      {total: number},
      {start_date: string; end_date: string}
    >({
      query: ({start_date, end_date}) => ({
        url: 'api/user/reports/total-earning-by-date',
        method: 'GET',
        params: {
          start_date,
          end_date,
        },
      }),
      providesTags: result => {
        return [{type: TAG_TYPE_REPORT, id: 'TOTAL-EARNING'}];
      },
    }),
    getTotalJobByDate: builder.query<
      {total: number},
      {start_date: string; end_date: string}
    >({
      query: ({start_date, end_date}) => ({
        url: 'api/user/reports/total-job-by-date',
        method: 'GET',
        params: {
          start_date,
          end_date,
        },
      }),
      providesTags: result => {
        return [{type: TAG_TYPE_REPORT, id: 'TOTAL-JOB'}];
      },
    }),
    getTotalHourByDate: builder.query<
      {total_second: number; total_hour: number},
      {start_date: string; end_date: string}
    >({
      query: ({start_date, end_date}) => ({
        url: 'api/user/reports/total-hour-by-date',
        method: 'GET',
        params: {
          start_date,
          end_date,
        },
      }),
      providesTags: result => {
        return [{type: TAG_TYPE_REPORT, id: 'TOTAL-HOUR'}];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTotalEarningByDateQuery,
  useGetTotalJobByDateQuery,
  useGetTotalHourByDateQuery,
} = reportApi;
