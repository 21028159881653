import {
  NextWeekOutlined,
  RefreshOutlined,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useGetMyJobsQuery } from "../../services/my-job.service";
import { NegotiationStatus, ProjectStatus } from "../../types/project";
import dayjs from "dayjs";
import TableRowActive from "../works/TableRowActive";
import TableRowOffer from "../works/TableRowOffer";
import TableRowInvite from "../works/TableRowInvite";

const WorkTablesView = () => {
  const [mode, setMode] = React.useState<"active" | "bid" | "invited">(
    "active"
  );

  const { data, refetch, isFetching } = useGetMyJobsQuery({
    statuses: "new,invited,hired,interview",
    load: "project,project.company,reportProgresses,project.manager",
  });

  const {
    data: jobs,
    activeCount,
    bidCount,
    inviteCount,
  } = React.useMemo(() => {
    const active = data
      ?.filter(
        (it) =>
          it.status == NegotiationStatus.HIRED &&
          [ProjectStatus.SCHEDULED, ProjectStatus.IN_PROGRESS].includes(
            it.project?.status!
          )
      )
      .slice()
      .sort((a, b) =>
        dayjs(a?.start_date || a?.project?.start_date || 0).isAfter(
          dayjs(b?.start_date || b?.project?.start_date || 0)
        )
          ? -1
          : 1
      );
    const bids = data?.filter(
      (it) =>
        [NegotiationStatus.INTERVIEW, NegotiationStatus.NEW].includes(
          it.status
        ) && it.project?.status != ProjectStatus.CANCELED
    );
    const invitations = data?.filter(
      (it) =>
        [NegotiationStatus.INVITED].includes(it.status) &&
        it.project?.status == ProjectStatus.PUBLISHED
    );
    // const isHired = (job: ProjectResource) => {
    //   let hired = false;
    //   job.project?.resources?.map(resource => {
    //     if (resource.status === NegotiationStatus.HIRED) {
    //       hired = true;
    //     }
    //     return hired;
    //   });
    // };
    const ret = {
      activeCount: active?.length || 0,
      bidCount: bids?.length || 0,
      inviteCount: invitations?.length || 0,
    };

    if (mode == "active")
      return {
        ...ret,
        data: active,
      };
    else if (mode == "bid")
      return {
        ...ret,
        data: bids,
      };
    else
      return {
        ...ret,
        data: invitations,
      };
  }, [data, mode]);

  return (
    <Box>
      <ToggleButtonGroup
        color="primary"
        value={mode}
        size="small"
        exclusive
        sx={{ mb: 1 }}
        onChange={(e, value) => setMode(value)}
      >
        <ToggleButton value={"active"} sx={{ px: 2 }}>
          {`Scheduled Work (${activeCount})`}
        </ToggleButton>
        <ToggleButton value={"bid"} sx={{ px: 2 }}>
          {`My Offers (${bidCount})`}
        </ToggleButton>
        <ToggleButton value={"invited"} sx={{ px: 2 }}>
          {`Invited (${inviteCount})`}
        </ToggleButton>
      </ToggleButtonGroup>
      <Button
        size="small"
        startIcon={<RefreshOutlined />}
        sx={{ ml: 2 }}
        onClick={() => refetch()}
      >
        Refresh
      </Button>
      {isFetching && <LinearProgress />}
      {mode == "active" && (
        <Card>
          <CardHeader
            title={`Scheduled Work (${activeCount})`}
            avatar={<NextWeekOutlined />}
          />
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Work ID/Title</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell align="right">Rate</TableCell>
                  <TableCell align="right">Total Hours</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobs?.map((job) => (
                  <TableRowActive job={job} key={job.id} />
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      )}
      {mode == "bid" && (
        <Card>
          <CardHeader
            title={`My Offers (${jobs?.length || 0})`}
            avatar={<WorkOutlineOutlined />}
          />
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Work ID/Title</TableCell>
                  <TableCell>Requested Start Date</TableCell>
                  <TableCell>Submitted At</TableCell>
                  <TableCell align="right">Submitted Rate</TableCell>
                  <TableCell align="right">Submitted Estimated Hours</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobs?.length == 0 && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography color={"GrayText"}>
                        No active offers here, start a new offer.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {jobs?.map((job) => (
                  <TableRowOffer job={job} key={job.id} />
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      )}
      {mode == "invited" && (
        <Card>
          <CardHeader
            title={`Invited (${jobs?.length || 0})`}
            avatar={<WorkOutlineOutlined />}
          />
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Work ID/Title</TableCell>
                  <TableCell>Date Invited</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobs?.length == 0 && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography color={"GrayText"}>
                        No current invitations in queue, let's explore new
                        opportunities.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {jobs?.map((job) => (
                  <TableRowInvite job={job} key={job.id} />
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default WorkTablesView;
