import { useParams } from "react-router";
import { useGetProjectByIdQuery } from "../../services/project.service";
import { Grid, LinearProgress } from "@mui/material";
import { NegotiationStatus } from "../../types/project";
import React, { useMemo } from "react";
import { useTitle } from "../../hooks/useTitle";
import WorkDetailView from "./WorkDetailView";
import ProjectDetailView from "./ProjectDetailView";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import BidView from "./BidView";

const WorkDetailPage = () => {
  const { projectId } = useParams();
  const user = useCurrentUser();

  useTitle(`Work ID #${projectId}`);

  const {
    data: project,
    refetch: refetchProject,
    isFetching: fetchingProject,
  } = useGetProjectByIdQuery(parseInt(projectId!), { skip: !projectId });

  const job = React.useMemo(
    () => project?.resources?.find((it) => it.resource_id == user?.id),
    [project, user]
  );

  const bidInProgress = useMemo(() => {
    return (
      job?.status == NegotiationStatus.INVITED ||
      job?.status == NegotiationStatus.NEW ||
      job?.status == NegotiationStatus.INTERVIEW ||
      job?.status == NegotiationStatus.REJECTED
    );
  }, [job]);

  return (
    <Grid container spacing={2}>
      {fetchingProject && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
      {!!project && !!job && !!bidInProgress && (
        <Grid item xs={12}>
          <BidView project={project} job={job} />
        </Grid>
      )}
      {!!project ? (
        !!job &&
        (job.status == NegotiationStatus.HIRED ||
          job.status == NegotiationStatus.COMPLETED) ? (
          <Grid item xs={12}>
            <WorkDetailView project={project} job={job} />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <ProjectDetailView project={project} />
          </Grid>
        )
      ) : (
        <></>
      )}
    </Grid>
  );
};
export default WorkDetailPage;
