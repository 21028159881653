import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import {
  useAddUserWorkExperienceMutation,
  useUpdateUserWorkExperienceMutation,
} from "../../services/user.service";
import { UserWorkExperience } from "../../types/workExperience";
import SelectCountry from "../../components/SelectCountry";

interface ModalUserExperienceFormProps {
  open: boolean;
  onClose: () => void;
  userId: number;
  data: UserWorkExperience | null;
}

const ModalUserExperienceForm = ({
  open,
  onClose,
  userId,
  data,
}: ModalUserExperienceFormProps) => {
  const [add, { isLoading: adding }] = useAddUserWorkExperienceMutation();
  const [edit, { isLoading: editing }] = useUpdateUserWorkExperienceMutation();

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      payload.user_id = userId;

      if (!!payload.start_period) {
        payload.start_period_month = dayjs(payload.start_period).month() + 1;
        payload.start_period_year = dayjs(payload.start_period).year();
      }
      if (!!payload.end_period) {
        payload.end_period_month = dayjs(payload.end_period).month() + 1;
        payload.end_period_year = dayjs(payload.end_period).year();
      }

      if (!data) {
        await add(payload).unwrap();
        toast.success("Work experience added");
      } else {
        payload.id = data?.id!;
        await edit(payload).unwrap();
        toast.success("Work experience updated");
      }
      onClose();
    } catch (err) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (open) {
      clearErrors();

      setValue("company", data?.company);
      setValue("country", data?.country_id);
      setValue("city", data?.city);
      setValue(
        "start_period",
        !!data?.start_period_month
          ? dayjs()
              .month(data?.start_period_month!)
              .year(data?.start_period_year!)
          : undefined
      );
      setValue(
        "end_period",
        !!data?.end_period_month
          ? dayjs().month(data?.end_period_month!).year(data?.end_period_year!)
          : undefined
      );
    }
  }, [data, open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{!data ? "Add" : "Edit"} Work Experience</DialogTitle>
        <DialogContent>
          <Box>
            <Controller
              name="company"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Company Name"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.company}
                  helperText={!!errors.company && "This field is required"}
                />
              )}
            />
            <Controller
              name="country"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <SelectCountry
                  onChange={onChange}
                  value={value}
                  error={!!errors.country}
                  helperText={!!errors.country && "This field is required"}
                />
              )}
            />
            <Controller
              name="city"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="City"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.city}
                  helperText={!!errors.city && "This field is required"}
                />
              )}
            />
            <Controller
              name="start_period"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <DesktopDatePicker
                  sx={{ mt: 1, width: "100%" }}
                  onChange={onChange}
                  value={!!value ? dayjs(value) : undefined}
                  views={["month", "year"]}
                  label="From"
                  onError={() => {
                    setError("start_period", {
                      type: "custom",
                      message: "Invalid",
                    });
                  }}
                  onAccept={() => clearErrors("start_period")}
                  slotProps={{
                    textField: {
                      helperText:
                        !!errors.start_period && "This field is required",
                      error: !!errors.start_period,
                    },
                  }}
                />
              )}
            />
            <Controller
              name="end_period"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <DesktopDatePicker
                  sx={{ mt: 1, width: "100%" }}
                  onChange={onChange}
                  value={!!value ? dayjs(value) : undefined}
                  views={["month", "year"]}
                  label="To (optional)"
                  onError={() => {
                    setError("end_period", {
                      type: "custom",
                      message: "Invalid",
                    });
                  }}
                  onAccept={() => clearErrors("end_period")}
                  slotProps={{
                    textField: {
                      helperText:
                        !!errors.end_period && "This field is required",
                      error: !!errors.end_period,
                    },
                  }}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={adding || editing}
          >
            {!data ? "Add" : "Update"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalUserExperienceForm;
