import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useGetTalentByIdQuery } from "../../services/talent.service";
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { CommentOutlined, FlagOutlined } from "@mui/icons-material";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../hooks/useCurrentUser";

interface ModalTalentProfileProps {
  open: boolean;
  onClose: () => void;
  id: number;
}

const ModalTalentProfile = ({ open, onClose, id }: ModalTalentProfileProps) => {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const { data: talent, isLoading } = useGetTalentByIdQuery(
    { id },
    {
      skip: !open,
    }
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>{talent?.full_name || "Profile"}</DialogTitle>
      <DialogContent>
        {isLoading && <CircularProgress />}
        <Box textAlign={"center"}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              src={talent?.photo?.[0]?.original_url}
              sx={{ width: 100, height: 100 }}
            />
          </Box>
          <Typography variant="h5">{talent?.full_name}</Typography>
          <Typography variant="body2">
            {talent?.city || "?"} {talent?.state || "?"}
          </Typography>

          {user?.id != talent?.id && (
            <Stack direction={"row"} justifyContent={"center"} sx={{ mt: 2 }}>
              <Button
                startIcon={<CommentOutlined />}
                variant="outlined"
                sx={{ mr: 2 }}
                size="small"
                onClick={() =>
                  navigate(`/private-messages/${talent?.id}?isUnread=1`)
                }
              >
                Send Message
              </Button>
              <Button
                startIcon={<FlagOutlined />}
                variant="outlined"
                size="small"
                onClick={() =>
                  navigate({
                    pathname: "/live-feed/send-feedback",
                    search: createSearchParams({
                      type: "Report an User",
                      info: `UserID:${talent?.id}`,
                    }).toString(),
                  })
                }
              >
                Report
              </Button>
            </Stack>
          )}

          <Divider sx={{ my: 1 }} />
          <Box>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Skills:
            </Typography>
            {talent?.skills?.length == 0
              ? "n/a"
              : talent?.skills?.map((it) => (
                  <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                ))}
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Industries:
            </Typography>
            {talent?.business_verticals?.length == 0
              ? "n/a"
              : talent?.business_verticals?.map((it) => (
                  <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ mb: 1 }}>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalTalentProfile;
