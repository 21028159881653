import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import useDebounce from "../../hooks/useDebounce";
import { useGetTalentsQuery } from "../../services/talent.service";
import {
  Avatar,
  Divider,
  IconButton,
  InputBase,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { MessageOutlined, SearchOutlined } from "@mui/icons-material";
import { Talent } from "../../types/users";
import ClickableAvatar from "../../components/ClickableAvatar";

function stringAvatar(name: string) {
  return {
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const TalentItem = ({ item }: { item: Talent }) => {
  const navigate = useNavigate();
  const user = useCurrentUser();

  return (
    <>
      <ListItem
        secondaryAction={
          <IconButton
            onClick={() =>
              navigate(`/private-messages/${item.id}?isUnread=false`)
            }
            disabled={item.id == user?.id}
          >
            <MessageOutlined />
          </IconButton>
        }
      >
        <ListItemIcon>
          <ClickableAvatar talent={item} />
        </ListItemIcon>
        <ListItemText
          primary={`${item.full_name} ${item.id == user?.id ? "[You]" : ""}`}
          primaryTypographyProps={{
            fontSize: 16,
          }}
          secondary={
            <Typography variant="caption">
              {item.city || "?"}, {item.state || "?"}
            </Typography>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

interface ModalSearchTalentProps {
  open: boolean;
  onClose: () => void;
}

const ModalSearchTalent = ({ open, onClose }: ModalSearchTalentProps) => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const debounceQuery = useDebounce(searchQuery, 500);

  const {
    data: talents,
    isFetching: searching,
    refetch,
  } = useGetTalentsQuery(
    {
      search: debounceQuery,
    },
    {
      skip: debounceQuery.length == 0 || !open,
    }
  );

  const onChangeSearch = (e: any) => setSearchQuery(e.target.value);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>Chat With</DialogTitle>
      <DialogContent>
        <Paper
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Independent Professionals"
            onChange={onChangeSearch}
          />
          <IconButton type="button" sx={{ px: 2, py: 1 }}>
            <SearchOutlined />
          </IconButton>
        </Paper>
        {searching && <LinearProgress />}
        <div
          style={{ position: "relative", height: "50vh", overflow: "scroll" }}
        >
          <List dense={true} disablePadding sx={{ mt: 1 }}>
            {talents?.data?.map((it) => (
              <TalentItem item={it} />
            ))}
          </List>
        </div>
      </DialogContent>
      <DialogActions sx={{ mb: 1 }}>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalSearchTalent;
