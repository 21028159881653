import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import WorkOutlineOutlined from "@mui/icons-material/WorkOutlineOutlined";
import HelpCenterOutlined from "@mui/icons-material/HelpCenterOutlined";
import ArticleOutlined from "@mui/icons-material/ArticleOutlined";
import AddReactionOutlined from "@mui/icons-material/AddReactionOutlined";
import LockOpenOutlined from "@mui/icons-material/LockOpenOutlined";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { clearSession } from "../features/auth/authSlice";
import { useAppDispatch } from "../hooks/store";
import {
  BusinessCenterOutlined,
  ExpandMoreOutlined,
  ForumOutlined,
  GroupsOutlined,
  HomeOutlined,
  KeyboardArrowRightOutlined,
  LanguageOutlined,
  MailOutline,
  NextWeekOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import { Collapse } from "@mui/material";

const USER_AGREEMENT_URL =
  "https://docs.google.com/document/d/1Gpdq0fogNDB_szyiwjVrjBFh6BLHjRtRH7S9TihRyuQ/edit?usp=sharing";

const SidebarMenu = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [workExpanded, setWorkExpanded] = React.useState(true);
  const [connectExpanded, setConnectExpanded] = React.useState(false);
  const [privacyExpanded, setPrivacyExpanded] = React.useState(false);

  const { pathname } = useLocation();
  const page = React.useMemo(() => {
    const parts = pathname.split("/");
    let page = "?";
    if (parts.length >= 3) page = parts[1] + "/" + parts[2];
    else if (parts.length >= 2) page = parts[1];
    return page;
  }, [pathname]);

  const handleInviteFriend = React.useCallback(() => {
    const androidUrl =
      "https://play.google.com/store/apps/details?id=com.flextek.workz360talent";
    const iosUrl = "https://apps.apple.com/us/app/workz360/id1624082524";
    const options = {
      title: "Download Workz360!",
      message: `Checkout this awesome app! I recently started using an app called Workz360, and I think you’d really benefit from it too. I’m able to find work that match my skillsets and get paid instantly!.\n\nIf you’re interested, you can download the app below:\n\nfor IOS → ${iosUrl}\n\nfor Android → ${androidUrl}`,
    };

    window.open(
      "mailto:?subject=" +
        encodeURI(options.title) +
        "&body=" +
        encodeURI(options.message),
      "_blank"
    );
  }, []);

  return (
    <List component="nav">
      <ListItemButton
        onClick={() => navigate("/home")}
        selected={page == "home"}
      >
        <ListItemIcon>
          <HomeOutlined />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
      <ListItemButton onClick={() => setWorkExpanded(!workExpanded)}>
        <ListItemIcon>
          <BusinessCenterOutlined />
        </ListItemIcon>
        <ListItemText primary="Work Management" />
        {workExpanded ? <ExpandMoreOutlined /> : <KeyboardArrowRightOutlined />}
      </ListItemButton>
      <Collapse in={workExpanded} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{ backgroundColor: "#eee" }}
        >
          <ListItemButton
            onClick={() => navigate("/works/search")}
            selected={page == "works/search"}
          >
            <ListItemIcon>
              <SearchOutlined />
            </ListItemIcon>
            <ListItemText primary="Search For Work" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/works")}
            selected={page == "works"}
          >
            <ListItemIcon>
              <NextWeekOutlined />
            </ListItemIcon>
            <ListItemText primary="My Work" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/works/my-offers")}
            selected={page == "works/my-offers"}
          >
            <ListItemIcon>
              <WorkOutlineOutlined />
            </ListItemIcon>
            <ListItemText primary="My Offers" />
          </ListItemButton>
          {/* <ListItemButton
            onClick={() => navigate("/works/completed")}
            selected={page == "works/completed"}
          >
            <ListItemIcon>
              <WorkHistoryOutlined />
            </ListItemIcon>
            <ListItemText primary="Completed Work" />
          </ListItemButton> */}
        </List>
      </Collapse>

      {/* connect */}
      <ListItemButton onClick={() => setConnectExpanded(!connectExpanded)}>
        <ListItemIcon>
          <GroupsOutlined />
        </ListItemIcon>
        <ListItemText primary="Connect" />
        {connectExpanded ? (
          <ExpandMoreOutlined />
        ) : (
          <KeyboardArrowRightOutlined />
        )}
      </ListItemButton>
      <Collapse in={connectExpanded} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{ backgroundColor: "#eee" }}
        >
          <ListItemButton
            onClick={() => navigate("/live-feed")}
            selected={page == "live-feed"}
          >
            <ListItemIcon>
              <ForumOutlined />
            </ListItemIcon>
            <ListItemText primary="Live Feed" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/private-messages")}
            selected={page == "private-messages"}
          >
            <ListItemIcon>
              <MailOutline />
            </ListItemIcon>
            <ListItemText primary="Private Messages" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton
        onClick={() => navigate("/resources")}
        selected={page == "resources"}
      >
        <ListItemIcon>
          <LanguageOutlined />
        </ListItemIcon>
        <ListItemText primary="Resources / News" />
      </ListItemButton>

      {/* Help & Support */}
      <ListItemButton
        onClick={() => navigate("/help")}
        selected={page == "help"}
      >
        <ListItemIcon>
          <HelpCenterOutlined />
        </ListItemIcon>
        <ListItemText primary="Help & Support" />
      </ListItemButton>

      <ListItemButton
        onClick={handleInviteFriend}
        selected={page == "invite-friend"}
      >
        <ListItemIcon>
          <AddReactionOutlined />
        </ListItemIcon>
        <ListItemText primary="Invite Your Friend" />
      </ListItemButton>

      <ListItemButton onClick={() => setPrivacyExpanded(!privacyExpanded)}>
        <ListItemIcon>
          <GroupsOutlined />
        </ListItemIcon>
        <ListItemText primary="Settings & Privacy" />
        {privacyExpanded ? (
          <ExpandMoreOutlined />
        ) : (
          <KeyboardArrowRightOutlined />
        )}
      </ListItemButton>
      <Collapse in={privacyExpanded} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{ backgroundColor: "#eee" }}
        >
          <ListItemButton
            onClick={() => window.open(USER_AGREEMENT_URL, "_blank")}
            selected={page == "user-agreement"}
          >
            <ListItemIcon>
              <ArticleOutlined />
            </ListItemIcon>
            <ListItemText primary="User Agreement" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/change-password")}
            selected={page == "change-password"}
          >
            <ListItemIcon>
              <LockOpenOutlined />
            </ListItemIcon>
            <ListItemText primary="Change Password" />
          </ListItemButton>

          <ListItemButton
            onClick={() => dispatch(clearSession())}
            selected={page == "logout"}
          >
            <ListItemIcon>
              <LogoutOutlined />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
};
export default SidebarMenu;
