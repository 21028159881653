import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import { useForm, Controller } from "react-hook-form";
import {
  NegotiationStatus,
  Project,
  ProjectResource,
} from "../../types/project";
import { TextField, Typography } from "@mui/material";
import { formatWithTimezone } from "../../utils/dateHelper";
import dayjs from "dayjs";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import {
  useAcceptProjectInvitationMutation,
  useUpdateBidMutation,
} from "../../services/project.service";
import { toast } from "react-toastify";

interface ModalBidProps {
  open: boolean;
  project: Project;
  bid: ProjectResource;
  onClose: () => void;
}

const ModalBid = ({ open, project, bid, onClose }: ModalBidProps) => {
  const [doUpdate, { isLoading: isUpdating }] = useUpdateBidMutation();
  const [doAcceptInvitation, { isLoading: isAccepting }] =
    useAcceptProjectInvitationMutation();

  const {
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    // defaultValues: {
    //   price: "",
    //   rate: "",
    //   max_hour: "",
    //   start_date: "",
    // },
  });

  React.useEffect(() => {
    if (open && project) {
      setValue("price", bid?.price || "");
      setValue("max_hour", bid?.max_hour || "");
      setValue("rate", bid?.rate || "");
      // setValue("start_date", "");
    }
  }, [open, bid, project]);

  const onSubmit = async (payload: any) => {
    try {
      let { rate, price, max_hour, start_date } = payload;
      if (!!rate) price = undefined;
      else {
        rate = undefined;
        max_hour = undefined;
      }
      let start_date_format = dayjs(start_date).format("YYYY-MM-DD HH:mm");
      const reqPayload = {
        rate,
        price,
        max_hour,
        start_date: start_date_format,
        timezone: project?.timezone,
        projectId: project?.id!,
      };
      if (bid?.status == NegotiationStatus.INVITED) {
        await doAcceptInvitation(reqPayload).unwrap();
        toast.success("Offer submitted");
      } else {
        // already bid, need to update
        await doUpdate(reqPayload).unwrap();
        toast.success("Offer updated");
      }
      onClose();
    } catch (err: any) {
      toast.error(err);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Counter Offer - Work ID # - {project.id}</DialogTitle>
        <DialogContent>
          {project?.type == "fixed" && (
            <Controller
              name="price"
              control={control}
              rules={{
                required: true,
                min: 1,
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Your Rate"
                  fullWidth
                  value={value?.toString()}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.price}
                  helperText={!!errors.price && "Price is invalid!"}
                />
              )}
            />
          )}
          {project?.type == "hourly" && (
            <>
              <Typography>
                Customer's Offer → {project.hourly_rate || 0} $
              </Typography>
              <Controller
                name="rate"
                control={control}
                rules={{
                  required: true,
                  min: 1,
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    label="Your Rate"
                    value={value?.toString()}
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.rate}
                    helperText={!!errors.rate && "Rate is invalid!"}
                  />
                )}
              />
              <Typography sx={{ mt: 1 }}>
                Customer's Estimated Hours →{" "}
                {project?.approximate_hours_to_complete} hr
              </Typography>
              <Controller
                name="max_hour"
                control={control}
                rules={{
                  required: true,
                  min: 1,
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    label={"Your Estimation"}
                    fullWidth
                    value={value?.toString()}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.max_hour}
                    helperText={!!errors.max_hour && "Hour is invalid!"}
                  />
                )}
              />
            </>
          )}
          <Typography sx={{ my: 1 }}>
            Customer's Start Date →{" "}
            {formatWithTimezone(project?.start_date, project?.timezone)}
          </Typography>
          <Controller
            name="start_date"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <DesktopDateTimePicker
                sx={{ mt: 1, width: "100%" }}
                onChange={onChange}
                value={!!value ? dayjs(value) : undefined}
                label="Your Start Date"
                minutesStep={5}
                onError={() => {
                  setError("start_date", {
                    type: "custom",
                    message: "Invalid",
                  });
                }}
                onAccept={() => clearErrors("start_date")}
                slotProps={{
                  textField: {
                    helperText: !!errors.start_date && "This field is required",
                    error: !!errors.start_date,
                  },
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions sx={{ mb: 1 }}>
          <Button onClick={onClose} disabled={isUpdating || isAccepting}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            autoFocus
            loading={isUpdating || isAccepting}
            variant={"contained"}
          >
            Offer
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalBid;
