import { useEffect, useState } from "react";
import {
  useReplyThreadCommentMutation,
  useSendThreadCommentMutation,
} from "../../services/forum.service";
import { toast } from "react-toastify";
import { ForumThread, ThreadComment } from "../../types/forum";
import {
  Avatar,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";
import { AttachFileOutlined, SendOutlined } from "@mui/icons-material";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useFilePicker } from "use-file-picker";

const InputComment = ({
  thread,
  comment,
  onFinish,
}: {
  thread: ForumThread;
  comment?: ThreadComment;
  onFinish?: () => void;
}) => {
  const user = useCurrentUser();
  const [doComment, { isLoading: commenting }] = useSendThreadCommentMutation();
  const [doReply, { isLoading: replying }] = useReplyThreadCommentMutation();
  const [commentText, setCommentText] = useState("");

  const [
    openFileSelector,
    { plainFiles, loading, errors: uploadErrors, clear },
  ] = useFilePicker({
    multiple: true,
    accept: [".jpeg", ".jpg", ".png", ".gif"],
    maxFileSize: 5,
    readFilesContent: false,
  });
  const [files, setFiles] = useState<Array<File>>([]);

  useEffect(() => {
    if (plainFiles?.length > 0) {
      setFiles(files.concat(plainFiles));
    }
  }, [plainFiles]);

  const handleSendComment = async () => {
    try {
      if (!comment) {
        await doComment({
          thread_id: thread?.id!,
          comment: commentText,
          files,
        }).unwrap();
      } else {
        await doReply({
          thread_id: thread?.id!,
          parent_id: comment.id,
          comment: commentText,
          files,
        }).unwrap();
      }
      if (onFinish) onFinish();
      setCommentText("");
      setFiles([]);
    } catch (err) {
      toast.error(err);
    }
  };
  return (
    <Paper sx={{ px: 2, py: 1 }}>
      <Stack direction={"row"} alignItems={"center"}>
        <Avatar src={user?.photo?.[0]?.original_url} sx={{ mr: 2 }} />
        <TextField
          sx={{ flex: 1 }}
          placeholder={
            !!comment
              ? `Reply to ${comment.owner.full_name}`
              : "Leave a comment"
          }
          value={commentText}
          color="secondary"
          onChange={(e) => setCommentText(e.target.value)}
        />
        <LoadingButton
          disabled={commentText.trim().length == 0}
          onClick={handleSendComment}
          loading={commenting || replying}
          size="small"
        >
          <SendOutlined />
        </LoadingButton>
        <LoadingButton onClick={() => openFileSelector()} size="small">
          <AttachFileOutlined />
        </LoadingButton>
        {!!comment && (
          <Button onClick={() => !!onFinish && onFinish()} size="small">
            Close
          </Button>
        )}
      </Stack>
      {files.length > 0 && (
        <List>
          {files.map((f) => (
            <ListItem
              secondaryAction={
                <Button
                  variant="text"
                  onClick={() =>
                    setFiles(files.filter((it) => it.name != f.name))
                  }
                >
                  ❌
                </Button>
              }
            >
              <ListItemText>{f.name}</ListItemText>
            </ListItem>
          ))}
        </List>
      )}
    </Paper>
  );
};
export default InputComment;
