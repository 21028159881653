import { NavLink, useLocation } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { ucFirstAllWords } from "../utils/helper";

function getBreadcrumbs(path = "") {
  const parts = path.split("/").filter((el) => Boolean(el));
  return parts.map((part, index) => {
    const to = "/" + parts.slice(0, index + 1).join("/");
    const page = part.includes("-")
      ? part
      : part.charAt(0).toUpperCase() + part.slice(1);
    return { to, page };
  });
}

const SiteBreadcrumb = (props: any) => {
  const location = useLocation();
  const breadcrumbs = getBreadcrumbs(location.pathname);
  const crumbs = breadcrumbs
    .filter(({ page }) => page != "Home")
    .map(({ to, page }) => {
      const pageName = ucFirstAllWords(page.replace("-", " "));
      return { name: pageName, to };
    });

  const homepage = { name: "Home", to: "/home" };
  const items = [homepage, ...crumbs];

  return (
    <Breadcrumbs separator="›" sx={{ mb: 2 }}>
      {items.map((it, idx) => {
        const last = idx === items.length - 1;
        return last ? (
          <Typography key={idx} sx={{ fontWeight: "500", letterSpacing: 0.5 }}>
            {it.name}
          </Typography>
        ) : (
          <Link
            key={idx}
            underline="hover"
            component={NavLink}
            // href={it.to}
            to={it.to}
            sx={{
              letterSpacing: 0.5,
            }}
          >
            {it.name}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default SiteBreadcrumb;
