import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import SelectSkills from "../account/SelectSkills";
import { useAppDispatch } from "../../hooks/store";
import { useSelector } from "react-redux";
import { currentSearchFilter, setFilterSkills } from "../../app/uiSlice";

interface ModalSearchFilterProps {
  open: boolean;
  onClose: () => void;
}

const ModalSearchFilter = ({ open, onClose }: ModalSearchFilterProps) => {
  const dispatch = useAppDispatch();
  const { skills } = useSelector(currentSearchFilter);
  const user = useCurrentUser();

  const mySkills = React.useMemo(
    () => user?.skills?.map((it) => it.id) || [],
    [user]
  );

  const [mode, setMode] = React.useState<"skill" | "none">("none");
  const [selectedSkills, setSelectedSkills] = React.useState<Array<number>>([]);

  const handleUpdate = () => {
    if (mode == "skill") {
      dispatch(setFilterSkills(selectedSkills));
    } else {
      dispatch(setFilterSkills([]));
    }
    onClose();
  };

  React.useEffect(() => {
    if (open) {
      if (skills.length > 0) {
        setMode("skill");
        setSelectedSkills(skills);
      } else {
        setMode("none");
        setSelectedSkills([]);
      }
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>Search Filter</DialogTitle>
      <DialogContent>
        <Stack direction={"row"} alignItems={"center"}>
          <Typography>Filter by: </Typography>
          <ToggleButtonGroup
            value={mode}
            exclusive
            size="small"
            color="secondary"
            sx={{ ml: 1 }}
            onChange={(e, value) => setMode(value)}
          >
            <ToggleButton value="none" sx={{ px: 2 }}>
              None
            </ToggleButton>
            <ToggleButton value="skill" sx={{ px: 2 }}>
              Skills
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        {mode == "skill" && (
          <Box sx={{ mt: 2 }}>
            <SelectSkills
              onChange={(ids) => setSelectedSkills(ids || [])}
              values={selectedSkills}
            />
            <Button variant="text" onClick={() => setSelectedSkills(mySkills)}>
              Select My Skills
            </Button>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ mb: 1 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleUpdate} variant="contained">
          Update Filter
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalSearchFilter;
