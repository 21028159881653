import {Language} from '../types/language';
import {baseApi} from './base';
import {TAG_TYPE_LANGUAGE} from './tags';

const apiWithTag = baseApi.enhanceEndpoints({addTagTypes: [TAG_TYPE_LANGUAGE]});

export const languageApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getLanguages: builder.query<
      Array<Language>,
      {sortField?: string; sortMode?: string | null; search?: string}
    >({
      query: ({sortField, sortMode, search}) => ({
        url: 'api/languages',
        method: 'GET',
        params: {
          sort:
            !!sortField && !!sortMode ? `${sortField}|${sortMode}` : `name|asc`,
          search,
        },
      }),
      providesTags: result => {
        const data = result;
        if (!data) return [{type: TAG_TYPE_LANGUAGE, id: 'PARTIAL-LIST'}];
        return [
          {type: TAG_TYPE_LANGUAGE, id: 'PARTIAL-LIST'},
          ...data?.map(({id}) => ({type: TAG_TYPE_LANGUAGE, id})),
        ];
      },
    }),
  }),
  overrideExisting: false,
});

export const {useGetLanguagesQuery} = languageApi;
