import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTitle } from "../../hooks/useTitle";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import dayjs from "dayjs";
import { AttachMoneyOutlined, RefreshOutlined } from "@mui/icons-material";
import { currencyFormat } from "../../utils/helper";
import { ProjectStatus } from "../../types/project";
import { useGetUserBalanceHistoriesQuery } from "../../services/user.service";
import { UserBalanceHistory } from "../../types/balanceHistory";

const StatusChip = ({ status }: { status?: string }) => {
  const style = { fontWeight: 500 };
  if (status == ProjectStatus.COMPLETED)
    return (
      <Chip
        label={"COMPLETED"}
        sx={style}
        color="info"
        variant="outlined"
        size="small"
      />
    );
  if (status == ProjectStatus.CANCELED)
    return (
      <Chip
        label={"CANCELLED"}
        sx={style}
        color="error"
        variant="outlined"
        size="small"
      />
    );
  else
    return (
      <Chip
        label={status}
        sx={style}
        color="default"
        variant="outlined"
        size="small"
      />
    );
};

const TableBalanceHistory = ({ balance }: { balance: UserBalanceHistory }) => {
  return (
    <TableRow>
      <TableCell>
        {dayjs(balance.created_at).format("MMM DD, YYYY hh:mm A")}
      </TableCell>
      <TableCell>{balance.notes}</TableCell>
      <TableCell align="right">
        {!!balance.debit ? (
          <Chip
            size="small"
            label={`- ${currencyFormat(balance.debit)}`}
            color="error"
            variant="outlined"
          />
        ) : (
          <Chip
            size="small"
            label={`${currencyFormat(balance.credit)}`}
            color="success"
            variant="outlined"
          />
        )}
      </TableCell>
    </TableRow>
  );
};

const BalanceHistoryPage = () => {
  const user = useCurrentUser();
  useTitle("Balance History");

  const {
    data: balances,
    isLoading,
    isFetching,
    refetch,
  } = useGetUserBalanceHistoriesQuery({});

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack alignItems={"flex-end"}>
            <Button startIcon={<RefreshOutlined />} onClick={refetch}>
              Refresh
            </Button>
          </Stack>
          {isFetching && <LinearProgress />}
          <Card>
            <CardHeader
              title={
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography sx={{ color: "#666" }}>
                    Your current balance:{" "}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      ml: 2,
                      color: "primary.main",
                      fontSize: 22,
                    }}
                  >
                    {currencyFormat(user?.balance_decrypt || 0)}
                  </Typography>
                </Stack>
              }
              avatar={<AttachMoneyOutlined />}
            />
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Time</TableCell>
                    <TableCell>Notes</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {balances?.data?.length == 0 && (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Typography color={"GrayText"}>
                          No balance history, yet.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {balances?.data?.map((it) => (
                    <TableBalanceHistory balance={it} />
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
export default BalanceHistoryPage;
