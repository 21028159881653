import * as React from "react";
import {
  useGetNotificationsQuery,
  useReadAllNotificationsMutation,
  useReadNotificationMutation,
} from "../services/notification.service";
import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  AttachMoneyOutlined,
  ChatBubbleOutlined,
  ChatOutlined,
  CheckCircleOutlineOutlined,
  CommentOutlined,
  InfoOutlined,
  NotificationsOutlined,
  PlaylistAddOutlined,
  RefreshOutlined,
  ReplyOutlined,
  StarOutlineOutlined,
  ThumbDownAltOutlined,
  ThumbUpAltOutlined,
  WorkOutline,
} from "@mui/icons-material";
import { useTitle } from "../hooks/useTitle";
import { useNavigate } from "react-router-dom";
import { Notification } from "../types/notification";
import { timeSince } from "../utils/dateHelper";
import { toast } from "react-toastify";

export function getNotificationIcon(type: string): React.ReactNode {
  if (
    [
      `App\\Notifications\\ProjectBudgetApprovalNotification`,
      `App\\Notifications\\ProjectCompletedNotification`,
      `App\\Notifications\\ProjectResourcesApprovedNotification`,
      // `App\\Notifications\\ProjectResourcesHiredNotification`,
      `App\\Notifications\\ProjectResourcesCanceledNotification`,
    ].includes(type)
  )
    return <WorkOutline />;
  else if (type == `App\\Notifications\\ProjectResourcesHiredNotification`)
    return <CheckCircleOutlineOutlined color="success" />;
  else if (type == `App\\Notifications\\InvitedProjectNotification`)
    return <PlaylistAddOutlined color="info" />;
  else if (type == `App\\Notifications\\ReportProgressApprovedNotification`)
    return <ThumbUpAltOutlined />;
  else if (type == `App\\Notifications\\ReportProgressRejectedNotification`)
    return <ThumbDownAltOutlined color="error" />;
  else if (type == `App\\Notifications\\ReviewNotification`)
    return <StarOutlineOutlined />;
  else if (
    [
      `App\\Notifications\\WithdrawalRejectedNotification`,
      `App\\Notifications\\WithdrawalCompletedNotification`,
      `App\\Notifications\\BonusNotification`,
    ].includes(type)
  )
    return <AttachMoneyOutlined />;
  else if ([`App\\Notifications\\ThreadCommentNotification`].includes(type))
    return <ReplyOutlined />;
  else if ([`App\\Notifications\\MessageNotification`].includes(type))
    return <CommentOutlined />;
  else return <NotificationsOutlined />;
}

const NotificationItem = ({
  item,
  onClick,
}: {
  item: Notification;
  onClick: (item: Notification) => void;
}) => {
  //   const project_id =
  //     item.data.project_id || item.data.message?.chat?.project_id;
  let body = item.data.body;

  if (item.type == "App\\Notifications\\MessageNotification")
    body = `[${item.data.message?.user?.full_name}] ${item.data.message?.body}`;
  else if (item.type == "App\\Notifications\\ThreadCommentNotification")
    body = body + ` : ${item.data.comment.comment}`;

  return (
    <>
      <ListItem
        secondaryAction={
          <Typography variant="caption" sx={{ mt: 1 }}>
            {timeSince(item.created_at)}
          </Typography>
        }
      >
        <ListItemIcon>{getNotificationIcon(item.type)}</ListItemIcon>
        <ListItemText
          primary={
            <Link component="button" onClick={() => onClick(item)}>
              {item.data.title}
            </Link>
          }
          primaryTypographyProps={{
            fontSize: 16,
            fontWeight: !!item.read_at ? undefined : "600",
          }}
          secondary={
            <Box>
              <Typography variant="body2">{body || "n/a"}</Typography>
              {/* <Typography variant="caption" sx={{ mt: 1 }}>
                {timeSince(item.created_at)}
              </Typography> */}
            </Box>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

const NotificationsPage = () => {
  const navigate = useNavigate();
  useTitle("Notifications");

  const {
    data: notifications,
    isFetching,
    refetch,
  } = useGetNotificationsQuery();
  const [doRead] = useReadNotificationMutation();
  const [doReadAll, { isLoading: isReadingAll }] =
    useReadAllNotificationsMutation();

  const handleRead = React.useCallback((item: Notification) => {
    const project_id =
      item.data.project_id || item.data.message?.chat?.project_id;

    if (!!item.data.thread_id) {
      navigate("/live-feed/" + item.data.thread_id);
    } else if (!!project_id) {
      navigate("/works/" + project_id);
    }

    try {
      doRead(item.id);
    } catch (err) {
      toast.error(err);
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        <Stack alignItems={"flex-end"}>
          <Button startIcon={<RefreshOutlined />} onClick={refetch}>
            Refresh
          </Button>
        </Stack>
        {isFetching && <LinearProgress />}
        <Paper sx={{ p: 2 }}>
          <List dense={true} disablePadding>
            {notifications?.data?.map((notif) => (
              <NotificationItem item={notif} onClick={handleRead} />
            ))}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default NotificationsPage;
