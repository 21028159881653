import * as React from "react";
import {
  ProjectResource,
  ReportProgress,
  ReportProgressStatus,
} from "../types/project";
import { elapsed } from "../utils/dateHelper";

export const useJobMeta = (job: ProjectResource) => {
  const sorted = job.report_progresses
    ?.slice()
    .sort((a: ReportProgress, b: ReportProgress) => {
      return new Date(b.check_in).getTime() - new Date(a.check_in).getTime();
    });
  const currentSession = sorted?.[0];
  const lastCheckout = sorted?.find((it) => !!it.check_out)?.check_out;

  // total sec , without current session
  const totalSec =
    sorted?.reduce((acc, it) => {
      if (!!it.check_in && !!it.check_out) {
        const sec =
          new Date(it.check_out).getTime() - new Date(it.check_in).getTime();
        return acc + sec / 1000;
      } else {
        return acc;
      }
    }, 0) || 0;

  if (!currentSession) {
    return {
      currentSession,
      isCheckedIn: false,
      isCheckedOut: true,
      canFinish: false,
      firstCheckIn: undefined,
      lastCheckout,
      totalSec,
    };
  }

  const firstCheckIn = sorted?.[sorted?.length - 1]?.check_in;
  const isCheckedIn = !!currentSession?.check_in && !currentSession?.check_out;
  const isCheckedOut = !!currentSession?.check_out;
  const canFinish =
    isCheckedOut && currentSession.status != ReportProgressStatus.APPROVED;

  return {
    currentSession,
    isCheckedIn,
    isCheckedOut,
    canFinish,
    firstCheckIn,
    lastCheckout,
    totalSec,
  };
};
