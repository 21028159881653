import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useCreateFeedbackMutation } from "../../services/feedback.service";
import { Box, Grid, Paper, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const SendFeedbackPage = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const type = params.get("type");
  const info = params.get("info");
  const thread_id = params.get("thread_id");

  const user = useCurrentUser();
  const [createFeedback, { isLoading: isCreating }] =
    useCreateFeedbackMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm({
    defaultValues: {
      type: type || "",
      message: "",
      info: info || "",
      thread_id: thread_id || undefined,
    },
  });

  const onSubmit = async (payload: any) => {
    try {
      await createFeedback(payload).unwrap();
      toast.success("Thank you for your report. We'll investigate promptly.");
      navigate("/live-feed");
    } catch (err: any) {
      toast.error(err);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Paper sx={{ p: 2 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography>Reporter: {user?.full_name}</Typography>
              <Controller
                name="type"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    label="Type"
                    value={value}
                    fullWidth
                    disabled={true}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.type}
                    helperText={!!errors.type && "Invalid value"}
                  />
                )}
              />
              <Controller
                name="info"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    label="Info"
                    value={value}
                    fullWidth
                    disabled={true}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.info}
                    helperText={!!errors.info && "Invalid value"}
                  />
                )}
              />
              <Controller
                name="message"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    label="A Brief Description of The Issue"
                    value={value}
                    multiline={true}
                    minRows={5}
                    onChange={onChange}
                    onBlur={onBlur}
                    fullWidth
                    error={!!errors.message}
                    helperText={!!errors.message && "Invalid value"}
                  />
                )}
              />
              <LoadingButton
                loading={isCreating}
                type="submit"
                variant="contained"
                sx={{ mt: 1 }}
              >
                {"Submit Report"}
              </LoadingButton>
            </Box>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default SendFeedbackPage;
