import { ArrowRightAltSharp } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import dayjs, { OpUnitType } from "dayjs";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetTotalEarningByDateQuery,
  useGetTotalHourByDateQuery,
  useGetTotalJobByDateQuery,
} from "../../services/report.service";
import { currencyFormat } from "../../utils/helper";

const dateSelect = [
  { label: "This Week", key: "week", value: "week" },
  { label: "This Month", key: "month", value: "month" },
  { label: "This Year", key: "year", value: "year" },
];

const StatisticCard = (props: {
  title: string;
  value: string;
  url?: string;
  color?: string;
}) => {
  const { title, value, url, color } = props;
  const navigate = useNavigate();
  return (
    <Card
    //   sx={{
    //     background: `linear-gradient(${colors.green[100]}44, #fff)`,
    //   }}
    >
      <CardContent>
        <Typography
          sx={{ fontSize: 16 }}
          color={color ?? "text.secondary"}
          gutterBottom
        >
          {title}
        </Typography>
        <Typography variant="h4" component="div">
          {value}
        </Typography>
      </CardContent>
      {url && (
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button
            size="small"
            onClick={() => navigate(url)}
            endIcon={<ArrowRightAltSharp />}
          >
            More detail
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

const WorkStatisticView = () => {
  const [timeFilter, setTimeFilter] = React.useState(dateSelect[0].value);

  const date = React.useMemo(() => {
    return {
      start_date: dayjs()
        .startOf(timeFilter as OpUnitType)
        .format("YYYY-MM-DD"),
      end_date: dayjs()
        .endOf(timeFilter as OpUnitType)
        .format("YYYY-MM-DD"),
    };
  }, [timeFilter]);

  const { data: totalJob, isFetching: loadingStat } =
    useGetTotalJobByDateQuery(date);
  const { data: totalHour } = useGetTotalHourByDateQuery(date);
  const { data: totalEarning } = useGetTotalEarningByDateQuery(date);

  return (
    <Box>
      {/* <FormControl variant="standard" sx={{ mb: 1 }}>
        <Select
          size="small"
          value={timeFilter}
          onChange={(e) => setTimeFilter(e.target.value)}
        >
          {dateSelect.map((it) => (
            <MenuItem value={it.value}>{it.label}</MenuItem>
          ))}
        </Select>
      </FormControl> */}
      <ToggleButtonGroup
        color="primary"
        value={timeFilter}
        size="small"
        exclusive
        sx={{ mb: 1 }}
        onChange={(e, value) => setTimeFilter(value)}
      >
        {dateSelect.map((it) => (
          <ToggleButton value={it.value} sx={{ px: 2 }}>
            {it.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {loadingStat && <LinearProgress />}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <StatisticCard
            title="Completed Work"
            value={Number(totalJob?.total || 0).toString()}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <StatisticCard
            title="Total Hours"
            value={Number(totalHour?.total_hour || 0).toFixed(1)}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <StatisticCard
            title="Earnings"
            value={currencyFormat(totalEarning?.total || 0)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default WorkStatisticView;
