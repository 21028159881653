import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { ForumThread, ThreadComment } from "../../types/forum";
import { decode } from "html-entities";
import {
  DeleteOutline,
  DownloadOutlined,
  EditOutlined,
  FlagOutlined,
  PersonOutlineOutlined,
  ReplyOutlined,
} from "@mui/icons-material";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import { useState } from "react";
import InputComment from "./InputComment";
import EditComment from "./EditComment";
import { createSearchParams, useNavigate } from "react-router-dom";
import ClickableAvatar from "../../components/ClickableAvatar";

const CardComment = ({
  thread,
  comment,
  onDelete,
}: {
  thread: ForumThread;
  comment: ThreadComment;
  onDelete: (comment: ThreadComment) => void;
}) => {
  const navigate = useNavigate();
  const user = useCurrentUser();
  const [deleteModal, setDeleteModal] = useState<{
    open: boolean;
  }>({
    open: false,
  });
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [showCommentEdit, setShowCommentEdit] = useState(false);

  const closeDeleteModal = () =>
    setDeleteModal({ ...deleteModal, open: false });

  const handleDelete = async () => {
    onDelete(comment);
    closeDeleteModal();
  };

  return (
    <Card sx={{ mt: 1 }}>
      <CardHeader
        avatar={<ClickableAvatar talent={comment?.owner} />}
        title={<Typography>{comment.owner?.full_name}</Typography>}
        subheader={`${dayjs(comment?.created_at).format(
          "MMM DD YYYY, hh:mm A"
        )}`}
        subheaderTypographyProps={{ fontSize: 12 }}
      />
      <CardContent
        sx={{
          whiteSpace: "pre-wrap",
          overflowWrap: "break-word",
        }}
      >
        {showCommentEdit ? (
          <Box sx={{ mb: 2 }}>
            <EditComment
              thread={thread}
              comment={comment}
              onFinish={() => setShowCommentEdit(false)}
            />
          </Box>
        ) : (
          <Typography variant="body1">{decode(comment.comment)}</Typography>
        )}

        {(comment?.documents?.length || 0) > 0 && (
          <ImageList cols={6} rowHeight={400} gap={20}>
            {comment?.documents?.map((item) => (
              <ImageListItem key={item.id}>
                <img
                  src={`${item.original_url}`}
                  alt={item.file_name}
                  loading="lazy"
                />
                <ImageListItemBar
                  position="top"
                  actionIcon={
                    <IconButton
                      onClick={() => window.open(item.original_url, "_blank")}
                      color="warning"
                    >
                      <DownloadOutlined />
                    </IconButton>
                  }
                />
              </ImageListItem>
            ))}
            || <></>
          </ImageList>
        )}
        <Stack direction={"row"} justifyContent={"flex-end"}>
          {!comment?.parent_id && (
            <Button
              startIcon={<ReplyOutlined />}
              size="small"
              sx={{ mr: 2 }}
              onClick={() => setShowCommentInput(!showCommentInput)}
            >
              Reply
            </Button>
          )}
          {user?.id == comment?.owner?.id && (
            <>
              <Button
                startIcon={<EditOutlined />}
                size="small"
                sx={{ mr: 2 }}
                onClick={() => setShowCommentEdit(true)}
              >
                Edit
              </Button>
              <Button
                startIcon={<DeleteOutline />}
                size="small"
                sx={{ mr: 2 }}
                color="error"
                onClick={() => setDeleteModal({ open: true })}
              />
            </>
          )}
          <IconButton
            onClick={() =>
              navigate({
                pathname: "/live-feed/send-feedback",
                search: createSearchParams({
                  type: "Report a Comment",
                  info: `User:${comment.owner.full_name} CommentID:${comment.id}`,
                }).toString(),
              })
            }
          >
            <FlagOutlined color="error" fontSize="small" />
          </IconButton>
        </Stack>
        {showCommentInput && (
          <InputComment
            thread={thread}
            comment={comment}
            onFinish={() => setShowCommentInput(false)}
          />
        )}
        {comment.childs?.map((child) => (
          <CardComment
            thread={thread}
            comment={child}
            onDelete={() => onDelete(child)}
          />
        ))}
      </CardContent>

      <ConfirmDeleteModal
        open={deleteModal.open}
        onClose={closeDeleteModal}
        onDelete={handleDelete}
        message={`Delete Comment ?`}
      />
    </Card>
  );
};
export default CardComment;
