import dayjs from "dayjs";
import { Project } from "../types/project";
import { baseApi, QueryResponse } from "./base";
import { TAG_TYPE_JOB, TAG_TYPE_PROJECT } from "./tags";

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_PROJECT, TAG_TYPE_JOB],
});

export const projectApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<
      QueryResponse<Project>,
      {
        page?: number;
        pageSize?: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
        load?: string;
        skills?: Array<number>;
        verticals?: Array<number>;
        distance?: string;
      }
    >({
      query: ({
        page = 0,
        pageSize = 200,
        sortField,
        sortMode,
        search,
        load = "customer,location,company,skills,resources",
        skills,
        verticals,
        distance,
      }) => ({
        url: "api/projects",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `created_at|desc`,
          search,
          load,
          skills,
          verticals,
          distance,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE_PROJECT, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE_PROJECT, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE_PROJECT, id })),
        ];
      },
    }),
    getProjectById: builder.query<Project, number>({
      query: (id) => ({
        url: `api/projects/${id}`,
        method: "GET",
        params: {
          load: "company,customer,manager,location,skills,certifications,tasks,resources,documents,verticals,reviews,chats,chats.participants",
        },
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE_PROJECT, id }],
    }),
    applyProject: builder.mutation<
      Project,
      {
        projectId: number;
        rate?: number;
        price?: number;
        max_hour?: number;
        start_date?: string;
        timezone?: string;
      }
    >({
      query: ({ projectId, rate, price, max_hour, start_date, timezone }) => {
        return {
          url: `api/user/project/${projectId}/apply`,
          method: "POST",
          body: {
            rate,
            price,
            max_hour,
            start_date,
            timezone,
          },
        };
      },
      invalidatesTags: (result, error, payload) => [
        { type: TAG_TYPE_PROJECT, id: payload.projectId },
        { type: TAG_TYPE_JOB },
      ],
    }),
    acceptProjectInvitation: builder.mutation<
      Project,
      {
        projectId: number;
        rate?: number;
        price?: number;
        max_hour?: number;
        start_date?: string;
        timezone?: string;
      }
    >({
      query: ({ projectId, rate, price, max_hour, start_date, timezone }) => {
        return {
          url: `api/user/project/${projectId}/accepted`,
          method: "PUT",
          body: {
            rate,
            price,
            max_hour,
            start_date,
            timezone,
          },
        };
      },
      invalidatesTags: (result, error, payload) => [
        { type: TAG_TYPE_PROJECT, id: payload.projectId },
        { type: TAG_TYPE_JOB },
      ],
    }),
    updateBid: builder.mutation<
      Project,
      {
        projectId: number;
        rate?: number;
        price?: number;
        max_hour?: number;
        start_date?: string;
        timezone?: string;
      }
    >({
      query: ({ projectId, rate, price, max_hour, start_date, timezone }) => {
        return {
          url: `api/user/bids/${projectId}`,
          method: "PUT",
          body: {
            rate,
            price,
            max_hour,
            start_date,
            timezone,
          },
        };
      },
      invalidatesTags: (result, error, payload) => [
        { type: TAG_TYPE_PROJECT, id: payload.projectId },
        // {type: TAG_TYPE_PROJECT, id: 'PARTIAL-LIST'},
        { type: TAG_TYPE_JOB },
      ],
    }),
    withdrawBid: builder.mutation<Project, number>({
      query: (projectId) => {
        return {
          url: `api/user/bids/${projectId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, projectId) => [
        { type: TAG_TYPE_PROJECT, id: projectId },
        // {type: TAG_TYPE_PROJECT, id: 'PARTIAL-LIST'},
        { type: TAG_TYPE_JOB },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProjectsQuery,
  useGetProjectByIdQuery,
  useApplyProjectMutation,
  useAcceptProjectInvitationMutation,
  useUpdateBidMutation,
  useWithdrawBidMutation,
} = projectApi;
