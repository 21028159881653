import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import { nl2br } from "../../utils/helper";
import { decode } from "html-entities";
import {
  AssignmentOutlined,
  ExpandMoreOutlined,
  LocationOnOutlined,
} from "@mui/icons-material";
import { Project, ProjectResource, ProjectStatus } from "../../types/project";
import MapPreview from "../../components/MapPreview";
import { useMemo } from "react";
import { formatWithTimezone, timeSince } from "../../utils/dateHelper";

const ProjectDetailView = ({ project }: { project: Project }) => {
  const [lng, lat] = useMemo(() => {
    if (!!project?.customer) {
      return project?.customer?.coordinate?.coordinates || [];
    } else if (!!project?.location) {
      return project?.location?.coordinate?.coordinates || [];
    } else return [];
  }, [project]);

  return (
    <Grid container spacing={2}>
      {project?.status == ProjectStatus.CANCELED && (
        <Grid item xs={12}>
          <Alert severity="error" variant="standard">
            Project Cancelled. Reason: {project?.canceled_reason || "n/a"}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack direction={"row"} alignItems="flex-start">
          <Stack direction="column" flex={1}>
            <Stack direction="row">
              <Typography variant="h5">{`#${project?.id || ""} ${
                project?.title || ""
              }`}</Typography>
            </Stack>
            <Typography variant="body2">{`Posted ${timeSince(
              project?.created_at
            )}`}</Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12} lg={8}>
        {/* customer info, contract */}
        <Grid container spacing={1}>
          <Grid item xs={12} lg={4}>
            <Card>
              <CardHeader title="Customer" />
              <CardContent>
                <Typography variant="body1" sx={{ mr: 1 }}>
                  {project?.company?.name}
                </Typography>
                <Stack direction={"row"}>
                  <Rating
                    value={project?.company?.total_rating || 0}
                    readOnly
                    precision={0.5}
                    size="small"
                  />
                  <Typography variant="body2">
                    ({Number(project?.company?.total_rating || 0).toFixed(1)})
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card>
              <CardHeader title="Contract Type" />
              <CardContent>
                <Typography variant="body2">
                  {project?.type == "hourly" ? "Hourly" : "Fixed Price"}
                </Typography>
                <Typography variant="body1" fontWeight={500}>
                  {project?.type == "hourly"
                    ? project.hourly_rate
                      ? ` $${project.hourly_rate}/hr`
                      : "\nPlease offer on this work"
                    : project.total_pay
                    ? ` $${project.total_pay}`
                    : "\nPlease offer on this work"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card>
              <CardHeader title="Start Date" />
              <CardContent>
                <Typography variant="body1">
                  {formatWithTimezone(
                    project.start_date,
                    project.timezone,
                    "dddd, MMMM D"
                  )}
                </Typography>
                <Typography variant="body2">
                  {formatWithTimezone(
                    project.start_date,
                    project.timezone,
                    "h:mmA z"
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* project info */}
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Scope of Work / Instructions" />
          <CardContent>
            <Typography variant="body2">
              {nl2br(decode(project?.description))}
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Tasks" />
          <CardContent>
            {project?.tasks?.length == 0 && (
              <Typography variant="caption">No tasks specified</Typography>
            )}
            {project?.tasks?.map((it, idx) => (
              <Accordion key={idx}>
                <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                  <AssignmentOutlined sx={{ mr: 2 }} />
                  <Typography>{it.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="caption">
                    {decode(it.description) || "n/a"}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Requirements" />
          <CardContent>
            <Typography variant="body2" fontWeight={500}>
              Skills
            </Typography>
            <Box sx={{ mt: 1 }}>
              {project?.skills?.length == 0 ? (
                <Typography sx={{ fontSize: 12, color: "text.disabled" }}>
                  Not Required
                </Typography>
              ) : (
                project?.skills?.map((it) => (
                  <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                ))
              )}
            </Box>
            <Typography sx={{ mt: 2 }} variant="body2" fontWeight={500}>
              Certifications
            </Typography>
            <Box sx={{ mt: 1 }}>
              {project?.certifications?.length == 0 ? (
                <Typography sx={{ fontSize: 12, color: "text.disabled" }}>
                  Not Required
                </Typography>
              ) : (
                project?.certifications?.map((it) => (
                  <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                ))
              )}
            </Box>
          </CardContent>
          <Divider />
          <CardContent>
            <Typography variant="body1">
              Photos of Completed Work →{" "}
              {!!project?.is_photo_required ? "Required" : "Not Required"}
              <br />
              Final Notes of Completed Work →{" "}
              {!!project?.is_final_notes_required ? "Required" : "Not Required"}
              <br />
              Background Check →{" "}
              {!!project?.background_check_required
                ? "Required"
                : "Not Required"}
              <br />
              Drug Test →{" "}
              {!!project?.drug_test_required ? "Required" : "Not Required"}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Card>
          <CardHeader title={"Work Site"} />
          <CardContent sx={{ p: 0 }}>
            <MapPreview lat={lat} lng={lng} />
          </CardContent>
          <CardContent>
            <Stack direction={"row"}>
              <LocationOnOutlined sx={{ mr: 1 }} />
              {!!project?.location && (
                <Typography variant="body1">
                  {project?.location.city} {project?.location.zip}
                </Typography>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export default ProjectDetailView;
