import { ROLE_TALENT_ID } from "../configs/api";
import { Talent, User } from "../types/users";
import { baseApi, QueryResponse } from "./base";
import { TAG_TYPE_USER } from "./tags";
import { Skill } from "../types/skill";
import { Language } from "../types/language";
import { Certification, UserCertification } from "../types/certification";
import { UserEducation } from "../types/education";
import { UserWorkExperience } from "../types/workExperience";
// import {Image} from 'react-native-image-crop-picker';
// import {Platform} from 'react-native';
import { UserBalanceHistory } from "../types/balanceHistory";
import { BusinessVertical } from "../types/vertical";
import { isUndefined } from "lodash";

const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [TAG_TYPE_USER] });

// type UpdateUserPayload = Pick<
//   Talent,
//   | 'first_name'
//   | 'last_name'
//   | 'phone_number'
//   | 'email'
//   | 'bio'
//   | 'physical_address_1'
//   | 'physical_address_2'
//   | 'city'
//   | 'state'
//   | 'zip'
//   | 'linkedin'
//   | 'website'
//   | 'instagram'
//   | 'facebook'
//   | 'commute_radius'
//   | 'min_pay'
//   | 'max_pay'
// > & {
//   country: number;
//   skills: Array<number>;
//   languages: Array<number>;
//   photo?: Image;
// };

export function createUpdateUserPayload(user: Talent, input: any) {
  const payload = { ...user, ...input };
  delete payload.photo;
  delete payload.created_at;
  delete payload.updated_at;
  delete payload.deleted_at;
  delete payload.stripe_id;
  delete payload.role_id;
  delete payload.stripe_account_id;
  delete payload.id;
  delete payload.talent;
  delete payload.status;
  delete payload.total_rating;
  delete payload.security_questions;
  delete payload.security_answer;
  delete payload.balance_str;
  delete payload.balance_decrypt;
  delete payload.device_token;
  delete payload.coordinate;
  delete payload.country_id;

  payload.country = isUndefined(input.country)
    ? user.country_id
    : input.country;

  payload.work_status = isUndefined(input.work_status)
    ? user.talent?.work_status
    : input.work_status;

  payload.is_valid_us = isUndefined(input.is_valid_us)
    ? user.talent?.is_valid_us
    : input.is_valid_us;

  payload.avail_monday = isUndefined(input.avail_monday)
    ? user.talent?.avail_monday
    : input.avail_monday;
  payload.avail_tuesday = isUndefined(input.avail_tuesday)
    ? user.talent?.avail_tuesday
    : input.avail_tuesday;
  payload.avail_wednesday = isUndefined(input.avail_wednesday)
    ? user.talent?.avail_wednesday
    : input.avail_wednesday;
  payload.avail_thursday = isUndefined(input.avail_thursday)
    ? user.talent?.avail_thursday
    : input.avail_thursday;
  payload.avail_friday = isUndefined(input.avail_friday)
    ? user.talent?.avail_friday
    : input.avail_friday;
  payload.avail_saturday = isUndefined(input.avail_saturday)
    ? user.talent?.avail_saturday
    : input.avail_saturday;
  payload.avail_sunday = isUndefined(input.avail_sunday)
    ? user.talent?.avail_sunday
    : input.avail_sunday;

  payload.skills = user.skills.map((it) => it.id);
  payload.languages = user.languages.map((it) => it.id);

  // check if this is for update vertical
  if (isUndefined(input.business_verticals))
    payload.business_verticals = user.business_verticals.map((it) => it.id);

  return payload;
}

export const userApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    updateUser: builder.mutation<Talent, any>({
      query: (body) => {
        // if (!body.photo) delete body.photo;
        // let formData = new FormData();
        // formData.append('_method', 'patch');
        // formData.append('role', ROLE_TALENT_ID.toString());
        // Object.entries(body).forEach(([key, value]) => {
        //   if (typeof value == 'number') formData.append(key, value.toString());
        //   else if (Array.isArray(value)) {
        //     // formData.append(key, JSON.stringify(value));
        //     value.forEach((it: any) => {
        //       formData.append(`${key}[]`, it);
        //     });
        //   } else if (key == 'photo') {
        //     // if (isFile(value)) formData.append(key, value as any);
        //   } else if (value != null) formData.append(key, value as string);
        // });
        // return {
        //   url: `api/user`,
        //   method: 'POST',
        //   body: formData,
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'multipart/form-data',
        //   },
        // };
        return {
          url: `api/user`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE_USER, id: "me" },
      ],
    }),
    //
    getUserSkills: builder.query<
      Array<Skill>,
      { sortField?: string; sortMode?: string | null; search?: string }
    >({
      query: ({ sortField, sortMode }) => ({
        url: "api/user/skills",
        method: "GET",
        params: {
          sort:
            !!sortField && !!sortMode ? `${sortField}|${sortMode}` : `name|asc`,
        },
      }),
      providesTags: (result) => {
        return [{ type: TAG_TYPE_USER, id: "skill" }];
      },
    }),
    updateUserSkills: builder.mutation<void, Array<number>>({
      query: (body) => {
        return {
          url: `api/user/skills`,
          method: "POST",
          body: {
            skills: body,
          },
        };
      },
      invalidatesTags: () => [{ type: TAG_TYPE_USER }], //reset all
    }),
    //
    getUserLanguages: builder.query<
      Array<Language>,
      { sortField?: string; sortMode?: string | null }
    >({
      query: ({ sortField, sortMode }) => ({
        url: "api/user/languages",
        method: "GET",
        params: {
          sort:
            !!sortField && !!sortMode ? `${sortField}|${sortMode}` : `name|asc`,
        },
      }),
      providesTags: (result) => {
        return [{ type: TAG_TYPE_USER, id: "language" }];
      },
    }),
    updateUserLanguages: builder.mutation<void, Array<number>>({
      query: (body) => {
        return {
          url: `api/user/languages`,
          method: "POST",
          body: {
            languages: body,
          },
        };
      },
      invalidatesTags: () => [{ type: TAG_TYPE_USER }], //reset all
    }),
    //
    getUserCertifications: builder.query<
      Array<UserCertification>,
      { sortField?: string; sortMode?: string | null }
    >({
      query: ({ sortField, sortMode }) => ({
        url: "api/user/user-certifications",
        method: "GET",
        params: {
          sort:
            !!sortField && !!sortMode ? `${sortField}|${sortMode}` : `name|asc`,
          load: "certification",
        },
      }),
      providesTags: (result) => {
        return [{ type: TAG_TYPE_USER, id: "certification" }];
      },
    }),
    addUserCertification: builder.mutation<
      UserCertification,
      Omit<UserCertification, "id">
    >({
      query: (body) => {
        return {
          url: `api/user/user-certifications`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: () => [{ type: TAG_TYPE_USER }], //reset all
    }),
    updateUserCertification: builder.mutation<
      UserCertification,
      Partial<UserCertification> & Pick<UserCertification, "id">
    >({
      query: (body) => {
        return {
          url: `api/user/user-certifications/${body.id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: () => [{ type: TAG_TYPE_USER }], //reset all
    }),
    deleteUserCertification: builder.mutation<void, number>({
      query: (id) => {
        return {
          url: `api/user/user-certifications/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: () => [{ type: TAG_TYPE_USER }], //reset all
    }),
    //
    getUserEducations: builder.query<
      Array<UserEducation>,
      { sortField?: string; sortMode?: string | null }
    >({
      query: ({ sortField, sortMode }) => ({
        url: "api/user/user-educations",
        method: "GET",
        params: {
          sort:
            !!sortField && !!sortMode ? `${sortField}|${sortMode}` : `name|asc`,
        },
      }),
      providesTags: (result) => {
        return [{ type: TAG_TYPE_USER, id: "education" }];
      },
    }),
    addUserEducation: builder.mutation<
      UserEducation,
      Omit<UserEducation, "id">
    >({
      query: (body) => {
        return {
          url: `api/user/user-educations`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: () => [{ type: TAG_TYPE_USER }], //reset all
    }),
    updateUserEducation: builder.mutation<
      UserEducation,
      Partial<UserEducation> & Pick<UserEducation, "id">
    >({
      query: (body) => {
        return {
          url: `api/user/user-educations/${body.id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: () => [{ type: TAG_TYPE_USER }], //reset all
    }),
    deleteUserEducation: builder.mutation<void, number>({
      query: (id) => {
        return {
          url: `api/user/user-educations/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: () => [{ type: TAG_TYPE_USER }], //reset all
    }),
    //
    getUserWorkExperiences: builder.query<
      Array<UserWorkExperience>,
      { sortField?: string; sortMode?: string | null }
    >({
      query: ({ sortField, sortMode }) => ({
        url: "api/user/user-work-experiences",
        method: "GET",
        params: {
          sort:
            !!sortField && !!sortMode ? `${sortField}|${sortMode}` : `name|asc`,
          load: "country",
        },
      }),
      providesTags: (result) => {
        return [{ type: TAG_TYPE_USER, id: "work-experience" }];
      },
    }),
    addUserWorkExperience: builder.mutation<
      UserWorkExperience,
      Omit<UserWorkExperience, "id">
    >({
      query: (body) => {
        return {
          url: `api/user/user-work-experiences`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: () => [{ type: TAG_TYPE_USER }], //reset all
    }),
    updateUserWorkExperience: builder.mutation<
      UserWorkExperience,
      Partial<UserWorkExperience> & Pick<UserWorkExperience, "id">
    >({
      query: (body) => {
        return {
          url: `api/user/user-work-experiences/${body.id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: () => [{ type: TAG_TYPE_USER }], //reset all
    }),
    deleteUserWorkExperience: builder.mutation<void, number>({
      query: (id) => {
        return {
          url: `api/user/user-work-experiences/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: () => [{ type: TAG_TYPE_USER }], //reset all
    }),
    //
    changePassword: builder.mutation<
      User,
      { old_password: string; password: string }
    >({
      query: (body) => {
        return {
          url: `api/user/change-password`,
          method: "POST",
          body,
        };
      },
    }),
    // DISABLE DEBUGGER NETWORK-INSPECT WHEN TESTING UPLOAD!!!
    changePhoto: builder.mutation<User, File>({
      query: (photo) => {
        let formData = new FormData();
        formData.append("photo", photo);
        return {
          url: `api/user/change-photo`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: () => [{ type: TAG_TYPE_USER }], //reset all
    }),
    getUserBalanceHistories: builder.query<
      QueryResponse<UserBalanceHistory>,
      { sortField?: string; sortMode?: string | null }
    >({
      query: ({ sortField, sortMode }) => ({
        url: "api/user/balance-histories",
        method: "GET",
        params: {
          page: 1,
          per_page: 100,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `created_at|desc`,
          load: "reference_model,createdBy,project,project.company,model",
        },
      }),
      providesTags: (result) => {
        return [{ type: TAG_TYPE_USER, id: "balance-history" }];
      },
    }),
    completeProfile: builder.mutation<User, void>({
      query: () => {
        return {
          url: `api/user/complete-profile`,
          method: "PUT",
        };
      },
      invalidatesTags: () => [{ type: TAG_TYPE_USER }], //reset all
    }),
    updateDeviceToken: builder.mutation<User, string>({
      query: (device_token) => {
        return {
          url: `api/user/device-token`,
          method: "PUT",
          body: {
            device_token,
          },
        };
      },
      // invalidatesTags: () => [{type: TAG_TYPE_USER}], //reset all
    }),
  }),
  overrideExisting: false,
});

export const {
  useUpdateUserMutation,
  useGetUserSkillsQuery,
  useUpdateUserSkillsMutation,
  useGetUserLanguagesQuery,
  useUpdateUserLanguagesMutation,
  useGetUserCertificationsQuery,
  useAddUserCertificationMutation,
  useUpdateUserCertificationMutation,
  useDeleteUserCertificationMutation,
  useGetUserEducationsQuery,
  useAddUserEducationMutation,
  useUpdateUserEducationMutation,
  useDeleteUserEducationMutation,
  useGetUserWorkExperiencesQuery,
  useAddUserWorkExperienceMutation,
  useUpdateUserWorkExperienceMutation,
  useDeleteUserWorkExperienceMutation,
  useChangePasswordMutation,
  useChangePhotoMutation,
  useGetUserBalanceHistoriesQuery,
  useCompleteProfileMutation,
  useUpdateDeviceTokenMutation,
} = userApi;
