import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  LinearProgress,
  Link,
  Rating,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../../hooks/useTitle";
import dayjs from "dayjs";
import { RefreshOutlined } from "@mui/icons-material";
import { currencyFormat } from "../../utils/helper";
import { useGetMyJobsQuery } from "../../services/my-job.service";
import {
  NegotiationStatus,
  ProjectResource,
  ProjectStatus,
} from "../../types/project";
import { formatWithTimezone } from "../../utils/dateHelper";
import WorkOutlineOutlined from "@mui/icons-material/WorkOutlineOutlined";

const StatusChip = ({ status }: { status?: string }) => {
  const style = { fontWeight: 500 };
  if (status == ProjectStatus.COMPLETED)
    return (
      <Chip
        label={"COMPLETED"}
        sx={style}
        color="info"
        variant="outlined"
        size="small"
      />
    );
  if (status == ProjectStatus.CANCELED)
    return (
      <Chip
        label={"CANCELLED"}
        sx={style}
        color="error"
        variant="outlined"
        size="small"
      />
    );
  else
    return (
      <Chip
        label={status}
        sx={style}
        color="default"
        variant="outlined"
        size="small"
      />
    );
};

const TableRowHistory = ({ job }: { job: ProjectResource }) => {
  const navigate = useNavigate();
  // const meta = useJobMeta(job);
  const startDate = React.useMemo(() => {
    const st = dayjs(job?.start_date || job?.project?.start_date).toDate();
    return st;
  }, [job]);

  return (
    <TableRow>
      <TableCell>
        <Link
          component="button"
          variant="body1"
          onClick={() => navigate(`/works/${job.project_id}`)}
        >
          #{job.project_id} - {job.project?.title}
        </Link>
        <br />
        <Stack direction={"row"} alignItems={"center"}>
          <Typography variant="body2" sx={{ mr: 1 }}>
            {job.project?.company?.name}
          </Typography>
          <Rating
            value={job.project?.company?.total_rating || 0}
            readOnly
            precision={0.5}
            size="small"
          />
        </Stack>
      </TableCell>
      <TableCell>
        <StatusChip status={job.project?.status} />
      </TableCell>
      <TableCell>
        {formatWithTimezone(startDate, job?.timezone || "", "ddd, MMM DD YYYY")}
        <br />
        <Typography variant="caption">
          {formatWithTimezone(startDate, job?.timezone || "", "hh:mm A z")}
        </Typography>
      </TableCell>
      <TableCell align="right">
        {job?.project?.type == "hourly"
          ? `${currencyFormat(job?.rate)} / hr`
          : currencyFormat(job?.price)}
      </TableCell>
      {/* <TableCell align="right">{toDuration(meta.totalSec)}</TableCell> */}
      <TableCell align="right">{job?.total_working || "-"}</TableCell>
      <TableCell align="right">
        {job?.total_earning ? currencyFormat(job?.total_earning) : "-"}
      </TableCell>
    </TableRow>
  );
};

const WorkHistoryPage = () => {
  useTitle("Completed Work");

  const { data, isLoading, isFetching, refetch } = useGetMyJobsQuery({
    statuses: NegotiationStatus.COMPLETED,
    sortField: "created_at",
    sortMode: "desc",
  });

  const history = React.useMemo(
    () => data?.filter((it) => it.status == NegotiationStatus.COMPLETED),
    [data]
  );

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack alignItems={"flex-end"}>
            <Button startIcon={<RefreshOutlined />} onClick={refetch}>
              Refresh
            </Button>
          </Stack>
          {isFetching && <LinearProgress />}
          <Card>
            <CardHeader
              title={`Completed Work`}
              avatar={<WorkOutlineOutlined />}
            />
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Work ID/Title</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell align="right">Submitted Rate</TableCell>
                    <TableCell align="right">Total Hour</TableCell>
                    <TableCell align="right">Total Earning</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history?.length == 0 && (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Typography color={"GrayText"}>
                          No active offers here, start a new offer.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {history?.map((job) => (
                    <TableRowHistory job={job} key={job.id} />
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
export default WorkHistoryPage;
