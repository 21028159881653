import {
  CommentOutlined,
  DownloadOutlined,
  PersonOutlineOutlined,
  PushPin,
  ThumbUp,
  ThumbUpOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Link,
  Typography,
} from "@mui/material";
import { ForumThread } from "../../types/forum";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import { decode } from "html-entities";
import ClickableAvatar from "../../components/ClickableAvatar";

const CardFeed = ({ thread }: { thread: ForumThread }) => {
  const navigate = useNavigate();
  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader
        avatar={<ClickableAvatar talent={thread?.user} />}
        title={thread.user?.full_name || "[User Not Found]"}
        subheader={`${dayjs(thread.created_at).format(
          "MMM DD, YYYY hh:mm A"
        )} • ${thread.total_view} views`}
        action={
          thread.is_pinned ? (
            <IconButton disabled={true} sx={{ mt: 1 }}>
              <PushPin />
            </IconButton>
          ) : undefined
        }
      />
      <CardContent>
        <Link
          component="button"
          onClick={() => navigate(`/live-feed/${thread.id}`)}
        >
          <Typography
            variant="h3"
            sx={{ fontSize: 20, fontWeight: "400", mb: 2 }}
          >
            {thread.title}
          </Typography>
        </Link>
        <Typography
          variant="body2"
          sx={{
            textOverflow: "ellipsis",
            maxHeight: "4em",
            wordWrap: "break-word",
            overflow: "hidden",
          }}
        >
          {decode(thread.body)}
        </Typography>
      </CardContent>
      {(thread?.documents?.length || 0) > 0 && (
        <CardContent>
          <ImageList cols={6} rowHeight={400} gap={20}>
            {thread?.documents?.map((item) => (
              <ImageListItem key={item.id}>
                <img
                  src={`${item.original_url}`}
                  alt={item.file_name}
                  loading="lazy"
                />
                <ImageListItemBar
                  position="top"
                  actionIcon={
                    <IconButton
                      onClick={() => window.open(item.original_url, "_blank")}
                      color="warning"
                    >
                      <DownloadOutlined />
                    </IconButton>
                  }
                />
              </ImageListItem>
            ))}
            || <></>
          </ImageList>
        </CardContent>
      )}
      <CardActions>
        <Button
          startIcon={thread.is_like ? <ThumbUp /> : <ThumbUpOutlined />}
          sx={{ mr: 2 }}
          onClick={() => navigate(`/live-feed/${thread.id}`)}
        >
          {thread.total_likes > 0 ? `${thread.total_likes} ` : ``}
          {thread.is_like ? "Liked" : "Like"}
        </Button>
        <Button
          startIcon={<CommentOutlined />}
          onClick={() => navigate(`/live-feed/${thread.id}`)}
        >
          {thread.total_comments > 0 ? `${thread.total_comments} ` : ``}
          Comments
        </Button>
      </CardActions>
    </Card>
  );
};

export default CardFeed;
